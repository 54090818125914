// import { UseQueryOptions } from 'react-query'

// type Options = Omit<UseQueryOptions, 'queryKey' | 'queryFn'>

export const defaultOptions = {
  staleTime: 60 * 60 * 1000,
  refetchOnWindowFocus: false,
  keepPreviousData: true,
  retry: false,
}
