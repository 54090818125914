import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import { ILaximoCategory } from '@monorepo/laximo_module'
import { CategoryItem } from './category_item'

type CategoriesListProps = {
  items: ILaximoCategory[]
  currentCategoryId?: string
  createUrl(item: ILaximoCategory): string
  isCurrent?(item: ILaximoCategory): boolean
}

export const CategoriesList: React.FC<CategoriesListProps> = ({
  items,
  createUrl,
  currentCategoryId,
  isCurrent,
}) => (
  <ul className="fa-ul">
    {items.map((item) => (
      <CategoryItem key={item.categoryid} $current={isCurrent?.(item)}>
        <Link to={createUrl(item)}>
          <span className="fa-li">
            <FontAwesomeIcon icon={faCog} />
          </span>
          {item.name}
        </Link>
      </CategoryItem>
    ))}
  </ul>
)
