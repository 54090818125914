import {
  defaultTo,
  first,
  flow,
  get,
  groupBy,
  invert,
  map,
  mapValues,
} from 'lodash/fp'
import { ILaximoCatalog } from '@monorepo/laximo_module'

export const getBrandsLetter: (
  items?: ILaximoCatalog[]
) => Record<string, string> = flow(
  defaultTo<ILaximoCatalog[]>([]),
  map(get('brand')),
  groupBy(first),
  mapValues(first),
  invert
)
