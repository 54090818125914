import React, { useCallback } from 'react'
import { useLocation } from 'react-router'
import qs from 'qs'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { ILaximoCategory, useVehicle } from '@monorepo/laximo_module'
import { CategoriesList } from './components/categories_list'
import { UnitsList } from './components/units_list'
import { Metadata } from '../../vehicle.page'

interface VehicleProps {
  readonly catalogCode: string
  readonly ssd: string
  onLoad?<T extends Metadata>(data: T): void
}

export const Vehicle: React.FC<VehicleProps> = ({
  catalogCode,
  ssd,
  onLoad,
}) => {
  const location = useLocation()

  const query = qs.parse(location.search, { ignoreQueryPrefix: true }) as {
    categoryId?: string
  }

  const { categoryId = '-1' } = query

  const { data } = useVehicle({
    catalogCode,
    ssd,
    categoryId,
    onSuccess: (data) => onLoad?.(data),
  })

  const createCategoryUrl = useCallback(
    ({ categoryid: categoryId, ssd }: ILaximoCategory) =>
      qs.stringify({ ...query, categoryId, ssd }, { addQueryPrefix: true }),
    [query]
  )

  return (
    <Row className="mt-4">
      <Col md="4">
        {data?.categories && (
          <CategoriesList
            items={data.categories}
            createUrl={createCategoryUrl}
            isCurrent={(item) => item.categoryid === categoryId}
          />
        )}
      </Col>
      <Col md="8">
        {data?.units && data?.catalog && (
          <UnitsList items={data.units} catalog={data.catalog} />
        )}
      </Col>
    </Row>
  )
}
