import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleNotch,
  faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons'

import { IOffer } from '@monorepo/application_module'
import { CreateCartItemProvider } from '@monorepo/cart_module'
import { CompositButton } from 'App/Components/Button'

import { AddToCartForm } from './add_to_cart.form'

type AddToCartModalProps = {
  item: IOffer
  onClose?(): void
}

export const AddToCartModal: React.FC<AddToCartModalProps> = ({
  item,
  onClose,
}) => {
  return (
    <CreateCartItemProvider
      item={item}
      // onError={errorHandler}
      onSuccess={onClose}
    >
      {({ errors, values, isSubmitting, isValid }) => (
        <Modal show={true} onHide={onClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>
              Добавить «{item.brandName} {item.number.toUpperCase()}»
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <AddToCartForm cartAmount={0} offer={item} id="add-to-cart" />
          </Modal.Body>

          <Modal.Footer>
            <CompositButton outline onClick={onClose}>
              Отмена
            </CompositButton>
            <CompositButton
              variant="primary"
              form="add-to-cart"
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              Добавить&nbsp;
              <FontAwesomeIcon
                icon={isSubmitting ? faCircleNotch : faShoppingBasket}
                spin={isSubmitting}
                fixedWidth
              />
            </CompositButton>
          </Modal.Footer>
        </Modal>
      )}
    </CreateCartItemProvider>
  )
}
