import React from 'react'
import { useHistory } from 'react-router'
import { BaseError, BaseErrorProps } from './base_error'

import { getSearchUrl } from '@monorepo/application_module/helpers'
import { useRemoveCartItem } from '@monorepo/cart_module'
import { CompositButton } from 'App/Components'

export const OfferError: React.FC<BaseErrorProps> = (props) => {
  const history = useHistory()
  const { removeAsync } = useRemoveCartItem()

  const clickHandler = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault()
    await removeAsync(props.item)
    history.push(getSearchUrl(props.item.good))
  }

  return (
    <BaseError {...props}>
      <CompositButton
        size="small"
        variant="danger"
        onClick={clickHandler}
        to={getSearchUrl(props.item.good)}
      >
        Перезаказать?
      </CompositButton>
    </BaseError>
  )
}
