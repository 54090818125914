import React from 'react'
import { useField } from 'formik'

import { IPaymentMethod } from '@monorepo/payment_module'
import { BaseFormGroup, SelectField } from 'App/Components/FormFields'

type PaymentFieldProps = {
  name: string
  paymentMethods?: IPaymentMethod[]
}

export const PaymentField: React.FC<PaymentFieldProps> = ({
  name,
  paymentMethods,
}) => {
  const [field] = useField<IPaymentMethod>(name)
  const currentMethod = field.value

  return (
    <>
      <BaseFormGroup name={name} label="Способ оплаты">
        <SelectField
          items={paymentMethods}
          keyProp="id"
          valueProp="name"
          name={name}
          className="form-control"
        />
      </BaseFormGroup>
      {(currentMethod?.commission ?? 0) > 0 && (
        <p className="m4-4 lead text-danger">
          Внимание! Комиссия {currentMethod?.commission}%
        </p>
      )}
      {currentMethod?.description && (
        <p className="m4-4 lead">{currentMethod?.description}</p>
      )}
      {currentMethod?.message && (
        <p className="m4-4 lead text-warning">{currentMethod?.message}</p>
      )}
    </>
  )
}
