import React from 'react'
import Media from 'react-bootstrap/Media'
import Button from 'react-bootstrap/Button'
import gravatar from 'gravatar'

import { IUser } from '@monorepo/user_module'

type AvatarSectionProps = {
  user?: IUser
}

export const AvatarSection: React.FC<AvatarSectionProps> = ({ user }) => {
  const url = gravatar.url(user?.email ?? '', {
    s: '200',
    r: 'pg',
    d: 'mp',
  })

  return (
    <Media>
      {/* <div className="media-left mr-5"> */}
      <img
        alt=""
        className="user-photo media-object rounded align-self-center mr-5"
        src={url}
      />
      {/* </div> */}
      <Media.Body>
        <p>
          Для выбора изображения используется сервис Gravatar. <br />
          <em>Размер изображения должен быть не менее 140px x 140px</em>
        </p>
        <Button
          href="https://ru.gravatar.com"
          target="_blank"
          variant="link"
          // rel="noopener noreferrer"
        >
          Изменить аватар
        </Button>
        {/* <input className="sr-only" id="filePhoto" type="file" /> */}
      </Media.Body>
    </Media>
  )
}
