import React from 'react'
import { Form } from 'react-bootstrap'
import { Field, Form as FormikForm } from 'formik'

import { BaseFormGroup } from 'App/Components/FormFields'

type UpdatePasswordFormProps = {
  //
}

export const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = () => (
  <Form as={FormikForm}>
    <div className="row clearfix">
      <div className="col-lg-6 col-md-12">
        <BaseFormGroup name="email">
          <Field
            className="form-control"
            disabled
            placeholder="email"
            type="text"
            name="email"
            // value="alizeethomas"
            // onChange={() => {}}
          />
        </BaseFormGroup>
        {/* <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Email"
                    type="email"
                    value="alizee.info@yourdomain.com"
                    onChange={() => {}}
                  />
                </div> */}
        {/* <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Phone Number"
                    type="text"
                    onChange={() => {}}
                  />
                </div> */}
      </div>

      <div className="col-lg-6 col-md-12">
        <h6>Изменить пароль</h6>
        <BaseFormGroup name="password">
          <Field
            className="form-control"
            placeholder="Текущий пароль"
            type="password"
            name="password"
          />
        </BaseFormGroup>
        <BaseFormGroup name="newPassword">
          <Field
            className="form-control"
            placeholder="Новый пароль"
            type="password"
            name="newPassword"
          />
        </BaseFormGroup>
        <BaseFormGroup name="confirmPassword">
          <Field
            className="form-control"
            placeholder="Повторите новый пароль"
            type="password"
            name="confirmPassword"
          />
        </BaseFormGroup>
      </div>
    </div>
    <button className="btn btn-primary" type="submit">
      Сохранить
    </button>{' '}
    &nbsp;&nbsp;
    <button className="btn btn-default" type="reset">
      Отмена
    </button>
  </Form>
)
