import { apiClient } from '@monorepo/core'
import { ILaximoCatalog } from '../interfaces'

// interface IResponse {
//   ListCatalogs: ILaximoCatalog[]
// }

export async function fetchCatalogsList() {
  try {
    // const { data } = await apiClient.get<IResponse>('/laximo2/catalogs')
    const { data } = await apiClient.get<ILaximoCatalog[]>('/laximo2/catalogs')

    return data
  } catch (error) {
    throw error
  }
}
