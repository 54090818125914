import styled from 'styled-components'
import Col from 'react-bootstrap/Col'

export const TD = styled(Col).attrs((props) => ({
  role: 'table-cell',
}))`
  &&& {
    padding: 4px 8px;
    line-height: 1.5;
  }
`
