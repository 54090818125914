import { useQuery, UseQueryOptions } from 'react-query'

import { IPagination } from '@monorepo/pagination_module'
import { IBlogPost } from '../interfaces'
import { fetchBlogPostsList } from '../api'

const QUERY_KEY = 'blog-posts'

type PostsFilter = {
  page?: number
  perPage?: number
  category?: string
}

export function useBlogPostsList<TData = IPagination<IBlogPost>>(
  params?: string | PostsFilter,
  options?: UseQueryOptions<IPagination<IBlogPost>, unknown, TData>
) {
  const filter: PostsFilter | undefined =
    typeof params === 'string'
      ? {
          category: params,
        }
      : params

  return useQuery(
    [QUERY_KEY, filter] as any,
    () => fetchBlogPostsList(filter),
    {
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
      ...options,
    }
  )
}
