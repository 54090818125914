import React from 'react'
import { useLocation } from 'react-router'

import { Link } from 'App/Components'

type NavigationProps = {
  //
}

export const Navigation: React.FC<NavigationProps> = () => {
  // TODO: Заменить на рабочий вариант
  const { pathname } = useLocation()

  return (
    <nav className="post-single-navigation post mob-hide-false et-clearfix">
      <Link rel="prev" to={pathname}>
        Назад
      </Link>{' '}
      <Link rel="next" to={pathname}>
        Вперёд
      </Link>
    </nav>
  )
}
