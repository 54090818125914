import React from 'react'

import { IMenu } from '@monorepo/menu_module'
import { ListItem } from './list_item'

type LinksListProps = React.HTMLAttributes<HTMLElement> & {
  title?: React.ReactNode
  items: IMenu
}

const LinksList: React.FC<LinksListProps> = ({ title, items, ...props }) => (
  <section {...props}>
    {title && (
      <>
        <h6>
          <span className="text-wrapper">
            <span className="text">{title}</span>
          </span>
        </h6>
        <span className="et-gap et-clearfix" />
      </>
    )}
    <ul>
      {items.map((item, idx) => (
        <ListItem key={idx} item={item} />
      ))}
    </ul>
  </section>
)

export default LinksList
