import React from 'react'

import { RecentPosts } from './RecentPosts'

type SidebarProps = {
  //
}

export const Sidebar: React.FC<SidebarProps> = () => (
  <aside className="blog-single-widgets widget-area">
    {/* <div id="search-4" className="widget widget_search">
        <form
          className="search-form"
        >
          <fieldset>
            <input type="text" name="s" id="s" data-placeholder="Search..." />
            <input type="submit" id="searchsubmit" />
            <div className="search-icon" />
          </fieldset>
        </form>
      </div> */}
    {/* <div id="instagram-2" className="widget widget_instagram">
        <h5 className="widget_title">Instagram</h5>
        <ul className="instagram-image-list columns-mob-3 columns-tablet-3 columns-desk-3">
          <li>
            <a target="_blank" href="//instagram.com/p/B-m8n9GHyTC/">
              <div className="image-preloader" />
              <img
                src="//instagram.fhen1-1.fna.fbcdn.net/v/t51.2885-15/e35/c141.0.573.573a/s320x320/92399038_145157507020018_2903380904920178234_n.jpg?_nc_ht=instagram.fhen1-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=LVH4ihHQnroAX_WmTAb&oh=ec1b97451f6272e9001e98f88de960fd&oe=5EB5DBEB"
                alt="Bentley Continental GT | @BASFRefinish black out package | 3 piece @AGWheels | Matte black wrap | #UltimateAuto #UALifestyle"
                title="Bentley Continental GT | @BASFRefinish black out package | 3 piece @AGWheels | Matte black wrap | #UltimateAuto #UALifestyle"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="//instagram.com/p/B-j8vc8Aa5X/">
              <div className="image-preloader" />
              <img
                src="//instagram.fhen1-1.fna.fbcdn.net/v/t51.2885-15/e35/s320x320/91981235_176565643817769_4138764776025605110_n.jpg?_nc_ht=instagram.fhen1-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=8onRrVMgcS0AX_OmUWX&oh=2b2f4469cb75bc4bc116f62cf993497e&oe=5EB3FDFF"
                alt="Mercedes G550 | #ForSale 2019 G550 | 24” @alphaonewheels | UA Black out package | @ceramicprousa paint protection | $145k #UltimateAuto #UALifeStyle #AllInHouse #Mercedes #Gwagon"
                title="Mercedes G550 | #ForSale 2019 G550 | 24” @alphaonewheels | UA Black out package | @ceramicprousa paint protection | $145k #UltimateAuto #UALifeStyle #AllInHouse #Mercedes #Gwagon"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="//instagram.com/p/B-fAaXBHRQF/">
              <div className="image-preloader" />
              <img
                src="//instagram.fhen1-1.fna.fbcdn.net/v/t51.2885-15/e35/c149.0.604.604a/s320x320/91543032_665145197582514_3194654389058565778_n.jpg?_nc_ht=instagram.fhen1-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=WH9C5ZHmmwEAX93a46Z&oh=c7e7c4fcf150ca76a40505222256243c&oe=5EB37F26"
                alt="#UAExclusive Mercedes Sprinter Van #ForSale | Custom built #AllInHouse | Full @KickerAudio sound system | IPad controlled | Full custom interior | WiFi and Direct TV ready | Bar and refrigerators with tons of storage all around | $189,000 | For more information and more photos visit UltimateAuto.com and check out our inventory tab | #UltimateAuto #UALifestyle #Mercedes #Orlando"
                title="#UAExclusive Mercedes Sprinter Van #ForSale | Custom built #AllInHouse | Full @KickerAudio sound system | IPad controlled | Full custom interior | WiFi and Direct TV ready | Bar and refrigerators with tons of storage all around | $189,000 | For more information and more photos visit UltimateAuto.com and check out our inventory tab | #UltimateAuto #UALifestyle #Mercedes #Orlando"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="//instagram.com/p/B-cY-yTnhlQ/">
              <div className="image-preloader" />
              <img
                src="//instagram.fhen1-1.fna.fbcdn.net/v/t51.2885-15/e35/c142.0.1155.1155a/s320x320/91625362_222640412426888_2188564376500993990_n.jpg?_nc_ht=instagram.fhen1-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=nblAarWRmo0AX-SCOfn&oh=4aa697cb2eedb1e444a8a25317b1215c&oe=5EB52319"
                alt="The first 2020 F350 10-12 inch @KeldermanTrucks air suspension lift kit | Paint matched Kelderman front and rear bumper | @RigidIndustries light bars all around | @BASFRefinish custom painted trim | @AmericanForceWheels 24”s | @MonsterHooksInc front and rear | @LlumarFilms paint protection film | @CeramicproUSA coating | From window tint to full builds, we are your number one custom shop, stop by or give us a ring at 407-849-1100 to discuss your next project | #UltimateAuto #UALifestyle #UltimateEdition #AllOnHouse #Ford #Orlando"
                title="The first 2020 F350 10-12 inch @KeldermanTrucks air suspension lift kit | Paint matched Kelderman front and rear bumper | @RigidIndustries light bars all around | @BASFRefinish custom painted trim | @AmericanForceWheels 24”s | @MonsterHooksInc front and rear | @LlumarFilms paint protection film | @CeramicproUSA coating | From window tint to full builds, we are your number one custom shop, stop by or give us a ring at 407-849-1100 to discuss your next project | #UltimateAuto #UALifestyle #UltimateEdition #AllOnHouse #Ford #Orlando"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="//instagram.com/p/B-Z1qoBH_Ju/">
              <div className="image-preloader" />
              <img
                src="//instagram.fhen1-1.fna.fbcdn.net/v/t51.2885-15/e35/c150.0.600.600a/s320x320/91214374_575609693073716_7277846173162421799_n.jpg?_nc_ht=instagram.fhen1-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=l-tg8ctfH1cAX80sSlL&oh=011a292d9637293f634b4809720bc72b&oe=5EB65950"
                alt="New project alert 🚨🚨| 2020 F450 Ultimate Edition | 4” @Wicked_Customs coilover lift kit | full custom Sound system | @Americanforcewheels 24” wheels | @BASFRefinish painted trim package and a ton of other cool upgrades | #UltimateAuto #UALifestyle #AllInHouse #Ford  #SuperDuty  #Orlando #Staytuned"
                title="New project alert 🚨🚨| 2020 F450 Ultimate Edition | 4” @Wicked_Customs coilover lift kit | full custom Sound system | @Americanforcewheels 24” wheels | @BASFRefinish painted trim package and a ton of other cool upgrades | #UltimateAuto #UALifestyle #AllInHouse #Ford  #SuperDuty  #Orlando #Staytuned"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="//instagram.com/p/B-XRvyjHzqE/">
              <div className="image-preloader" />
              <img
                src="//instagram.fhen1-1.fna.fbcdn.net/v/t51.2885-15/e35/c0.117.937.937a/s320x320/91169321_306072277036648_8185029822255054033_n.jpg?_nc_ht=instagram.fhen1-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=AUG4BT5gRt8AX9ynKA3&oh=c904fb745bf1b2efd2af81be1643f4ab&oe=5EB570D6"
                alt="We are closely watching the updates from CDC local government, we are open our normal schedule Monday-Friday 9am to 6pm. We also offer pick up and delivery of your vehicle via our Ultimate Auto rollback tow truck. For any questions, concerns, or inquiries; please reach out to us at 407-849-1100. | #UltimateAuto #Orlando"
                title="We are closely watching the updates from CDC local government, we are open our normal schedule Monday-Friday 9am to 6pm. We also offer pick up and delivery of your vehicle via our Ultimate Auto rollback tow truck. For any questions, concerns, or inquiries; please reach out to us at 407-849-1100. | #UltimateAuto #Orlando"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="//instagram.com/p/B-VXquanL8g/">
              <div className="image-preloader" />
              <img
                src="//instagram.fhen1-1.fna.fbcdn.net/v/t51.2885-15/e35/c150.0.600.600a/s320x320/91204000_120275679594116_2806024517339799921_n.jpg?_nc_ht=instagram.fhen1-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=ydv17zn0jLkAX9jbKbl&oh=11dfa6345b17af13a9ef68bfd1be8387&oe=5EB3FCBC"
                alt="Custom 2017 Polaris Slingshot #ForSale | Supercharged | @WilwoodDiscBrakes system | @JLAudioInc sound system | @BulletConcepts four wheel conversion | @NicheWheels 22s | $55,000 | #UltimateAuto #Slingshot # Orlando"
                title="Custom 2017 Polaris Slingshot #ForSale | Supercharged | @WilwoodDiscBrakes system | @JLAudioInc sound system | @BulletConcepts four wheel conversion | @NicheWheels 22s | $55,000 | #UltimateAuto #Slingshot # Orlando"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="//instagram.com/p/B-S21v8nAnq/">
              <div className="image-preloader" />
              <img
                src="//instagram.fhen1-1.fna.fbcdn.net/v/t51.2885-15/e35/c149.0.604.604a/s320x320/91361041_536256540362685_1982609055899227859_n.jpg?_nc_ht=instagram.fhen1-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=DylaY2AbvO8AX-YMWUj&oh=c4cb74950e83473bec732d8398d89053&oe=5EB424A1"
                alt="#ForSale | 2017 Mercedes AMG S63 Widebody | @PriorDesignUSA wideboy Kit | @RENNTech performance package | @AlphaOneWheels 22” featuring carbon lips | Full @JLAudioInc sounds system | @EscortInc Ci 360 radar detector with laser shifter | Custom @BASFRefinish matte black paint job |  Built all in house | $199,500 | #UltimateAuto #UALifestyle #AllInHouse #Mercedes #Orlando"
                title="#ForSale | 2017 Mercedes AMG S63 Widebody | @PriorDesignUSA wideboy Kit | @RENNTech performance package | @AlphaOneWheels 22” featuring carbon lips | Full @JLAudioInc sounds system | @EscortInc Ci 360 radar detector with laser shifter | Custom @BASFRefinish matte black paint job |  Built all in house | $199,500 | #UltimateAuto #UALifestyle #AllInHouse #Mercedes #Orlando"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="//instagram.com/p/B-SK5GtnlKn/">
              <div className="image-preloader" />
              <img
                src="//instagram.fhen1-1.fna.fbcdn.net/v/t51.2885-15/e35/c149.0.604.604a/s320x320/91296993_228356955235736_1535158044004795173_n.jpg?_nc_ht=instagram.fhen1-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=T_7wP4JB2Q8AX_p2A_8&oh=e4fe5fa1530200ecdd9e8b48490b1c4a&oe=5EB69342"
                alt="#Lamborghini Mercielago in for some audio upgrades | @FocalOfficial K2 Power series components | @JLAudioInc sub and amplifier package | #UltimateAuto #UALifestyle #Allinhouse #focal #jlaudio"
                title="#Lamborghini Mercielago in for some audio upgrades | @FocalOfficial K2 Power series components | @JLAudioInc sub and amplifier package | #UltimateAuto #UALifestyle #Allinhouse #focal #jlaudio"
              />
            </a>
          </li>
        </ul>
        <p className="et-clearfix">
          <a href="//instagram.com/ultimateauto/" rel="me" target="_self">
            Follow Me!
          </a>
        </p>
      </div> */}
    {/* <div id="categories-4" className="widget widget_categories">
      <h5 className="widget_title">Категории</h5>{' '}
      <ul>
        <li className="cat-item-none">Рубрик нет</li>{' '}
      </ul>
    </div> */}
    {/* <div
        id="vin_decoder_widget-2"
        className="widget widget_vin_decoder_widget"
      >
        <div
          className="vin-decoder search-by-vin-true depth-trim"
        >
          <h5 className="widget_title">Vin decoder</h5>
          <form name="et-vin-decoder" className="et-vin-decoder" method="POST">
            <div>
              <input
                type="text"
                name="vin"
                placeholder="Enter VIN"
                defaultValue
              />
              <span className="alert error">Invalid or Unknown VIN</span>
              <span className="alert example note">
                Example: 1NXBR32E85Z505904
              </span>
              <div>
                <input
                  type="submit"
                  className="vin-decoder-button"
                  defaultValue="Go"
                  name="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>{' '} */}
    <RecentPosts />
    {/* <div id="mailchimp-2" className="widget widget_mailchimp">
        <div className="mailchimp-form">
          <h5 className="widget_title">Subscribe</h5>
          <p className="mailchimp-description">
            Don’t miss Mobimax latest news and events.
          </p>
          <form
            className="et-mailchimp-form"
            name="et-mailchimp-form"
          >
            <div>
              <input
                data-required="true"
                className="field"
                type="text"
                defaultValue
                name="fname"
                placeholder="First name"
              />
              <span className="alert warning">
                Please enter your First name
              </span>
            </div>
            <div>
              <input
                data-required="true"
                className="field"
                type="text"
                defaultValue
                name="lname"
                placeholder="Last name"
              />
              <span className="alert warning">Please enter your Last name</span>
            </div>
            <div>
              <input
                data-required="true"
                className="field"
                type="text"
                defaultValue
                name="phone"
                placeholder="Phone number"
              />
              <span className="alert warning">
                Please enter your Phone number
              </span>
            </div>
            <div>
              <input
                type="text"
                defaultValue
                className="field"
                name="email"
                placeholder="Email"
              />
              <span className="alert warning">Invalid or empty email</span>
            </div>
            <input type="hidden" defaultValue="ec80d589c7" name="list" />
            <input type="hidden" name="action" defaultValue="et_mailchimp" />
            <div className="send-div">
              <input
                type="submit"
                className="button"
                defaultValue="Subscribe"
                name="subscribe"
              />
              <div className="sending" />
            </div>
            <div className="et-mailchimp-success alert final success">
              You have successfully subscribed to the newsletter.
            </div>
            <div className="et-mailchimp-error alert final error">
              Something went wrong. Your subscription failed.
            </div>
            <input
              type="hidden"
              id="et_mailchimp_nonce"
              name="et_mailchimp_nonce"
              defaultValue="bf4534a82e"
            />
          </form>
        </div>
      </div> */}
    {/* <div id="facebook-2" className="widget widget_facebook">
        <h5 className="widget_title">Find us on Facebook</h5>{' '}
        <div id="fb-root" className=" fb_reset">
          <div
            style={{
              position: 'absolute',
              top: '-10000px',
              width: '0px',
              height: '0px',
            }}
          >
            <div />
          </div>
        </div>
        <div
          className="fb-page fb_iframe_widget"
          data-href="https://www.facebook.com/pages/category/Car-Dealership/Ultimate-Cars-Ltd-317750141586314/"
          data-tabs="timeline,messages,events"
          data-show-facepile="true"
          data-adapt-container-width="true"
          fb-xfbml-state="rendered"
          fb-iframe-plugin-query="adapt_container_width=true&app_id=576106959422907&container_width=296&href=https%3A%2F%2Fwww.facebook.com%2Fpages%2Fcategory%2FCar-Dealership%2FUltimate-Cars-Ltd-317750141586314%2F&locale=en_US&sdk=joey&show_facepile=true&tabs=timeline%2Cmessages%2Cevents"
        >
          <span
            style={{ verticalAlign: 'bottom', width: '296px', height: '500px' }}
          >
            <iframe
              name="f2cc941bf20d03"
              width="1000px"
              height="1000px"
              data-testid="fb:page Facebook Social Plugin"
              title="fb:page Facebook Social Plugin"
              frameBorder={0}
              allowTransparency="true"
              allowFullScreen="true"
              scrolling="no"
              allow="encrypted-media"
              src="https://www.facebook.com/v3.1/plugins/page.php?adapt_container_width=true&app_id=576106959422907&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter.php%3Fversion%3D46%23cb%3Df20ac8892e10d8%26domain%3Dtemplate.mobizap.itonix.ru%26origin%3Dhttp%253A%252F%252Ftemplate.mobizap.itonix.ru%252Ff3153f882036154%26relation%3Dparent.parent&container_width=296&href=https%3A%2F%2Fwww.facebook.com%2Fpages%2Fcategory%2FCar-Dealership%2FUltimate-Cars-Ltd-317750141586314%2F&locale=en_US&sdk=joey&show_facepile=true&tabs=timeline%2Cmessages%2Cevents"
              style={{
                border: 'none',
                visibility: 'visible',
                width: '296px',
                height: '500px',
              }}
              className
            />
          </span>
        </div>
      </div>{' '} */}
  </aside>
)
