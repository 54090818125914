import { curry, reduce } from 'lodash/fp'

import { ILaximoQuickGroup, Nullable } from '../interfaces'

export const filterQuickGroups: (
  items: Nullable<ILaximoQuickGroup[]>,
  searchString: string
) => ILaximoQuickGroup[] = curry(
  (items: Nullable<ILaximoQuickGroup[]>, searchString: string) =>
    reduce<ILaximoQuickGroup, ILaximoQuickGroup[]>(
      (acc, node) => {
        const childGroups = filterQuickGroups(node.childGroups, searchString)

        const includeName = node.name.toLowerCase().includes(searchString)

        return childGroups.length || includeName
          ? acc.concat({ ...node, childGroups })
          : acc
      },
      [],
      items
    )
)
