import { OrderId } from '@monorepo/order_module'
import { apiClient } from '@monorepo/core'

export interface RegisterOrderParams {
  orderId: OrderId
  returnUrl: string
  failUrl: string
}

interface IRegisterOrderResponse {
  formUrl: string
  orderId: OrderId
}

export async function registerOrder(params: RegisterOrderParams) {
  try {
    const { data } = await apiClient.post<IRegisterOrderResponse>(
      '/payment/sberbank/register.do',
      null,
      {
        params,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}
