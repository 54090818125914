// import hoistNonReactStatics from 'hoist-non-react-statics'
import { Card as BaseCard } from 'react-bootstrap'

import { BodyWithPaddings } from './BodyWithPaddings'
import { Divider } from './Divider'
import { Table } from './Table'

export const Card = Object.assign({}, BaseCard, {
  Divider,
  BodyWithPaddings,
  Table,
})
