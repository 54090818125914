import React, { useContext, useEffect, useMemo, useState } from 'react'
import invariant from 'invariant'
import { yupToFormErrors } from 'formik'

import { ICartItem } from '@monorepo/cart_module'
import { CreateOrderItemDTO } from '../../dto'
import { createOrderItemSchema } from '../../schema'
import {
  IOrderItemValidatorContext,
  OrderItemValidatorContext,
} from './order_item_validator.context'

type OrderItemValidatorProviderProps = {
  item: ICartItem
  children:
    | ((props: IOrderItemValidatorContext) => React.ReactNode)
    | React.ReactNode
}

export const OrderItemValidatorProvider: React.FC<OrderItemValidatorProviderProps> =
  ({ children, item }) => {
    const [validationState, setValidationState] =
      useState<IOrderItemValidatorContext>({
        isValid: true,
        isValidating: false,
        errors: {},
      })

    const values: CreateOrderItemDTO = useMemo(
      () => ({
        id: item.id,
        offer: item.offer,
        isSelected: item.isSelected,
        amount: item.amount,
        price: item.price,
        deliveryTime: item.deliveryTime,
      }),
      [item]
    )

    useEffect(() => {
      const context = {}
      setValidationState((prev) => ({ ...prev, isValidating: true }))

      createOrderItemSchema
        .validate(values, { abortEarly: false, context })
        .then(() =>
          setValidationState({ isValid: true, isValidating: false, errors: {} })
        )
        .catch((err) => {
          setValidationState({
            isValid: false,
            isValidating: false,
            errors: yupToFormErrors<CreateOrderItemDTO>(err),
          })
        })

      //   return () => {}
    }, [values])

    return (
      <OrderItemValidatorContext.Provider value={validationState}>
        {typeof children === 'function' ? children(validationState) : children}
      </OrderItemValidatorContext.Provider>
    )
  }

export function useOrderItemValidator() {
  const context = useContext(OrderItemValidatorContext)

  invariant(context, 'Add [OrderItemValidatorProvider] to your app!')

  return context
}
