import React from 'react'

import { useBlogPostsList } from '@monorepo/blog_post_module'
import { PostItem } from './PostItem'

type RecentPostsProps = {
  //
}

export const RecentPosts: React.FC<RecentPostsProps> = () => {
  const { data: recentPosts } = useBlogPostsList()

  return (
    <div id="et-recent-posts-1" className="widget widget_et_recent_entries">
      <h5 className="widget_title">Новое</h5>
      <ul>
        {recentPosts?.items.map((item, idx) => (
          <PostItem key={idx} item={item} />
        ))}
      </ul>
    </div>
  )
}
