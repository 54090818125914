import { IRoute } from './interface'

// import Balance from '../../pages/Cabinet/Balance'
import Cart from 'App/View/Cart/Cart'
// import Garage from '../../pages/Cabinet/Garage'
// import Orders from '../../pages/Cabinet/Orders'
// import Profile from '../../pages/Cabinet/Profile'
// const Inbox: React.FC = React.lazy<React.FC>(() => import('../../screens/App/Inbox'))

export default (): IRoute[] => [
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/cart`,
    component: Cart,
  },
]
