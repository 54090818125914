import React, { useMemo } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import {
  getUnitPageUrl,
  ILaximoCatalog,
  ILaximoUnit,
} from '@monorepo/laximo_module'
import { CompositButton } from 'App/Components'

type UnitsListProps = {
  catalog: ILaximoCatalog
  items: ILaximoUnit[]
}

export const UnitsList: React.FC<UnitsListProps> = ({ items, catalog }) => {
  const getUrl = useMemo(() => getUnitPageUrl(catalog), [catalog])

  return (
    <Row>
      {items.map((item, idx) => (
        <Col key={idx} md="4" className="p-1">
          <Card className="h-100">
            <Card.Img
              // variant="bottom"
              src={item.imageurl?.replace('%size%', String(250))}
              alt={item.name ?? ''}
            />
            <Card.ImgOverlay>
              <Card.Title>{item.code}</Card.Title>
            </Card.ImgOverlay>
            <Card.Body className="d-flex flex-column justify-content-between align-items-center">
              <Card.Text>{item.name}</Card.Text>
              <CompositButton size="small" to={getUrl(item)}>
                Подробнее...
              </CompositButton>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  )
}
