import { apiClient } from '@monorepo/core'
import { CatalogCode, ILaximoCatalog, IWizard, Nullable } from '../interfaces'

export interface IResponse {
  readonly catalog: Nullable<ILaximoCatalog>
  readonly wizard: Nullable<IWizard>
}

interface Options {
  readonly wizard?: boolean
  readonly ssd?: string
  readonly catalogCode: CatalogCode
}

export async function fetchCatalogInfo(options: Options) {
  try {
    const { data } = await apiClient.get<IResponse>('/laximo2/catalog_info', {
      params: options,
    })

    return data
  } catch (error) {
    throw error
  }
}
