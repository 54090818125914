import React from 'react'

import { IMenu } from '@monorepo/menu_module'
import { MenuItem } from './MenuItem'

type MenyProps = React.HTMLAttributes<HTMLUListElement> & {
  items: IMenu
  depth?: number
}

export const Menu: React.FC<MenyProps> = ({ items, depth = 0, ...props }) => (
  <ul {...props}>
    {items.map((item, idx) => (
      <MenuItem key={idx} item={item} depth={depth} />
    ))}
  </ul>
)
