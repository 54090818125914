import React from 'react'
import {
  faEnvelopeOpen,
  faMapMarkerAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'

import { ICompany } from '@monorepo/company_module'
import { renderFullAddress } from '@monorepo/application_module/helpers'
import { EmailLink, PhoneLink } from 'App/Components'
import { Item, List } from './list'

type ContactsInfoProps = {
  office: ICompany
}

export const ContactsInfo: React.FC<ContactsInfoProps> = ({ office }) => (
  <List>
    <Item icon={faMapMarkerAlt}>
      {office.address.mapPlace.url ? (
        <a
          href={office.address.mapPlace.url}
          target="_blank"
          rel="noopener noreferrer"
          title="Посмотреть на карте"
        >
          {renderFullAddress(office.address)}
        </a>
      ) : (
        renderFullAddress(office.address)
      )}
    </Item>
    {office.phones.map((phone, idx) => (
      <Item key={idx} icon={faPhone}>
        <PhoneLink
          className="text-reset"
          phone={phone.number}
          title="Позвонить нам"
        >
          {phone.number}
        </PhoneLink>
      </Item>
    ))}
    {office.email && (
      <Item icon={faEnvelopeOpen}>
        <EmailLink className="text-reset" email={office.email}>
          {office.email}
        </EmailLink>
      </Item>
    )}
  </List>
)
