import styled from 'styled-components'

import { PageSection as Base } from './PageSection'
import { variantStyle } from 'App/Resources/mixins/variant.mixin'

export * from './PageSection'

export const Section = styled(Base).attrs((props) => ({
  $variant: props.$variant ?? 'light',
}))`
  padding-top: ${(props) => (props.$condensed ? 1 : 3)}rem;
  padding-bottom: ${(props) => (props.$condensed ? 1 : 1.5)}rem;

  ${() => variantStyle}
`
