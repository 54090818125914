import React from 'react'
import gravatar from 'gravatar'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { IUser } from '@monorepo/user_module'
import { CompositButton, Link } from 'App/Components'

// import defaultAvatarImage from 'App/images/default-avatar.png'

type ProfileCardProps = {
  user: IUser
}

const GravatarTooltip: React.FC<any> = ({ children }) => (
  <OverlayTrigger
    placement="auto"
    flip
    overlay={
      <Tooltip id="tooltip-gravatar">
        Изменить аватар можно на сервисе «Gravatar»
      </Tooltip>
    }
  >
    {children}
  </OverlayTrigger>
)

export const ProfileCard: React.FC<ProfileCardProps> = ({ user }) => (
  <div className="dashboard__profile card profile-card">
    <div className="card-body profile-card__body">
      <GravatarTooltip>
        <div className="profile-card__avatar mb-0">
          <Link to="https://gravatar.com">
            <img src={gravatar.url(user.email, { default: 'mp' })} alt="" />
          </Link>
        </div>
      </GravatarTooltip>
      <GravatarTooltip>
        <Link to="https://gravatar.com" className="btn btn-link btn-sm mb-2">
          Изменить аватар
        </Link>
      </GravatarTooltip>
      <div className="profile-card__name">
        {[user.firstName, user.middleName, user.lastName]
          .filter(Boolean)
          .join(' ')}
      </div>
      <div className="profile-card__email">{user.email}</div>
      <div className="profile-card__edit">
        <CompositButton to="/profile" block className="mb-2">
          Редактировать профиль
        </CompositButton>
        <CompositButton to="/logout" block variant="danger" className="mb-2">
          Выйти
        </CompositButton>
      </div>
    </div>
  </div>
)
