import styled from 'styled-components'

import Base from './middle_section'

export * from './middle_section'

export const MiddleSection = styled(Base)`
  color: ${(props) => props.theme.footerColor};
  background-color: ${(props) => props.theme.footerBackgroundColor};
  background: ${(props) => props.theme.footerBackground};
  /* background-color: ${(props) => props.theme.colorBlack}; */
  /* background: linear-gradient(
    180deg,
    ${(props) => props.theme.colorLightBlack} 0%,
    ${(props) => props.theme.colorBlack} 100%
  ); */
  position: relative;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  /* margin-top: 20px; */
  display: flex;
  align-items: flex-end;
  &::before {
    background-repeat: no-repeat;
    background-position-y: top;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    top: -20px;
  }
`
