import { useMemo } from 'react'

import { ICartItem } from '@monorepo/cart_module'
import { useCurrentUser } from '@monorepo/user_module'
import { useCurrentContract } from '@monorepo/contract_module'
import { usePaymentMethods } from '@monorepo/payment_module'
import { useDeliveryMethods } from '@monorepo/delivery_module'

export function useInitialValues(items: ICartItem[]) {
  const { data: deliveryCustomerName } = useCurrentUser({
    select: (user) =>
      user
        ? [user?.lastName, user?.firstName, user?.middleName]
            .filter(Boolean)
            .join(' ')
        : '',
  })
  const { data: currentContract } = useCurrentContract()

  const { data: paymentMethod } = usePaymentMethods({
    select: (methods) =>
      methods?.find((method) => method.isDefault) ?? methods?.[0],
  })

  const { data: deliveryMethod } = useDeliveryMethods({
    select: (methods) => methods?.find((method) => method.isDefault),
  })

  return useMemo(
    () => ({
      contract: currentContract,
      paymentMethod,
      deliveryMethod,
      deliveryCustomerName,
      // deliveryAddress: undefined,
      // deliverySchedule: undefined,
      customerComment: '',
      items,
    }),
    [
      currentContract,
      paymentMethod,
      deliveryMethod,
      deliveryCustomerName,
      items,
    ]
  )
}
