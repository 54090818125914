import React from 'react'
import cn from 'classnames'

import { SearchField as BaseSearchField } from 'App/ui/search_field'

import style from './style.module.scss'

type PropsType = {
  $isDark?: boolean
}

export const SearchField: React.FC<PropsType> = ({ $isDark }) => (
  <div className={cn(style.container, 'header-woo-search-form')}>
    <BaseSearchField isDark={$isDark} />
  </div>
)
