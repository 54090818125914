import styled from 'styled-components'

import { PostItem as Base } from './post_item'

export * from './post_item'

export const PostItem = styled(Base)`
  & {
    main {
      padding: 0;

      article {
        background-color: ${(props) => props.theme.colorOrange};
        padding: 0.75rem;
        height: 100%;

        font-weight: ${(props) => props.theme.fontSemibold};
        font-size: 1em;
        text-align: justify;
        text-indent: 2rem;
        hyphens: auto;

        & a {
          color: ${(props) => props.theme.colorWhite};

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
`
