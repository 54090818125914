import React from 'react'

import { useAddCar } from '@monorepo/garage_module'
import { CompositButton } from 'App/Components'

type ControlProps = {
  //
}

export const Control: React.FC<ControlProps> = () => {
  const { createCar, isNewCarExists } = useAddCar()

  return (
    <CompositButton disabled={isNewCarExists} onClick={createCar}>
      Добавить автомобиль
    </CompositButton>
  )
}
