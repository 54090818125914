import React from 'react'
import { useParams } from 'react-router'

import { ResetPasswordSection } from 'App/PageComponents/Auth'
import { Card, Container } from 'App/PageComponents/Auth/window'
import { Page } from 'App/Layout'

const ResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>()

  return (
    <Page authenticated={false}>
      <Container>
        <Card>
          <Card.Body>
            <Card.Title className="lead mb-5">
              Установите новый пароль
            </Card.Title>
            <ResetPasswordSection token={token} />
          </Card.Body>
        </Card>
      </Container>
    </Page>
  )
}

export default ResetPassword
