import React from 'react'
import Container from 'react-bootstrap/Container'
import { Redirect, useLocation } from 'react-router-dom'
import qs from 'qs'
import { groupBy, map, toPairs } from 'lodash/fp'

import { Page } from 'App/Layout'
import {
  ILaximoVehicle,
  getVehiclePageUrl,
  useVehiclesList,
} from '@monorepo/laximo_module'

import { createBreadcrumbs } from 'App/PageComponents/Laximo/helpers/createBreadcrumbs'
import { CatalogErrorPage } from 'App/PageComponents/Laximo/catalog_error.page'
import { VehiclesTable } from 'App/PageComponents/Laximo/vehicles_table'

type VehiclesListPageProps = {
  //
}

const groupByMark = groupBy<ILaximoVehicle>((item) =>
  [item.brand, item.name].join(' ').toLowerCase()
)

// TODO: transform to functional form
const getMarks = (items: ILaximoVehicle[]) =>
  map((item) => {
    return {
      name: item[0],
      items: item[1],
    }
  }, toPairs(groupByMark(items)))

const VehiclesListPage: React.FC<VehiclesListPageProps> = () => {
  const location = useLocation()
  const {
    catalogCode = '',
    ssd = '',
    identString = '',
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as {
    ssd?: string
    catalogCode?: string
    identString?: string
  }

  const {
    data: { catalog, vehicles } = {},
    isFetched,
    isError,
  } = useVehiclesList({
    catalogCode,
    ssd,
    identString,
  })

  const marks = getMarks(vehicles || [])

  if (isFetched && isError) {
    return <CatalogErrorPage />
  }

  if (vehicles?.length === 1) {
    return <Redirect to={getVehiclePageUrl(vehicles[0])} />
  }

  return (
    <Page
      title="Найденные модификации:"
      breadcrumbs={createBreadcrumbs({ catalog })}
    >
      <Container>
        {marks.map((item) => (
          <VehiclesTable key={item.name} items={item.items} />
        ))}
      </Container>
    </Page>
  )
}

export default VehiclesListPage
