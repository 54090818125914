import React from 'react'
import { Container } from 'react-bootstrap'

import { ICompany } from '@monorepo/company_module'
import { OfficeItem } from './OfficeItem'
import { Page } from 'App/Layout'

type OfficeListProps = {
  items: ICompany[]
}

export const OfficeList: React.FC<OfficeListProps> = ({ items }) => (
  <>
    <Container>
      <div className="wpb_column vc_column_container">
        <div className="vc_column-inner">
          <div className="wpb_wrapper">
            <Page.Title>Наши магазины</Page.Title>
          </div>
        </div>
      </div>
    </Container>
    {items.map((item, idx) => (
      <OfficeItem key={idx} item={item} />
    ))}
  </>
)
