import React from 'react'
import qs from 'qs'
import { Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table'

import { ILaximoCatalog, ILaximoCategory } from '@monorepo/laximo_module'
import { UnitRow } from './components/unit_row'

export interface DetailsListProps {
  item: ILaximoCategory
  catalog: ILaximoCatalog
}

export const DetailsList: React.FC<DetailsListProps> = ({ item, catalog }) => {
  return (
    <section>
      <h5>
        В категории «
        <Link to={qs.stringify({}, { addQueryPrefix: true })}>{item.name}</Link>
        »:
      </h5>
      <Table responsive>
        <tbody>
          {item.units?.map((item) => (
            <UnitRow key={item.unitid} item={item} catalog={catalog} />
          ))}
        </tbody>
      </Table>
    </section>
  )
}
