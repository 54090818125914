import React, { isValidElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { IMenuItem } from '@monorepo/menu_module'
import { Link } from 'App/Components'

type ListItemProps = {
  item: IMenuItem
}

export const ListItem: React.FC<ListItemProps> = ({ item }) => (
  <li>
    <span>
      {item.icon ? (
        isValidElement(item.icon) ? (
          item.icon
        ) : (
          <FontAwesomeIcon icon={item.icon as IconProp} fixedWidth />
        )
      ) : (
        <FontAwesomeIcon icon={faChevronRight} fixedWidth />
      )}
    </span>
    <Link to={item.url ?? '/'}>{item.name}</Link>
  </li>
)
