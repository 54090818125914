import styled from 'styled-components'

export const Slide = styled.div<{ $image: string }>`
  height: 300px;
  width: 100%;
  background-position: center;
  background-image: url(${(props) => props.$image});
  border-radius: ${(props) => props.theme.baseRadius};
  /* overflow: hidden; */
  background-size: cover;

  @media only screen and (max-width: 767px) {
    height: 175px;
    background-position: 70%;
    background-size: cover;
  }
`
