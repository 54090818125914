import styled from 'styled-components'

import Base from './MobileHeader'

export * from './MobileHeader'

export const MobileHeader = styled(Base).attrs({
  className:
    'header sticky-true' +
    ' et-mobile et-clearfix transparent-false shadow-true shadow-sticky-true mobile-true tablet-portrait-true tablet-landscape-true desktop-false',
})`
  & {
    color: ${(props) => props.theme.headerPrimaryColor};
    background-color: ${(props) => props.theme.headerBackgroundPrimaryColor};
    top: -54px !important;

    & a,
    & button {
      color: ${(props) => props.theme.headerPrimaryColor};
      &:hover,
      &:active {
        color: ${(props) => props.theme.headerPrimaryColor};
      }
    }
  }
`
