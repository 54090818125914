import { IRoute } from './interface'

import PayOrder from 'App/View/Orders/pay_order'
import OrderDetails from 'App/View/Orders/order_details'

export default (): IRoute[] => [
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/order/pay/:id`,
    component: PayOrder,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/order/details/:id`,
    component: OrderDetails,
  },
]
