import styled from 'styled-components'

export const THead = styled.div.attrs((props) => ({
  role: 'table-head',
}))`
  background-color: ${(props) => props.theme.colorOrange};
  box-shadow: inset ${(props) => props.theme.baseBoxShadow};
  border-bottom: 1px solid ${(props) => props.theme.colorOrange};
  color: ${(props) => props.theme.colorWhite};
  align-items: center;
`
