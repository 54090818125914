import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import ScrollToTop from 'react-scroll-up'
import { detect } from 'detect-browser'
import cn from 'classnames'
import styled, { keyframes } from 'styled-components'

import { Header } from './Header'
import { Footer } from './Footer'
import { ThemeProvider, ThemeVariant } from './theme'

import logoImage from 'App/Resources/images/logo/logo_white.svg'
import logoFooterImage from 'App/Resources/images/logo/logo_white.svg'
import { ReactComponent as LogoImage } from 'App/Resources/images/logo/logo.svg'
// import logoFooterImage from 'App/Resources/images/logo-mini.png'

export type LayoutProps = {
  //
}

const ScrollToTopIconAnimation = keyframes`
  from {
    border: 2px solid white;
    box-shadow: 0px 0px 4px 3px rgba(white, 0.5);
    filter: opacity(85%);
  }
  50% {
    border: 1px solid white;
    box-shadow: 0px 0px 3px 1px rgba(white, 0.5);
    filter: opacity(50%);
  }
  to {
    border: 2px solid white;
    box-shadow: 0px 0px 4px 3px rgba(white, 0.5);
    filter: opacity(85%);
  }
`

const ScrollToTopIcon = styled(FontAwesomeIcon).attrs({
  size: '3x',
  icon: faArrowAltCircleUp,
})`
  background-color: ${(props) => props.theme.light};
  border: 2px solid ${(props) => props.theme.light};
  color: ${(props) => props.theme.primary};
  border-radius: 50%;
  /* transition: 1s ease-in-out; */
  animation: ${ScrollToTopIconAnimation} 2s infinite ease-in-out;

  &:hover {
    filter: none;
    animation: none;
  }
`

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const browser = useMemo(detect, [])

  return (
    <ThemeProvider defaultThemeVariant={ThemeVariant.LIGHT}>
      <div id="gen-wrap" style={{ overflowX: 'clip' }}>
        <div
          id="wrap"
          className={cn({
            [`browser-${browser?.name}`]: browser?.name,
          })}
        >
          <Header logoImage={logoImage} />
          <div className="page-content-wrap">
            {children}
            <Footer />
          </div>
        </div>
        <ScrollToTop showUnder={160} duration={250} style={{ zIndex: 999 }}>
          <ScrollToTopIcon />
        </ScrollToTop>
      </div>
    </ThemeProvider>
  )
}
