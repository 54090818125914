import React from 'react'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'

export interface IAdvantageItem {
  image: string
  content: string
}

type AdvantageItemProps = {
  item: IAdvantageItem
}

const Wrapper = styled(Col).attrs({
  lg: 4,
  md: 4,
  sm: 4,
  xs: 12,
})`
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */
`

const Text = styled.div`
  hyphens: auto;
  text-indent: 2em;
  text-align: justify;
  color: ${(props) => props.theme.dark};
`

const Image = styled.img`
  margin-bottom: 25px;
`

export const AdvantageItem: React.FC<AdvantageItemProps> = ({ item }) => (
  <Wrapper className="mb-4 mb-sm-0">
    <Image className="img-responsive" src={item.image} alt="" />
    <Text>{item.content}</Text>
  </Wrapper>
)
