import { ILaximoVehicle } from '@monorepo/laximo_module'
import { apiClient } from '@monorepo/core'
import {
  CatalogCode,
  ILaximoCatalog,
  ILaximoCategory,
  CategoryId,
  VehicleId,
  Nullable,
  ILaximoUnit,
} from '../interfaces'

export interface IGetVehicleResponse {
  readonly catalog: Nullable<ILaximoCatalog>
  readonly vehicle: Nullable<ILaximoVehicle>
  readonly categories: Nullable<ILaximoCategory[]>
  readonly units: Nullable<ILaximoUnit[]>
}

interface QueryParams {
  catalogCode: CatalogCode
  ssd: string
  vehicleId?: VehicleId
  categoryId?: CategoryId
}

export async function fetchVehicle(params: QueryParams) {
  try {
    const { data } = await apiClient.get<IGetVehicleResponse>(
      '/laximo2/vehicle',
      { params }
    )

    return data
  } catch (error) {
    throw error
  }
}
