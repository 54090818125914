import { compact, flow, get, map, merge, reduce, values } from 'lodash/fp'
import {
  ILaximoVehicle,
  ILaximoVehicleOption,
  OptionsList,
} from '@monorepo/laximo_module'

const getAllAttributes = map<ILaximoVehicle, OptionsList | null>(
  get('attributes')
)
const mergeAttributes = reduce<OptionsList, OptionsList>(merge, {})
export const getAttributes: (
  items: ILaximoVehicle[]
) => ILaximoVehicleOption[] = flow(
  getAllAttributes,
  compact,
  mergeAttributes,
  values
)
