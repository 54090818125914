import styled from 'styled-components'

export const SlideTitle = styled.span`
  display: inline-block;
  margin-bottom: 5px;
  margin-left: 140px;
  padding: 1px 10px 5px 7px;
  color: ${(props) => props.theme.colorLightBlack};
  font-weight: 500;
  font-size: 32px;
  line-height: 1;
  background-color: ${(props) => props.theme.colorWhite};
  /* background-color: ${(props) => props.theme.colorOrange}; */
  border-radius: ${(props) => props.theme.baseRadius};
  /* mix-blend-mode: hard-light; */

  &:first-child {
    margin-top: 80px;
  }

  @media (max-width: 767px) {
    font-size: 22px;
    margin-left: 15px;
    padding-left: 5px;
    padding-right: 5px;

    &:first-child {
      padding-right: 5px;
      margin-top: 35px;
    }
  }
`
