import React, { useCallback, useEffect, useState } from 'react'
import qs from 'qs'
import { useHistory } from 'react-router'
import defer from 'lodash/defer'

import { IOrder } from '@monorepo/order_module'
import { IPaymentContext, PaymentContext } from './payment.context'
import { paymentSystems } from '../payment_systems'

export enum PaymentStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}
export interface PaymentProviderProps {
  order: IOrder
  onSuccess?(): void
  onError?(): void
  orderNotFound?: React.ReactNode
  children?: ((context: IPaymentContext) => React.ReactNode) | React.ReactNode
}

export const PaymentProvider: React.FC<PaymentProviderProps> = ({
  children,
  order,
  ...props
}) => {
  const history = useHistory()
  const [isPaid, setPaid] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const paymentSystemName = order?.paymentMethod?.provider
  // const paymentSystemName = 'sberbank'

  const PaymentComponent = paymentSystems?.[paymentSystemName ?? '']

  const context = {
    isPaid,
    error,
  }

  const redirectUrl = order ? `/order/details/${order.id}` : ''
  const successUrl = order
    ? [
        redirectUrl,
        qs.stringify(
          { payment: PaymentStatus.SUCCESS },
          { addQueryPrefix: true }
        ),
      ].join('')
    : ''
  const errorUrl = order
    ? [
        redirectUrl,
        qs.stringify(
          { payment: PaymentStatus.FAILURE },
          { addQueryPrefix: true }
        ),
      ].join('')
    : ''

  const successHandler = useCallback(() => {
    setPaid(true)
    props.onSuccess?.()
    defer(history.replace, successUrl, { order })
  }, [history.replace, order, props.onSuccess, successUrl])

  const errorHandler = useCallback(
    (error?: string) => {
      setError(error ?? 'При оплате возникла ошибка')
      props.onError?.()
    },
    [props]
  )

  useEffect(() => {
    if (!PaymentComponent) {
      history.replace(redirectUrl)
    }
  }, [PaymentComponent, history, redirectUrl, successHandler, successUrl])

  return (
    <PaymentContext.Provider value={context}>
      {!isPaid && PaymentComponent && (
        <PaymentComponent
          order={order}
          successUrl={successUrl}
          errorUrl={errorUrl}
          onSuccess={successHandler}
          onError={errorHandler}
        />
      )}
      {typeof children === 'function' ? children(context) : children}
    </PaymentContext.Provider>
  )
}
