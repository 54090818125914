import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'

import { Link } from 'App/Components'
import brandsSprite from 'App/Resources/images/brands-sprite.svg'
import { getCatalogPageUrl, ILaximoCatalog } from '@monorepo/laximo_module'

export type ItemProps = React.HTMLProps<HTMLDivElement> & {
  item: ILaximoCatalog
  isDark?: boolean
}

const IconSvg = styled.svg`
  width: 56px;
  height: auto;
  max-height: 40px;
  transition: all 250ms ease;
  overflow: visible;
`

const Container = styled.div<{ $isDark: boolean }>`
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */
  background-color: ${(props) =>
    props.$isDark ? props.theme.colorOrange : props.theme.colorLightGrey};
  /* border: 1px solid
    ${(props) => (props.$isDark ? props.theme.primary : props.theme.grey)}; */
  border-radius: ${(props) => props.theme.baseRadius};
  transition: background-color 350ms ease;

  &:hover {
    background-color: ${(props) =>
      props.$isDark ? props.theme.colorOrange : props.theme.colorWhite};

    a {
      text-decoration: none;
    }
  }

  & h6 {
    font-size: 1rem;
    color: ${(props) =>
      props.$isDark ? props.theme.colorWhite : props.theme.colorOrange};
  }

  & ${IconSvg} {
    filter: opacity(0.75);
    fill: ${(props) =>
      props.$isDark ? props.theme.colorWhite : props.theme.colorBlack};
  }

  &:hover ${IconSvg} {
    /* fill: ${(props) => props.theme.danger}; */
    filter: none;
    transform: scale(1.25);
  }
`

export const Item: React.FC<ItemProps> = ({
  item,
  className,
  isDark = false,
}) => (
  <Container
    $isDark={isDark}
    // className={`link et-icon-box et-make et-item icon-position-top icon-alignment-center ${style.container}`}
    className={cn(
      className,
      'link et-icon-box et-make et-item icon-position-top icon-alignment-center'
    )}
  >
    <div className="et-icon-box-inner et-item-inner et-clearfix">
      <Link to={getCatalogPageUrl(item)} title={item.name}>
        {/* <div className="et-icon mercedes-benz">{item.icon}</div> */}
        <IconSvg>
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref={[brandsSprite, item.icon].join('#')}
          />
        </IconSvg>
        <div className="et-icon-content et-clearfix">
          <h6 className="et-icon-box-title et-make-title">{item.name}</h6>
        </div>
      </Link>
    </div>
  </Container>
)
