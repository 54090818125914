import React, { useState, useCallback } from 'react'
import cn from 'classnames'
import { useField } from 'formik'
import clamp from 'lodash/clamp'

import style from './style.module.scss'

type NumberInputPropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  containerClassName?: string
  name: string
}

export const NumberInput: React.FC<NumberInputPropsType> = ({
  className,
  containerClassName,
  ...props
}) => {
  const { value: initialValue, defaultValue } = props
  const [value, setValue] = useState(initialValue ?? defaultValue)
  const [, , /*field*/ /*meta*/ helpers] = useField(props)

  const updateValue = (changeValue: number) => {
    const nextValue = clamp(
      Number(value) + changeValue,
      typeof props.min === 'undefined' ? -Infinity : Number(props.min),
      typeof props.max === 'undefined' ? Infinity : Number(props.max)
    )
    setValue(nextValue)
    helpers.setValue(nextValue)
  }

  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = Number(event.target.value)
      setValue(nextValue)
      helpers.setValue(nextValue)
    },
    [helpers]
  )

  return (
    <div className={cn(style.container, containerClassName)}>
      <input
        className={cn(style.input, className)}
        type="number"
        {...props}
        value={value}
        onChange={changeHandler}
      />
      <div
        className={style.addButton}
        onClick={updateValue.bind(null, Number(props.step) || 1)}
      />
      <div
        className={style.subButton}
        onClick={updateValue.bind(null, -Number(props.step) || -1)}
      />
    </div>
  )
}
