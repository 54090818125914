import styled from 'styled-components'

export const FirstLetter = styled.span`
  display: inline-block;
  width: 24px;
  font-size: 24px;
  margin-right: 13px;
  font-weight: 700;
  color: ${(props) => props.theme.primary};
  line-height: 50px;
`
