import React from 'react'
import { FormattedDate } from 'react-intl'
import { faReceipt } from '@fortawesome/free-solid-svg-icons'

import { IOrder } from '@monorepo/order_module'
import { Callout } from 'App/Components/Callout'
import { MainInfo } from './main_info'

type OrderDetailsProps = {
  item?: IOrder
  isFetching?: boolean
  isFetched?: boolean
}

export const OrderDetails: React.FC<OrderDetailsProps> = ({
  item,
  isFetching,
  isFetched,
}) => {
  if (isFetching) {
    return <Callout loading>Получение статуса заказа...</Callout>
  }

  return (
    <>
      <Callout icon={faReceipt}>
        {!item ? (
          'Заказ не найден'
        ) : (
          <span className="lead">
            Заказ : {item?.number} от <FormattedDate value={item?.createdAt} />
          </span>
        )}
      </Callout>

      {item && <MainInfo item={item} />}
    </>
  )
}
