import React, { useCallback } from 'react'
import { Form } from 'react-bootstrap'
import { Form as FormikForm } from 'formik'
import { toast } from 'react-toastify'
import { Link, useLocation } from 'react-router-dom'
import qs from 'qs'
import styled from 'styled-components'

import { RegistrationProvider } from '@monorepo/auth_module'
import { FormError } from '@monorepo/application_module/components/form_error'
import { AuthSection } from './auth.section'
import { ProfileSection } from './profile.section'
import { CompositButton } from 'App/Components'
import { RegistrationDTO } from '@monorepo/auth_module/dto'

type RegistrationProps = {
  //
}

const SuccessToast = styled.p`
  line-height: 1.25;
  font-size: 15px;
`

export const Registration: React.FC<RegistrationProps> = () => {
  const errorHandler = useCallback((error: FormError) => {
    toast.error(
      <>
        {error.errors.map((error, idx) => (
          <div key={idx}>{error.message}</div>
        ))}
      </>
    )
  }, [])

  const successHandler = useCallback((dto: RegistrationDTO) => {
    toast.info(
      <SuccessToast>
        Уважаемый клиент, благодарим вас за регистрацию в нашем
        интернет-магазине. Письмо с логином и паролем отправлено на указанный
        адрес электронной почты.
      </SuccessToast>,
      {
        autoClose: 30 * 1000,
      }
    )
  }, [])

  const { search } = useLocation<{ email?: string }>()

  const { email } = qs.parse(search, { ignoreQueryPrefix: true })

  return (
    <RegistrationProvider
      email={typeof email === 'string' ? email : undefined}
      onError={errorHandler}
      onSuccess={successHandler}
    >
      <Form as={FormikForm} className="form-auth-small">
        <div className="body p-0 p-md-4">
          {/* <h6>Параметры авторизации</h6> */}
          <AuthSection />
        </div>
        <div className="body p-0 p-md-4">
          <h6>Основная информация</h6>
          <ProfileSection />
        </div>
        <div className="body p-0 p-md-2 col-md-4 offset-md-4">
          <CompositButton size="medium" type="submit" block>
            Зарегистрироваться
          </CompositButton>
        </div>
        <div className="body p-0 p-md-2 col-md-4 offset-md-4 text-center">
          <span>
            Уже зарегистрированы? <Link to="/login">Войти</Link>
          </span>
        </div>
      </Form>
    </RegistrationProvider>
  )
}
