import React from 'react'
import truncate from 'lodash/truncate'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// import { ICartItem } from '../../../../../../../interfaces/cart-item.interface'
// import { getSearchUrl } from '../../../../../../../components/good/link'
// import { Image } from './Image'
import { getSearchUrl } from '@monorepo/application_module/helpers'
import { IGood } from '@monorepo/application_module'
import { LightBox } from '@monorepo/application_module/components/light_box'
import { ImagePlaceholder as BaseImagePlaceholder } from 'App/Components'
// import { ImagePlaceholder } from '../../../../ImagePlaceholder'
// import { Image } from '../../../../Image'

type GoodCardProps = {
  item: IGood
}

const Image = styled.img<{ size: number }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  object-fit: contain;
`

const ImagePlaceholder = styled(BaseImagePlaceholder)<{ size: number }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  object-fit: contain;
`

const BarndName = styled.strong`
  color: ${(props) => props.theme.primary};
  min-width: 50%;
  /* font-weight: ${(props) => props.theme.fontSemibold}; */
`
const Article = styled.span`
  /* color: ${(props) => props.theme.colorLightBlack}; */
  min-width: 50%;
  font-weight: ${(props) => props.theme.fontBold};
`

export const GoodCard: React.FC<GoodCardProps> = ({ item }) => (
  <>
    <div className="d-flex justify-content-stretch">
      <div className="margin-right-10 p-2">
        {item.images?.length && item.thumbs?.length ? (
          <LightBox images={item.images}>
            <Image
              size={75}
              // height={50}
              // width={50}
              src={item.thumbs[0]}
              className="img-thumbnail"
              // unloader={<ImagePlaceholder />}
              // lazy={{ once: true, debounce: true }}
              alt=""
            />
          </LightBox>
        ) : (
          <ImagePlaceholder
            size={75}
            height={75}
            width={75}
            className="img-thumbnail"
          />
        )}
        {/* <Image item={item} height={48} /> */}
      </div>
      <div
        className="d-flex align-items-center w-100"
        style={{ borderBottom: '1px dotted #666' }}
      >
        <Link to={getSearchUrl(item)} className="d-flex flex-wrap w-100">
          <BarndName style={{ minWidth: '50%' }} className="text-break">
            {item.brand.name}
          </BarndName>{' '}
          <Article style={{ minWidth: '50%' }} className="text-break">
            {item.number.toUpperCase()}
          </Article>
        </Link>
      </div>
    </div>
    <em>
      <small>{truncate(item.name, { length: 120 })}</small>
    </em>
  </>
)
