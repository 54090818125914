import React from 'react'
import { orderBy } from 'lodash/fp'
import { faRubleSign } from '@fortawesome/free-solid-svg-icons'

import {
  useOperations,
  IOperationsFilter,
  IContractOperation,
} from '@monorepo/contract_module'
import { Callout } from 'App/Components/Callout'
import { Table, THead, TR } from 'App/Components/table'
import {
  DecDebtCell,
  EndBallanceCell,
  IncDebtCell,
  OperationCell,
  StartBallanceCell,
  TypeCell,
} from './components/cells'
import { OperationRow } from './components/operation_row'

type OperationsTableProps = {
  filter: IOperationsFilter
}

const sortOperations: (data: IContractOperation[]) => IContractOperation[] =
  orderBy<IContractOperation>(['createdAt', 'number'], ['desc', 'desc'])

export const OperationsTable: React.FC<OperationsTableProps> = ({ filter }) => {
  const {
    data: items = [],
    isFetching,
    isFetched,
  } = useOperations(filter, {
    select: sortOperations,
  })

  if (isFetching) {
    return (
      <Callout loading>
        <span className="lead">Загрузка финансовых операций...</span>
      </Callout>
    )
  }

  if (isFetched && items.length === 0) {
    return (
      <Callout icon={faRubleSign}>
        <span className="lead">Финансовых операций не найдено</span>
      </Callout>
    )
  }

  return items.length > 0 ? (
    <Table className="mb-5">
      <THead className="d-none d-md-block">
        <TR className="align-items-center">
          <OperationCell className="text-left">Операция</OperationCell>
          <TypeCell className="text-center">Тип</TypeCell>
          <StartBallanceCell className="text-center">
            Начальный остаток
          </StartBallanceCell>
          <IncDebtCell className="text-center">Увеличение долга</IncDebtCell>
          <DecDebtCell className="text-center">Уменьшение долга</DecDebtCell>
          <EndBallanceCell className="text-center">
            Конечный остаток
          </EndBallanceCell>
        </TR>
      </THead>
      {items?.map((operation, idx) => (
        <OperationRow key={idx} item={operation} />
      ))}
    </Table>
  ) : null
}
