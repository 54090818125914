import React from 'react'
import styled from 'styled-components'

type CheckBoxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string
}

const Wrapper = styled.span`
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
`

const Icon = styled.span`
  pointer-events: none;
  position: absolute;
  left: 4px;
  top: 5px;
  fill: ${(props) => props.theme.light};
  transform: scale(0);
  transition: fill 0.15s, transform 0.15s, visibility 0s 0.15s;
  svg {
    display: block;
  }
`
const Box = styled.span`
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 1.2px;
  background: ${(props) => props.theme.light};
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  transition: background 0.15s, box-shadow 0.15s;
`

const Input = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
  &:checked {
    & ~ ${Box} {
      background: ${(props) => props.theme.primary};
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
    }
    & ~ ${Icon} {
      fill: ${(props) => props.theme.light};
      transition-delay: 0s, 0s, 0s;
      transform: scale(1);
    }
  }
  &:disabled {
    & ~ ${Box} {
      background: #f2f2f2;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    }
    & ~ ${Icon} {
      fill: rgba(0, 0, 0, 0.2);
    }
  }
`

export const CheckBox: React.FC<CheckBoxProps> = ({
  className,
  style,
  ...props
}) => (
  <Wrapper className={className} style={style}>
    <Input type="checkbox" {...props} />
    <Box />
    <Icon>
      <svg xmlns="http://www.w3.org/2000/svg" width="9px" height="7px">
        <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z" />
      </svg>
    </Icon>
  </Wrapper>
)
