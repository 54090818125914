import styled from 'styled-components'

export const Title = styled.h3`
  font-size: ${(props) => `${parseInt(props.theme.baseFontSize) * 2}px`};
  font-weight: ${(props) => props.theme.fontSemibold};
  margin-bottom: 0;
  color: ${(props) => props.theme.dark};
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: ${(props) => `${parseInt(props.theme.baseFontSize) * 1.25}px`};
    text-align: right;
  }
`
