import React from 'react'
import { Container } from 'react-bootstrap'
import cn from 'classnames'

import { YandexMap } from '@monorepo/components/common'
import { ICompany } from '@monorepo/company_module'
// import { ImagesList } from './ImagesList'

import style from './style.module.scss'
import { Card } from './components/card'
import { ContactsBlock } from './components/contacts_block'
import { ScheduleBlock } from './components/schedule_block'

type OfficeItemProps = {
  item: ICompany
}

export const OfficeItem: React.FC<OfficeItemProps> = ({ item }) => (
  <div className={cn(style.container, 'py-5')}>
    <Container className="et-clearfix">
      <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none vc-column-712819">
        <div className="vc_column-inner~">
          <div className="wpb_wrapper">
            <div className="vc_row wpb_row vc_inner vc_row-fluid vc_column-gap-24 vc_row-o-content-middle vc_row-flex vc-row-436225 mb-4">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <h3>{item.name}</h3>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <Card>
                  <YandexMap
                    height={300}
                    zoom={item.address.mapPlace?.zoom}
                    center={[
                      item.address.mapPlace?.center.lon,
                      item.address.mapPlace?.center.lat,
                    ]}
                  />
                </Card>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <ContactsBlock office={item} />
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <ScheduleBlock office={item} />
              </div>
              {/* {item.images && (
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <ImagesList items={item.images} />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
)
