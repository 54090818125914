import React, { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import qs from 'qs'
import { Container } from 'react-bootstrap'

// import { Blog, useQueryState } from '@monorepo/hooks'
import { htmlParser } from '@monorepo/application_module/helpers'
import { getPostUrl, useBlogPostsList } from '@monorepo/blog_post_module'
import { Page } from 'App/Layout'
import { CompositButton, Pagination } from 'App/Components'

import { BlogLayout } from '../components/blog_layout'
import { BlogPost } from '../components/post'
// import { Sidebar } from './Sidebar'
// import { PostItem } from './PostItem'

type PropsType = {
  //
}

function isPageValid(page: number, totalPages: number = Infinity) {
  return !(Number.isNaN(page) || page < 1 || page > totalPages)
}

export const PostsList: React.FC<PropsType> = () => {
  const location = useLocation()
  const history = useHistory()

  const page = useMemo(() => {
    const { page = '1' } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as {
      page?: string
    }
    return parseInt(page)
  }, [location.search])

  const { data } = useBlogPostsList(
    { perPage: 5, page },
    { enabled: isPageValid(page) }
  )

  useEffect(() => {
    if (!isPageValid(page, data?.totalPages)) {
      const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })
      history.push({
        ...location,
        search: qs.stringify({ ...query, page: undefined }),
      })
    }
  }, [data, history, location, page])

  return (
    <Page
      topSpace
      bottomSpace
      padding={false}
      sidebar="right"
      className="blog-layout-single"
      title="Блог"
    >
      <Container>
        <Page.Title>Наш блог</Page.Title>
      </Container>
      <BlogLayout className="blog-content">
        <div className="blog-view__item blog-view__item-posts">
          {data && (
            <div className="block posts-view">
              <div className="posts-view__list posts-list posts-list--layout--classic">
                <div className="posts-list__body">
                  {data.items.map((post, idx) => (
                    <React.Fragment key={idx}>
                      <BlogPost
                        title={post.title}
                        category={post.categoryName}
                        createdAt={post.createdAt}
                        image={post.postImage}
                        url={getPostUrl(post)}
                      >
                        {!!post.short && htmlParser(post.short)}
                        <div className="text-right">
                          <CompositButton to={getPostUrl(post)}>
                            Читать далее
                          </CompositButton>
                        </div>
                        {idx + 1 !== data.items.length && (
                          <hr className="my-5" />
                        )}
                      </BlogPost>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              {data.totalPages > 1 && (
                <div className="posts-view__pagination d-flex justify-content-center">
                  <Pagination
                    meta={data}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </BlogLayout>
    </Page>
  )
}
