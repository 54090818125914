import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { IDeliveryMethod } from '../interfaces'
import { fetchDeliveryMethods } from '../api'

const QUERY_KEY = 'delivery-methods'

export function useDeliveryMethods<TData = IDeliveryMethod[]>(
  options?: UseQueryOptions<IDeliveryMethod[], AxiosError, TData>
) {
  return useQuery(QUERY_KEY as any, fetchDeliveryMethods, {
    refetchOnWindowFocus: false,
    ...options,
  })
}
