import { useQuery, UseQueryOptions } from 'react-query'

import { IOffer } from '@monorepo/application_module'
import { fetchPriceInfo } from '../api/fetch_price_info'
import { IPriceCalculation, PriceLevelId } from '../interfaces'

type UseInfoParams = {
  itemKey: IOffer['itemKey']
  priceLevel?: PriceLevelId
}

// export function useBlogPostsList<TData = IPagination<IBlogPost>>(
//   params?: string | PostsFilter,
//   options?: UseQueryOptions<IPagination<IBlogPost>, unknown, TData>
// ) {
export function useInfo<TData = IPriceCalculation>(
  { itemKey, priceLevel }: UseInfoParams,
  options?: UseQueryOptions<IPriceCalculation | null, any, TData>
) {
  return useQuery(
    ['fetchPriceInfo', itemKey, priceLevel] as any,
    () => fetchPriceInfo(itemKey, priceLevel),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      ...options,
    }
  )
}
