import React, { useMemo } from 'react'
import chunk from 'lodash/chunk'
import sampleSize from 'lodash/sampleSize'
import SwiperCore, { Autoplay, Pagination, SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'

import { useSwiperControl } from '@monorepo/swiper_module'
import { ItemsGroup } from './item_group'

export type SliderProps<T> = {
  items: T[]
  stopOnHover?: boolean
  swiperOptions?: SwiperOptions
  rows?: number
  renderItem(item: T): React.ReactNode
}

SwiperCore.use([Autoplay, Pagination])

const defaultSwiperOptions: SwiperOptions = {
  autoplay: { delay: 5000 },
  // slidesPerView: 5,
  spaceBetween: 15,
  speed: 1000,
  // breakpoints: {
  //   320: {
  //     slidesPerView: 1,
  //     spaceBetween: 15,
  //   },
  //   768: {
  //     slidesPerView: 2,
  //     spaceBetween: 15,
  //   },
  //   1024: {
  //     slidesPerView: 5,
  //     spaceBetween: 15,
  //   },
  // },
  loop: true,
  // navigation: true,
  // pagination: true,
}

export function Slider<T>({
  items,
  stopOnHover = true,
  swiperOptions: mainSwiperOptions,
  rows = 1,
  renderItem,
}: SliderProps<T>) {
  const { bind } = useSwiperControl({ stopOnHover })

  const groups = useMemo(
    () =>
      chunk(
        items.concat(
          sampleSize(
            items,
            Math.ceil(items.length / rows) * rows - items.length
          )
        ),
        rows
      ),
    [items, rows]
  )

  const swiperOptions = useMemo(
    () => ({
      ...defaultSwiperOptions,
      ...mainSwiperOptions,
      ...bind,
    }),
    [bind, mainSwiperOptions]
  )

  return (
    <Swiper {...swiperOptions}>
      {groups.map((items, row) => (
        <SwiperSlide key={row}>
          <ItemsGroup key={row} className="et-item">
            {React.Children.toArray(items.map(renderItem))}
            {/* {items.map((item, col) => (
              <GoodItem key={col} item={item} height={rowHeight} />
            ))} */}
          </ItemsGroup>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
