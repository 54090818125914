import { apiClient } from '@monorepo/core'
import { IPriceCalculation, PriceLevelId } from '../interfaces'

export async function fetchPriceInfo(
  itemKey?: string,
  priceLevel?: PriceLevelId
) {
  if (!itemKey) {
    return Promise.resolve(null)
  }
  try {
    const { data } = await apiClient.get<IPriceCalculation>(
      `/v2/offer/price-calculation-info/${itemKey}`,
      {
        params: { priceLevel },
      }
    )
    return data
  } catch (error) {
    return null
  }
}
