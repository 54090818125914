import qs from 'qs'

import { ILaximoCatalog } from '../interfaces'
import { getCatalogFeatures } from './getCatalogFeatures'

// const BASE_URL = '/catalogs'

// const template = `${BASE_URL}/%catalogCode%`

// type Query = string | number | boolean

// const generateURL = (
//   tpl: string,
//   query: Record<string, Query | Query[]> = {}
// ) => {
//   return [BASE_URL, tpl.replace]
// }

export function getCatalogPageUrl(item: ILaximoCatalog) {
  const wizard = getCatalogFeatures(item, 'wizardsearch2')

  const queryString = qs.stringify({ wizard }, { addQueryPrefix: true })
  return `/catalogs/${item.code}${queryString}`
}
