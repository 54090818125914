import { AxiosError } from 'axios'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import { ILaximoCatalog } from '../interfaces'
import { fetchCatalogsList } from '../api/fetch_catalogs_list.api'
import { defaultOptions } from './_default_query_options'

const QUERY_KEY = 'laximo_catalogs'

export function useCatalogsList<TData = ILaximoCatalog[]>(
  options?: UseQueryOptions<ILaximoCatalog[], AxiosError, TData>
) {
  return useQuery(QUERY_KEY as QueryKey, fetchCatalogsList, {
    ...defaultOptions,
    ...options,
  })
}
