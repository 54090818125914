import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'

import { IOffer } from '@monorepo/application_module'
import { CompositButton } from 'App/Components/Button'

type ControlProps = {
  toggle(): void
  expand(): void
  collapse(): void
  isExpanded: boolean
  totalItems: number
  items: IOffer[]
}

export const Control: React.FC<ControlProps> = ({
  toggle,
  isExpanded,
  totalItems,
  items,
}) => (
  <div className="row py-1">
    <div className="col d-flex justify-content-end align-items-center">
      <em>
        <FormattedMessage
          id="search_table.found_total"
          defaultMessage={`{amount, plural,
              =0 {Ничего не найдено}
              one {Найдено # предложение}
              few {Найдено # предложения}
              many {Найдено # предложений}
              other {Найдено # предложений}
            }
          `}
          values={{ amount: totalItems }}
        />
      </em>
      {(totalItems > items.length || isExpanded) && (
        <CompositButton
          outline
          className="ml-2 mr-1 py-0"
          size="small"
          onClick={toggle}
        >
          {isExpanded ? 'Свернуть' : 'Показать ещё'}
          &nbsp;
          <FontAwesomeIcon
            icon={faChevronDown}
            rotation={isExpanded ? 180 : undefined}
          />
        </CompositButton>
      )}
    </div>
  </div>
)
