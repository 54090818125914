import React from 'react'
import { Card } from 'react-bootstrap'
import cn from 'classnames'
// import { CardBody, Body } from 'react-bootstrap/Card'

type PaddingType = 2

type PropsType = {
  children: React.ReactNode
  padding?: PaddingType
  className?: string
}

export const BodyWithPaddings: React.FC<PropsType> = React.forwardRef<
  any,
  PropsType
>((props, ref?) => {
  const { padding = 2, children, className, ...rest } = props

  return (
    <Card.Body
      {...rest}
      ref={ref}
      className={cn(className, {
        [`card-body--padding--${padding}`]: padding,
      })}
    >
      {children}
    </Card.Body>
  )
})
