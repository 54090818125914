import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { CarId } from '../interfaces'

export async function removeCar(carId: CarId) {
  try {
    const { data } = await apiClient.post(`/cars/remove`, { id: carId })
    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при удалении')
  }
}
