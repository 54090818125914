import React from 'react'
import { Link } from 'react-router-dom'
import { Field, Form } from 'formik'
import { toast } from 'react-toastify'

import { LoginProvider, useAuthenticatation } from '@monorepo/auth_module'
import { FormError } from '@monorepo/application_module/components/form_error'
import { Page } from 'App/Layout'
import { CompositButton } from 'App/Components'
import { Card, Container } from 'App/PageComponents/Auth/window'
import { useCurrentUser } from 'packages/user_module'
import { Callout } from 'App/Components/Callout'

const Login: React.FC = () => {
  const { isFetched } = useCurrentUser()
  const { isAuthenticated } = useAuthenticatation()
  const handleError = (error: FormError) => {
    toast.error(error.message)
  }

  if (isAuthenticated && !isFetched) {
    return (
      <Page>
        <Container>
          <Callout loading>Загрузка текущей сессии...</Callout>
        </Container>
      </Page>
    )
  }

  return (
    <Page authenticated={false}>
      <Container>
        <Card>
          <Card.Body>
            <Card.Title className="lead mb-5">Авторизация на сайте</Card.Title>
            <LoginProvider onError={handleError}>
              <Form className="mb-4">
                <div className="form-group">
                  <label className="control-label sr-only">Email</label>
                  <Field
                    className="form-control"
                    id="signin-email"
                    placeholder="Email"
                    name="username"
                    type="email"
                  />
                </div>
                <div className="form-group">
                  <label className="control-label sr-only">Пароль</label>
                  <Field
                    className="form-control"
                    id="signin-password"
                    placeholder="Password"
                    name="password"
                    type="password"
                  />
                </div>
                {/* <div className="form-group clearfix">
                  <label className="fancy-checkbox element-left">
                    <input type="checkbox" />
                    <span>Remember me</span>
                  </label>
                </div> */}
                <CompositButton className="w-100" type="submit">
                  Войти
                </CompositButton>
                {/* <Link
                  className="btn btn-primary btn-lg btn-block"
                  to="/dashboard"
                >
                  Войти
                </Link> */}
              </Form>
            </LoginProvider>
            <div className="bottom d-flex flex-column align-items-center">
              <span className="helper-text m-b-10">
                <i className="fa fa-lock"></i>{' '}
                <Link to="/forgotpassword">Забыли пароль?</Link>
              </span>
              <span>
                Ещё нет аккаунта? <Link to="/registration">Регистрация</Link>
              </span>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </Page>
  )
}

export default Login
