import React from 'react'
import { Field } from 'formik'

import { AddressField } from '@monorepo/dadata_module'
import { useOfficesList } from '@monorepo/company_module'
import { BaseFormGroup, SelectField } from 'App/Components/FormFields'
import { renderOfficeName } from 'App/helpers/render_office_name'

type ProfileSectionProps = {
  //
}

export const ProfileSection: React.FC<ProfileSectionProps> = () => {
  const { data: offices } = useOfficesList()

  return (
    <div className="row clearfix">
      <div className="col-lg-6 col-md-12">
        <BaseFormGroup name="firstName" label="Имя">
          <Field
            className="form-control"
            placeholder="Имя"
            type="text"
            name="firstName"
          />
        </BaseFormGroup>
        <BaseFormGroup name="middleName" label="Отчество">
          <Field
            className="form-control"
            placeholder="Отчество"
            type="text"
            name="middleName"
          />
        </BaseFormGroup>
        <BaseFormGroup name="lastName" label="Фамилия">
          <Field
            className="form-control"
            placeholder="Фамилия"
            type="text"
            name="lastName"
          />
        </BaseFormGroup>
      </div>
      <div className="col-lg-6 col-md-12">
        <BaseFormGroup name="office" label="Офис обслуживания">
          <SelectField
            name="office"
            items={offices}
            keyProp="id"
            valueProp={renderOfficeName}
            className="form-control"
          />
        </BaseFormGroup>
        <BaseFormGroup name="address" label="Адрес">
          <Field
            className="form-control"
            placeholder="Адрес"
            type="text"
            name="address"
            as={AddressField}
          />
        </BaseFormGroup>
        <BaseFormGroup name="phone" label="Телефон">
          <Field
            className="form-control"
            placeholder="Телефон"
            type="text"
            name="phone"
          />
        </BaseFormGroup>
      </div>
    </div>
  )
}
