import { curry } from 'lodash/fp'

import { FeatureName, ILaximoCatalog } from '../interfaces'

// const supportWizardSearch = supportFeature(catalog, 'wizardsearch2')
// const supportVinSearch = supportFeature(catalog, 'vinsearch')

export const getCatalogFeatures = curry(
  (catalog: ILaximoCatalog | null | undefined, feature: FeatureName) =>
    typeof catalog?.features?.find((item) => item.name === feature) !==
    'undefined'
)
