import React from 'react'

import SwiperCore, {
  Autoplay,
  Pagination,
  SwiperOptions,
  Virtual,
  EffectCoverflow,
  // EffectCube,
  // EffectFade,
  // EffectFlip,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ISlide } from '@monorepo/slide_module'
import { useSwiperControl } from '@monorepo/swiper_module'
import { SlideButton } from './components/SlideButton'
import { SlideTitle } from './components/SlideTitle'
import { Slide } from './components/Slide'

type MainSliderProps = {
  items: ISlide[]
}

SwiperCore.use([
  Autoplay,
  Pagination,
  Virtual,
  EffectCoverflow,
  // EffectCube,
  // EffectFade,
  // EffectFlip,
])

const defaultSwiperOptions: SwiperOptions = {
  autoplay: { delay: 5000 },
  slidesPerView: 1,
  speed: 1000,
  effect: 'coverflow',
  loop: true,
  // navigation: true,
  pagination: true,
}

export const MainSlider: React.FC<MainSliderProps> = ({ items }) => {
  const { bind } = useSwiperControl({ stopOnHover: true })
  const swiperOptions = { ...defaultSwiperOptions }

  return (
    <Swiper {...bind} {...swiperOptions}>
      {items.map((item, idx) => (
        <SwiperSlide key={idx}>
          <Slide $image={item.image}>
            {item.title && <SlideTitle>{item.title}</SlideTitle>}
            {item.subTitle && (
              <>
                <br />
                <SlideTitle>{item.subTitle}</SlideTitle>
              </>
            )}
            {item.url && (
              <SlideButton to={item.url}>
                {item.buttonText ?? 'Открыть'}
              </SlideButton>
            )}
          </Slide>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
