import React from 'react'

import { ICompany } from '@monorepo/company_module'
import { ContactsInfo } from 'App/Components/contacts_info'
import { Card } from './card'

type ContactsBlockProps = {
  office: ICompany
}

export const ContactsBlock: React.FC<ContactsBlockProps> = ({ office }) => (
  <Card title="Контакты">
    <ContactsInfo office={office} />
  </Card>
)
