import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import {
  getCarName,
  ICar,
  UpdateCarProvider,
  useRemoveCar,
} from '@monorepo/garage_module'
import { CompositButton } from 'App/Components'
import { CarForm } from './car_form'
// import backgroundImage from 'App/Resources/images/layout/header-bg.png'

type CarItemProps = {
  item: ICar
}

function getFormId(item: ICar) {
  return `update-car-${item.id ?? 'new'}-form`
}

const Wrapper = styled(Card)`
  background-color: ${(props) => props.theme.colorWhite};
  border: 2px solid ${(props) => props.theme.colorOrange};
  border-radius: ${(props) => props.theme.baseRadius};
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */
`

const Header = styled(Card.Title)`
  background-color: ${(props) => props.theme.colorOrange};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    color: ${(props) => props.theme.light};
    margin: 0;
  }
`

export const CarItem: React.FC<CarItemProps> = ({ item }) => {
  const { mutate: removeCar } = useRemoveCar()
  const [isShowModal, setIsShowModal] = useState(false)

  const handleClose = setIsShowModal.bind(null, false)
  const handleShow = setIsShowModal.bind(null, true)

  return (
    <UpdateCarProvider item={item}>
      {({ values, isSubmitting, errors }) => (
        <>
          <Modal show={isShowModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Удалить автомобиль</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Вы уверены, что хотите удалить «{getCarName(values)}»?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Отмена
              </Button>
              <Button variant="danger" onClick={() => removeCar(item)}>
                Удалить
              </Button>
            </Modal.Footer>
          </Modal>
          <Wrapper>
            <Header>
              <h5 className="card-title">{getCarName(values)}</h5>
              <div>
                <CompositButton
                  type="submit"
                  form={getFormId(item)}
                  variant="primary"
                  className="mr-2"
                  disabled={isSubmitting}
                  inverted
                >
                  <FontAwesomeIcon icon={faSave} />
                </CompositButton>
                <CompositButton variant="danger" onClick={handleShow}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </CompositButton>
              </div>
            </Header>
            <Wrapper.Body className="card-body body">
              <Row>
                <Col md="2">
                  <Card.Img
                    variant="top"
                    src={require('./images/car_siluet.png')}
                  />
                </Col>
                <Col md="10">
                  <CarForm id={getFormId(item)} />
                </Col>
              </Row>
            </Wrapper.Body>
          </Wrapper>
        </>
      )}
    </UpdateCarProvider>
  )
}
