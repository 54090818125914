import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import { conforms, filter, find, flow, get, some, truncate } from 'lodash/fp'
import { faCog, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import {
  ILaximoMapObject,
  ILaximoUnit,
  ILaximoUnitDetail,
  useUnit,
} from '@monorepo/laximo_module'
import { Page } from 'App/Layout'
import { DetailsTable } from 'App/PageComponents/Laximo/components/details_table'
import { createBreadcrumbs } from 'App/PageComponents/Laximo/helpers/createBreadcrumbs'
import { CatalogErrorPage } from 'App/PageComponents/Laximo/catalog_error.page'
import { Callout } from 'App/Components/Callout'

type UnitPageProps = {
  //
}

const DetailsWrapper = styled(Col)`
  overflow: auto;
  height: 75vh;
`

const createTitle = flow(
  get<ILaximoUnit, keyof ILaximoUnit>('name'),
  truncate({ length: 50 })
)

const UnitPage: React.FC<UnitPageProps> = () => {
  const location = useLocation()

  const {
    ssd = '',
    unitId = '',
    catalogCode = '',
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as {
    ssd?: string
    unitId?: string
    catalogCode?: string
  }

  const [currentCode, setCurrentCode] = useState<string | null>(null)

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const {
    data: { catalog, details, unit, mapObjects, vehicle } = {},
    isFetching,
    isFetched,
    isError,
  } = useUnit({
    catalogCode,
    ssd,
    unitId,
  })

  const filterMapObjects = filter<ILaximoMapObject>(
    conforms({
      code: (code: string) =>
        some<ILaximoUnitDetail>({ codeonimage: code }, details),
    })
  )

  if (isFetched && isError) {
    return <CatalogErrorPage />
  }

  return (
    <Page
      title={unit ? createTitle(unit) : 'Загрузка...'}
      breadcrumbs={createBreadcrumbs({ catalog, vehicle })}
    >
      <Container>
        {isFetched && unit ? (
          <Callout icon={faCog} title={unit.name || undefined}>
            {unit.code && <em>Узел {unit.code}:</em>}
          </Callout>
        ) : isFetching ? (
          <Callout loading={isFetching} title="Загрузка..." />
        ) : (
          <Callout variant="danger" icon={faTimesCircle}>
            Узел не найден
          </Callout>
        )}
        <Row noGutters className="mt-4">
          <Col md="7" className="align-self-center">
            {unit && (
              <Schema
                unit={unit}
                details={details || []}
                mapObjects={filterMapObjects(mapObjects || [])}
                onSelect={setCurrentCode}
              />
            )}
          </Col>
          <DetailsWrapper md="5" ref={scrollContainerRef}>
            {/* <div ref={scrollContainerRef} style={{ overflow: 'auto', height: '75vh' }}> */}
            {details && catalog?.brand && (
              <DetailsTable
                items={details}
                catalog={catalog}
                currentCode={currentCode}
                scrollContainerRef={scrollContainerRef}
              />
            )}
          </DetailsWrapper>
        </Row>
      </Container>
    </Page>
  )
}

export default UnitPage

const ImageWrapper = styled.div`
  position: relative;
`

const MapObject = styled.span<{
  $x1: number
  $y1: number
  $x2: number
  $y2: number
  $padding: number | string
  $border: number | string
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${(props) =>
    props.$y1 -
    parseInt(String(props.$border)) +
    parseInt(String(props.$padding))}px;
  left: ${(props) =>
    props.$x1 -
    parseInt(String(props.$border)) +
    parseInt(String(props.$padding))}px;
  height: ${(props) =>
    props.$y2 -
    props.$y1 +
    parseInt(String(props.$border)) +
    parseInt(String(props.$padding))}px;
  width: ${(props) =>
    props.$x2 -
    props.$x1 +
    parseInt(String(props.$border)) +
    parseInt(String(props.$padding))}px;
  padding: ${(props) => props.$padding}px;
  font-size: ${(props) => props.$y2 - props.$y1}px;
  line-height: ${(props) => props.$y2 - props.$y1}px;
  font-weight: ${(props) => props.theme.fontSemibold};
  color: ${(props) => props.theme.danger};
  border: ${(props) => parseInt(String(props.$border))}px solid
    ${(props) => props.theme.danger};
  box-shadow: ${(props) => props.theme.baseBoxShadow};
  transition: all 250ms ease-out;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.light};
    border-radius: ${(props) => props.theme.baseRadius};
    transform: scale(2.5);
    border-width: 1px;
    transition: all 250ms ease-in;
  }
`

interface SchemaProps {
  unit: ILaximoUnit
  details: ILaximoUnitDetail[]
  mapObjects: ILaximoMapObject[]
  onSelect?: (item: string | null) => void
}

const Schema: React.FC<SchemaProps> = ({
  unit,
  details,
  mapObjects,
  onSelect,
}) => {
  const imageRef = useRef<HTMLImageElement>(null)

  const [ratio, setRatio] = useState<{ x: number; y: number } | null>(null)

  const updateRatio = useCallback(() => {
    const image = imageRef.current

    if (image) {
      const { offsetHeight, offsetWidth, naturalHeight, naturalWidth } = image
      const x = offsetWidth / naturalWidth
      const y = offsetHeight / naturalHeight

      if (
        typeof x === 'number' &&
        typeof y === 'number' &&
        !isNaN(x) &&
        !isNaN(y) &&
        (!ratio || ratio.x !== x || ratio.y !== y)
      ) {
        setRatio({ x, y })
      }
    }
  }, [ratio])

  useEffect(() => {
    const resizeListener = debounce(updateRatio, 500)
    resizeListener()

    window.addEventListener('resize', resizeListener)

    return () => {
      resizeListener.cancel()
      window.removeEventListener('resize', resizeListener)
    }
  }, [updateRatio])

  return (
    <div>
      <ImageWrapper>
        {unit.largeimageurl && (
          <img
            src={unit.largeimageurl.replace('%size%', String('source'))}
            ref={imageRef}
            alt={`${unit.name}. Узел ${unit.code}`}
          />
        )}
        {ratio &&
          mapObjects.map((item, idx) => (
            <OverlayTrigger
              key={idx}
              overlay={
                <Popover id={`popover-detail-${item.code}`}>
                  <Popover.Title as="h6">
                    Артикул: {find({ codeonimage: item.code }, details)?.oem}
                  </Popover.Title>
                  <Popover.Content>
                    {find({ codeonimage: item.code }, details)?.name}
                  </Popover.Content>
                </Popover>
              }
            >
              <MapObject
                key={idx}
                $padding="1"
                $border="2"
                $x1={Math.round(item.x1 * ratio.x)}
                $y1={Math.round(item.y1 * ratio.y)}
                $x2={Math.round(item.x2 * ratio.x)}
                $y2={Math.round(item.y2 * ratio.y)}
                onMouseEnter={onSelect?.bind(null, item.code)}
                onMouseLeave={onSelect?.bind(null, null)}
              >
                {item.code}
              </MapObject>
            </OverlayTrigger>
          ))}
      </ImageWrapper>
    </div>
  )
}
