import styled from 'styled-components'
import Col from 'react-bootstrap/Col'

const BaseCell = styled(Col)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

export const ProductCell = styled(BaseCell).attrs({ md: 12 })`
  /*  */
`

export const InfoCell = styled(BaseCell).attrs({ md: 1 })`
  /*  */
`
export const ReferenceCell = styled(BaseCell).attrs({ md: 3 })`
  /*  */
`
export const DeliveryTimeCell = styled(BaseCell).attrs({ md: 2 })`
  /*  */
`
export const PriceCell = styled(BaseCell).attrs({ md: 2 })`
  /*  */
`
export const AmountCell = styled(BaseCell).attrs({ md: 2 })`
  /*  */
`
export const TotalPriceCell = styled(BaseCell).attrs({ md: 2 })`
  /*  */
`
