import {
  getCatalogPageUrl,
  getVehiclePageUrl,
  ILaximoCatalog,
  ILaximoVehicle,
} from '@monorepo/laximo_module'
import { IBreadcrumb } from 'App/Layout'

interface CatalogParams {
  catalog: ILaximoCatalog
}

interface VehicleParams extends CatalogParams {
  vehicle: ILaximoVehicle
}

export function createBreadcrumbs(params: {} | CatalogParams | VehicleParams) {
  const breadcrumbs: IBreadcrumb[] = [
    {
      title: 'Оригинальные каталоги',
      url: '/catalogs',
    },
  ]

  const catalog = (params as CatalogParams).catalog
  if (catalog) {
    breadcrumbs.push({
      title: catalog.brand,
      url: getCatalogPageUrl(catalog),
    })
  } else {
    return breadcrumbs
  }

  const vehicle = (params as VehicleParams).vehicle
  if (vehicle) {
    breadcrumbs.push({
      title: vehicle?.name ?? 'Не указано',
      url: getVehiclePageUrl(vehicle),
    })
  }

  return breadcrumbs
}
