import React from 'react'
import styled from 'styled-components'
import BaseContainer from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { AdvantageItem, IAdvantageItem } from './advantage_item'

import image1 from './images/advant-1.jpg'
import image2 from './images/advant-2.jpg'
import image3 from './images/advant-3.jpg'
import { Title } from '../components/title'
import { Separator, SubTitle } from '../components'

const items: IAdvantageItem[] = [
  {
    image: image1,
    content:
      'Наша компания функционирует на рынке с 1988г. Мы прошли путь от станции технического обслуживания дизельных автомобилей, имеющей всего два поста, до крупной авторитетной компании по ремонту автомобилей и продаже запасных частей. Нашим коллективом накоплен 30-летний опыт, ремонт автомобилей осуществляется по новейшим технологиям, обеспечивающим высокое качество и надежность выполняемых работ.',
  },
  {
    image: image2,
    content:
      'Мы всегда в курсе обновлений модельного ряда автомобилей, отслеживаем все новинки, позволяющие производить ремонт автомобилей на высоком профессиональном уровне. Диагностика и регулировка топливной аппаратуры производится на специализированных стендах фирм-производителей, таких как Bosch, Delphi.',
  },
  {
    image: image3,
    content:
      'Компания оснащена уникальным оборудованием фирмы «Bosch», «Delphi», «Denso», а также оригинальные «Mersedes», «Ford», «Hyundai», «KIA». Это позволяет осуществлять полную диагностику состояния автомобиля, всех систем автомобиля. С 2002 года СТО является гарантийной по дизельным компонентам фирмы «Robert Bosch». Также является сертифицированным центром Delphi и Denso. Успех компании обеспечивается командой высококлассных специалистов, прошедших специальное обучение.',
  },
]

type AdvantagesSectionProps = {
  title: string
  subTitle: string
}

const Container = styled(BaseContainer)`
  margin-bottom: 55px;
  margin-top: 55px;
`

const ItemsContainer = styled(Row)`
  margin-top: 25px;
`

export const AdvantagesSection: React.FC<AdvantagesSectionProps> = ({
  title,
  subTitle,
}) => (
  <Container>
    <Row>
      <Col xs="12" className="d-flex flex-column">
        <SubTitle>{subTitle}</SubTitle>
        <Title className="align-self-end">{title}</Title>
      </Col>
    </Row>
    <ItemsContainer>
      {items.map((item, idx) => (
        <AdvantageItem key={idx} item={item} />
      ))}
    </ItemsContainer>
    <Separator />
  </Container>
)
