import styled from 'styled-components'

import Base from './top_section'

export * from './top_section'

export const TopSection = styled(Base)`
  && {
    background-color: ${(props) => props.theme.colorLightGrey};
    border-bottom: 1px solid ${(props) => props.theme.colorGrey};
    height: 48px;
    display: flex;
    align-items: center;

    display: flex;
    justify-content: space-between;
    align-items: center;

    a,
    button {
      color: ${(props) => props.theme.colorOrange};

      &:hover {
        color: ${(props) => props.theme.colorDarkGrey};
      }
    }

    & > div {
      & > section:nth-child(1) {
        /* font-weight: ${(props) => props.theme.fontSemibold}; */
        & > *:not(:last-child) {
          margin-right: 1.25rem;
        }
      }
      /* & > section:nth-child(2) { */
      & > section {
        display: flex;
        align-items: center;
      }
    }
  }
`
