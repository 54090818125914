import React from 'react'

import { ForgotPasswordSection } from 'App/PageComponents/Auth'
import { Page } from 'App/Layout'
import { Card, Container } from 'App/PageComponents/Auth/window'

const ForgotPassword: React.FC = () => (
  <Page authenticated={false}>
    <Container>
      <Card>
        <Card.Body>
          <Card.Title className="lead mb-5">Восстановление пароля</Card.Title>
          <ForgotPasswordSection />
        </Card.Body>
      </Card>
    </Container>
  </Page>
)

export default ForgotPassword
