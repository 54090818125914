import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { ICar } from '../interfaces'

export async function fetchCarsList() {
  try {
    const { data } = await apiClient.get<ICar[]>('/cars')
    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при получении автомобилей')
  }
}
