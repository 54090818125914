import React, { useMemo } from 'react'
import { Form, FormGroupProps } from 'react-bootstrap'
import cn from 'classnames'
import { useField } from 'formik'
import { isPlainObject } from 'lodash/fp'

export type BaseFormGroupProps = FormGroupProps & {
  name?: string
  label?: string
  inner?: React.ReactNode
}

function extractErrors(errors: any): React.ReactNodeArray | undefined {
  if (!errors) {
    return
  }
  if (React.isValidElement(errors) || typeof (errors as any) === 'string') {
    return [errors]
  }
  if (Array.isArray(errors)) {
    return errors?.filter(
      (error) => React.isValidElement(error) || typeof error === 'string'
    )
  }
  if (typeof errors === 'object' && isPlainObject(errors)) {
    return Object.values(errors as Record<string, any>).filter(
      (error) => React.isValidElement(error) || typeof error === 'string'
    )
  }
}

export const BaseFormGroup: React.FC<BaseFormGroupProps> = ({
  name,
  label,
  children,
  inner,
  className,
  ...props
}) => {
  const [, meta] = useField(name ?? '')

  const errors: React.ReactNodeArray | undefined = useMemo(
    () => extractErrors(meta.error),
    [meta.error]
  )

  return (
    <Form.Group
      {...props}
      controlId={name}
      className={cn('d-flex flex-column', className)}
      // isValid={
      //   typeof name !== 'undefined' && meta.error && meta.touched
      //     ? false
      //     : true
      // }
    >
      {label && <Form.Label>{label}</Form.Label>}
      {children}
      {typeof name !== 'undefined' && meta.touched && meta.error && (
        <ul className="list-unstyled text-danger">
          {errors?.map((error, idx) => (
            <Form.Text as="li" key={idx}>
              {error}
            </Form.Text>
          ))}
        </ul>
      )}
      <Form.Control.Feedback />
      {inner}
    </Form.Group>
  )
}
