import React from 'react'
import cn from 'classnames'

import { ICartItem } from '@monorepo/cart_module'
import { useOrderItemValidator } from '@monorepo/order_module'
import errorComponents, { BaseError } from './errors'

type ErrorsListProps = React.ComponentPropsWithoutRef<'ul'> & {
  item: ICartItem
}

export const ErrorsList: React.FC<ErrorsListProps> = ({ item, ...props }) => {
  const { isValid, errors } = useOrderItemValidator()

  return isValid ? null : (
    <ul {...props} className={cn('fa-ul m-0', props.className)}>
      {Object.entries(errors).map(([fieldName, text]) => {
        const Component = errorComponents[fieldName] ?? BaseError

        return (
          <Component
            key={fieldName}
            fieldName={fieldName}
            message={text}
            item={item}
          />
        )
      })}
    </ul>
  )
}
