import React, { useRef } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { useCompany } from '@monorepo/company_module'
import { CompositButton } from 'App/Components'
import image1 from 'App/Resources/images/View/Home/Section10/mail.png'
import image2 from 'App/Resources/images/View/Home/Section10/banner_img_phone.png'
import { Banner } from './banner'

const EmailInput = styled.input`
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px inset;
`

export const Section = () => {
  const emailInputRef = useRef<HTMLInputElement>(null)

  const history = useHistory()
  const registrationHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    history.push(
      emailInputRef.current?.value
        ? `/registration?email=${emailInputRef.current?.value}`
        : '/registration'
    )
  }

  const { data: company } = useCompany()

  return (
    <div className="vc_row wpb_row vc_row-fluid vc_custom_1558448273049 vc_column-gap-24 vc-row-168443 mb-3">
      <div className="container et-clearfix">
        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12 text-align-none vc-column-74913">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <Banner
                title="Зарегистрируйся сейчас"
                text="И получи гарантированную скидку на первый заказ"
                image={image1}
                dark
              >
                <div className="mailchimp-form">
                  <form
                    className="et-mailchimp-form"
                    name="et-mailchimp-form"
                    onSubmit={registrationHandler}
                  >
                    <div>
                      <EmailInput
                        type="text"
                        className="field"
                        ref={emailInputRef}
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="send-div">
                      <CompositButton type="submit" name="subscribe">
                        Регистрация
                      </CompositButton>
                      <div className="sending" />
                    </div>
                  </form>
                </div>
              </Banner>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12 text-align-none vc-column-827571">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <span className="et-gap et-clearfix et-gap-263945 hide1024" />

              <Banner
                title={company?.name ?? ''}
                text="Авторизованный сервисный центр"
                image={image2}
              >
                <CompositButton to="/">Перейти</CompositButton>
              </Banner>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
