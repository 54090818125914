import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { ICar } from '../interfaces'

import { useAuthenticatation } from '@monorepo/auth_module'
import { fetchCarsList } from '../api'
import { QueryKey } from './constants'

export function useCarsList<TData = ICar[]>(
  options?: UseQueryOptions<ICar[], AxiosError, TData>
) {
  const { isAuthenticated } = useAuthenticatation()

  return useQuery(QueryKey.carsList as any, () => fetchCarsList(), {
    refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000,
    ...options,
    enabled: isAuthenticated && (options?.enabled ?? true),
  })
}
