import React from 'react'

import { useBlogPostsList } from '@monorepo/blog_post_module'
import { BlogPostsSlider } from 'App/Components/blog_posts_slider'

type RelatedPostsCarouselProps = {
  category: string
}

export const RelatedPostsCarousel: React.FC<RelatedPostsCarouselProps> = ({
  category,
}) => {
  const { data: relatedPosts } = useBlogPostsList({ category })

  return (
    <div className="related-posts-wrapper et-clearfix">
      <h4 className="related-posts-title">Похожие посты</h4>
      {relatedPosts?.items && (
        <BlogPostsSlider
          items={relatedPosts?.items ?? []}
          height="350px"
          imageHeight="60%"
        />
      )}
    </div>
  )
}
