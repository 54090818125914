import { apiClient } from '@monorepo/core'
import {
  CatalogCode,
  ILaximoCatalog,
  ILaximoCategory,
  ILaximoQuickGroup,
  ILaximoVehicle,
  Nullable,
} from '../interfaces'

export interface IQuickGroupsResponse {
  catalog: Nullable<ILaximoCatalog>
  vehicle: Nullable<ILaximoVehicle>
  quickGroup: Nullable<ILaximoQuickGroup>
  quickDetails: Nullable<ILaximoCategory[]>
}

interface Options {
  ssd: string
  vehicleId: string
  catalogCode: CatalogCode
}

export async function fetchQuickGroups(options: Options) {
  try {
    const { data } = await apiClient.get<IQuickGroupsResponse>(
      '/laximo2/quick_groups',
      { params: options }
    )

    return data
  } catch (error) {
    throw error
  }
}
