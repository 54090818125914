import { apiClient } from '@monorepo/core'

import { IDeliveryMethod } from '../interfaces'

export async function fetchDeliveryMethods() {
  try {
    const { data } = await apiClient.get<IDeliveryMethod[]>('/delivery/types')

    return data
  } catch (error) {
    return []
  }
}
