import React from 'react'
import styled from 'styled-components'

import { ICar, useCarsList } from '@monorepo/garage_module'
import { CarItem } from './car_item'

type CarsListProps = {
  items: ICar[]
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const CarsList: React.FC<CarsListProps> = () => {
  const { data: items = [], isFetched } = useCarsList()

  if (isFetched && items.length === 0) {
    return null
  }

  return (
    <Wrapper>
      {items.map((item, idx) => (
        <CarItem key={item.id || idx} item={item} />
      ))}
    </Wrapper>
  )
}
