import React from 'react'
import { FormattedNumber } from 'react-intl'
import Table from 'react-bootstrap/Table'

import { IOperationTransaction } from '@monorepo/contract_module'

type TransactionsTableProps = {
  items: IOperationTransaction['items']
}

export const TransactionsTable: React.FC<TransactionsTableProps> = ({
  items,
}) => (
  <Table striped responsive>
    <thead className="thead-dark">
      <tr>
        <th>№</th>
        <th>Товар</th>
        <th>Цена</th>
        <th>Кол-во</th>
        <th>Сумма</th>
        <th>Качество</th>
        <th>Референс</th>
      </tr>
    </thead>
    <tbody>
      {items?.map((item, idx) => (
        <tr key={idx}>
          <td>{idx + 1}</td>
          <td>
            {item.brand} {item.number.toUpperCase()}
          </td>
          <td>{item.amount}</td>
          <td>
            <FormattedNumber value={item.price} format="RUB" />
          </td>
          <td>
            <FormattedNumber value={item.price * item.amount} format="RUB" />
          </td>
          <td>{item.quality}</td>
          <td>{item.reference}</td>
        </tr>
      ))}
    </tbody>
  </Table>
)
