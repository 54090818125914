import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BaseTable from 'react-bootstrap/Table'
import { FormattedDate, FormattedNumber } from 'react-intl'

import { IOrder } from '@monorepo/order_module'
import { getSearchUrl } from '@monorepo/application_module/helpers'
import { StatusBadge } from 'App/Components'

type MainInfoProps = {
  item: IOrder
}

const Table = styled(BaseTable)`
  border: 1px solid ${(props) => props.theme.primary};
  box-shadow: ${(props) => props.theme.baseBoxShadow};
  line-height: 1.25;

  thead th {
    color: ${(props) => props.theme.light};
    background: ${(props) => props.theme.primary};
  }
`

export const MainInfo: React.FC<MainInfoProps> = ({ item }) => {
  const totalPrice =
    item?.items.reduce((acc, item) => acc + item.price * item.amount, 0) ?? 0

  return (
    <>
      <Row>
        <Col md="6" sm="6">
          {/* <address>
                      <strong>ThemeMakker Inc.</strong>
                      <br /> 795 Folsom Ave, Suite 546
                      <br /> San Francisco, CA 54656
                      <br />
                      <abbr title="Phone">P:</abbr> (123) 456-34636{' '}
                    </address> */}
        </Col>
        <Col md="6" sm="6" className="text-right mb-4">
          {item?.createdAt && (
            <p className="mb-0">
              <strong>Дата заказа: </strong>{' '}
              <FormattedDate value={item?.createdAt} />
            </p>
          )}
          {/* <p className="mb-0">
                      <strong>Order Status: </strong>
                      <span className="badge badge-warning mb-0">Pending</span>
                    </p> */}
          {item?.id && (
            <p className="mb-0">
              <strong>ID заказа: </strong> #{item?.id}
            </p>
          )}
          {item?.paymentMethod && (
            <p className="mb-0">
              <strong>Способ оплаты: </strong> {item?.paymentMethod.name}
            </p>
          )}
          {item?.deliveryMethod && (
            <p className="mb-0">
              <strong>Способ доставки: </strong> {item?.deliveryMethod.name}
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Table hover responsive>
            <thead className="thead-dark--">
              <tr>
                <th>#</th>
                {/* <th>Артикул</th> */}
                <th>Фото</th>
                <th className="hidden-sm-down">Наименование</th>
                <th className="text-center">Кол-во</th>
                <th className="hidden-sm-down text-right">Цена</th>
                <th className="text-right">Сумма</th>
                <th className="text-center">Статус</th>
              </tr>
            </thead>
            <tbody>
              {item?.items.map((item, idx) => (
                <tr key={item.id}>
                  <td>{idx + 1}</td>
                  {/* <td>
                              <Link to={getSearchUrl(item.good)}>
                                <strong>{item.brandName}</strong> «
                                {item.number.toUpperCase()}»
                              </Link>
                            </td> */}
                  {/* <td className="hidden-sm-down">{item.name}</td> */}
                  <th>
                    {item.good.thumbs?.[0] && (
                      <img
                        src={item.good.thumbs?.[0]}
                        width="48"
                        className="img-thumbnail"
                        alt=""
                      />
                    )}
                  </th>
                  <td className="project-title">
                    <h6 className="mb-0">
                      <Link to={getSearchUrl(item.good)}>
                        <strong>{item.brandName}</strong> «
                        {item.number.toUpperCase()}»
                      </Link>
                    </h6>
                    <small className="text-wrap">{item.name}</small>
                  </td>
                  <td className="text-center">{item.amount}</td>
                  <td className="hidden-sm-down text-right">
                    <FormattedNumber value={item.price} format="RUB" />
                  </td>
                  <td className="text-right">
                    <FormattedNumber
                      value={item.price * item.amount}
                      format="RUB"
                    />
                  </td>
                  <td className="text-center">
                    {item.status && (
                      <StatusBadge
                        className="text-wrap"
                        color={item.status.color}
                        tooltip={item.status.description}
                      >
                        {item.status.name}
                      </StatusBadge>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md="6">
          {item?.customerComment && (
            <>
              <h5>Примечания к заказу</h5>
              <p>{item?.customerComment}</p>
            </>
          )}
        </Col>
        <Col md="6" className="text-right">
          <p className="mb-0">
            <b>Итого:</b> <FormattedNumber value={totalPrice} format="RUB" />
          </p>
          {/* <p className="mb-0">Discout: 12.9%</p> */}
          {/* <p className="mb-0">VAT: 12.9%</p> */}
          <h3 className="mb-0 m-t-10">
            К оплате <FormattedNumber value={totalPrice} format="RUB" />
          </h3>
        </Col>
        {/* <div className="hidden-print col-md-12 text-right">
                      <hr />
                      <button className="btn btn-outline-secondary mr-1">
                        <i className="icon-printer"></i>
                      </button>
                      <button className="btn btn-primary">Submit</button>
                    </div> */}
      </Row>
    </>
  )
}
