import React from 'react'
import { Col, Form, FormProps, Row } from 'react-bootstrap'
import { Field, FieldProps, Form as FormikForm } from 'formik'

import { FieldErrors } from 'App/Components/FormFields'

type CarFormProps = FormProps & {
  //
}

export const CarForm: React.FC<CarFormProps> = ({ ...props }) => (
  <Form {...props} as={FormikForm}>
    <Form.Row>
      <Col md="6">
        <Form.Group as={Row}>
          <Form.Label column sm="5">
            Марка
          </Form.Label>
          <Col sm="7">
            <Field as={Form.Control} name="mark" placeholder="Марка" />
          </Col>
          <FieldErrors name="mark" className="col-12" />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="5">
            Модель
          </Form.Label>
          <Col sm="7">
            <Field as={Form.Control} name="model" placeholder="Модель" />
          </Col>
          <FieldErrors name="model" className="col-12" />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="5">
            Модификация
          </Form.Label>
          <Col sm="7">
            <Field
              as={Form.Control}
              name="modification"
              placeholder="Модификация"
            />
          </Col>
          <FieldErrors name="modification" className="col-12" />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="5">
            Год выпуска
          </Form.Label>
          <Col sm="7">
            <Field
              as={Form.Control}
              name="productionYear"
              type="number"
              placeholder="Год выпуска"
            />
          </Col>
          <FieldErrors name="productionYear" className="col-12" />
        </Form.Group>
      </Col>

      <Col md="6">
        <Form.Group as={Row}>
          <Form.Label column sm="5">
            VIN-код (Frame)
          </Form.Label>
          <Col sm="7">
            <Field as={Form.Control} name="vin" placeholder="VIN-код (Frame)" />
          </Col>
          <FieldErrors name="vin" className="col-12" />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="5">
            Модель дв.
          </Form.Label>
          <Col sm="7">
            <Field
              as={Form.Control}
              name="engineModel"
              placeholder="Модель двигателя"
            />
          </Col>
          <FieldErrors name="engineModel" className="col-12" />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="5">
            Обьем дв., см³
          </Form.Label>
          <Col sm="7">
            <Field
              as={Form.Control}
              name="engineSize"
              placeholder="Обьем двигателя, см³"
            />
          </Col>
          <FieldErrors name="engineSize" className="col-12" />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="5">
            Мощность дв., л.с.
          </Form.Label>
          <Col sm="7">
            <Field
              as={Form.Control}
              name="enginePower"
              placeholder="Мощность двигателя, л.с."
            />
          </Col>
          <FieldErrors name="enginePower" className="col-12" />
        </Form.Group>
      </Col>

      <Col md="12">
        <Form.Group>
          <Form.Label>
            Дополнительная информация об автомобиле (тюнинг, изменения,
            конструкции)
          </Form.Label>
          <Field name="description" placeholder="">
            {({ field }: FieldProps) => (
              <Form.Control as="textarea" rows={4} {...field} />
            )}
          </Field>
          <FieldErrors name="description" className="col-12" />
        </Form.Group>
      </Col>
    </Form.Row>
  </Form>
)
