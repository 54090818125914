import { apiClient } from '@monorepo/core'

import type { LoginDTO } from '../dto'

export async function authenticate(params: LoginDTO) {
  try {
    const { data } = await apiClient.post<{
      token: string
      refresh_token: string
    }>('/auth/login', params)

    return data
  } catch (error) {
    throw error
  }
}
