import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import cn from 'classnames'
import { useField } from 'formik'

import { extractErrors } from './utils'

type FieldErrorsProps = React.ComponentPropsWithoutRef<'ul'> & {
  name: string
}

export const FieldErrors: React.FC<FieldErrorsProps> = ({
  name,
  className,
  ...props
}) => {
  const [, meta] = useField(name ?? '')

  const errors: React.ReactNodeArray | undefined = useMemo(
    () => extractErrors(meta.error),
    [meta.error]
  )

  return typeof name !== 'undefined' && meta.touched && meta.error ? (
    <ul {...props} className={cn(className, 'list-unstyled text-danger')}>
      {errors?.map((error, idx) => (
        <Form.Text as="li" key={idx}>
          {error}
        </Form.Text>
      ))}
    </ul>
  ) : null
}
