import { BaseError } from './base_error'
import { OfferError } from './offer_error'
import { AskError } from './ask_error'

export * from './base_error'

const errors: Record<string, typeof BaseError> = {
  deliveryTime: AskError,
  offer: OfferError,
  price: AskError,
}

export default errors
