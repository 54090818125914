import React from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import BaseImage from 'react-bootstrap/Image'

// import { BlogPostType } from '@monorepo/interfaces'
// import { Image } from '@monorepo/components/common'
import { Link, ImagePlaceholder } from 'App/Components'
import { getPostUrl, IBlogPost } from '@monorepo/blog_post_module'
// import { getImagineUrl } from '@monorepo/core/helpers'

type PostItemProps = {
  item: IBlogPost
}

const ImageContainer = styled.div<{ height: number }>`
  /* object-fit: cover; */
  height: ${(props) => props.height}px;
  width: ${(props) => props.height}px;
`
const Image = styled(BaseImage)`
  height: 100% !important;
  object-fit: cover;
`
export const PostItem: React.FC<PostItemProps> = ({ item }) => {
  const url = getPostUrl(item)

  return (
    <li className="post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-black tag-enova tag-green">
      <div className="post-thumbnail">
        <Link to={url}>
          <ImageContainer height={75} className="image-container~">
            {item.postThumb ? (
              <Image
                src={item.postThumb}
                // width={150}
                // height={150}
                // className="attachment-thumbnail size-thumbnail wp-post-image"
                alt={item.title}
                // fluid
                // rounded
                // roundedCircle
                // thumbnail
              />
            ) : (
              <ImagePlaceholder
                width={150}
                height={150}
                className="attachment-thumbnail size-thumbnail wp-post-image"
              />
            )}
          </ImageContainer>
        </Link>
      </div>
      <div className="post-body">
        <h6 className="post-title">
          <Link to={url}>{item.title}</Link>
        </h6>
        <div className="post-date">
          {<FormattedDate value={item.createdAt} />}
        </div>
      </div>
    </li>
  )
}
