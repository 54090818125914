import { useQuery } from 'react-query'

import { IOrderStatusGroup } from '../interfaces'

const statusGroups: IOrderStatusGroup[] = [
  {
    id: 'isActive',
    name: 'Активные',
  },
  {
    id: 'isNotAvailable',
    name: 'Нет в наличии',
  },
  {
    id: 'isIssued',
    name: 'Выдано',
  },
  {
    id: 'isArchive',
    name: 'Архивные',
  },
]

const QUERY_KEY = 'order-status-groups'

export function useOrdersStatusGroupsList() {
  return useQuery(QUERY_KEY, () => Promise.resolve(statusGroups))
}
