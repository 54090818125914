import React from 'react'
import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import Row, { RowProps } from 'react-bootstrap/Row'
import invariant from 'invariant'

import { TR } from 'App/Components/table'

type TableRowProps = Omit<RowProps, 'children'> & {
  children?: React.ReactNodeArray
}

export const TableRowNew: React.FC<TableRowProps> = ({
  children,
  ...props
}) => {
  const requireColomns = [2, 7]
  const givenColomns = React.Children.count(children)
  invariant(
    requireColomns.includes(givenColomns),
    `Table require ${requireColomns.join(
      ', '
    )} columns, ${givenColomns} passed.`
  )

  return (
    <TR {...props} noGutters>
      <Col md={3} xs={12}>
        {children?.[0]}
      </Col>
      <Col md={9} xs={12}>
        {givenColomns === 2 ? (
          children?.[1]
        ) : (
          <TableSubRow>{children?.slice(1)}</TableSubRow>
        )}
      </Col>
    </TR>
  )
}

const Wrapper = styled(Row).attrs((props) => ({ noGutters: true }))`
  border-bottom: 1px dotted ${(props) => props.theme.secondary};

  &:last-child {
    border-bottom: none;
  }
`

export const TableSubRow: React.FC<TableRowProps> = ({
  children,
  ...props
}) => {
  const requireColomns = [6, 7]
  const givenColomns = React.Children.count(children)
  invariant(
    requireColomns.includes(givenColomns),
    `Table require ${requireColomns.join(
      ', '
    )} columns, ${givenColomns} passed.`
  )

  return (
    <Wrapper>
      {children?.[0]}
      {children?.[1]}
      {children?.[2]}
      {children?.[3]}
      {children?.[4]}
      {children?.[5]}
      {children?.[6]}
    </Wrapper>
  )
}
