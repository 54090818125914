import React from 'react'
import BaseLightbox, { ViewType, Modal, ModalGateway } from 'react-images'

import style from './style.module.scss'
import { useLightbox } from './hooks'

type LightBoxPropsType = {
  images: string | Array<string> | ViewType | Array<ViewType>
  currentIndex?: number
}

export const LightBox: React.FC<LightBoxPropsType> = ({
  images,
  children,
  currentIndex: initialCurrentIndex = 0,
}) => {
  const { show, currentIndex, views, showHandler, hideHandler } = useLightbox(
    images,
    initialCurrentIndex
  )

  return (
    <>
      <ModalGateway>
        {show && (
          <Modal onClose={hideHandler} closeOnBackdropClick>
            <BaseLightbox
              views={views}
              currentIndex={currentIndex}
              frameProps={{ autoSize: 'height' }}
              styles={{
                view: (base) => ({
                  ...base,
                  '& img': {
                    maxHeight: '95vh !important',
                  },
                }),
              }}
            />
          </Modal>
        )}
      </ModalGateway>
      <>
        {typeof children === 'function' ? (
          views.map((view, idx) => (
            <div key={idx} className={style.container}>
              {children({
                image:
                  typeof view.source === 'object'
                    ? view.source.regular
                    : view.source,
                show: showHandler.bind(null, idx),
              })}
            </div>
          ))
        ) : (
          <div
            className={style.container}
            onClick={showHandler.bind(null, initialCurrentIndex)}
          >
            {children}
          </div>
        )}
      </>
    </>
  )
}
