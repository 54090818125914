import styled, { css } from 'styled-components'

import type { ButtonOptions } from '../types'
import { TextBlock } from './text'
import { HoverBlock } from './hover'

// border: ${(props) =>
//   props.$inverted ? `1px solid ${props.theme[props.$variant]}` : 'none'};
export const Button = styled.button<ButtonOptions>`
  background-color: transparent;
  border: ${(props) => (props.$outline ? 2 : 1)}px solid ${(props) =>
  props.theme[props.$variant]};
  /* border: ${(props) =>
    props.$inverted ? `1px solid ${props.theme[props.$variant]}` : 'none'}; */
  border-radius: ${(props) => props.theme.baseRadius};
  /* box-shadow: 0px 0px 1px 1px ${(props) => props.theme.light},
    inset ${(props) => props.theme.baseBoxShadow}; */
  cursor: ${(props) =>
    props.$disabled ? 'not-allowed' : 'pointer'} !important;
  display: inline-block;
  ${(props) =>
    props.$block &&
    css`
      width: 100%;
    `}
  font-size: ${(props) => {
    const { baseFontSize } = props.theme
    const baseFontSizeParsed = parseInt(baseFontSize, 10)
    return (
      (props.$size === 'small' && `${baseFontSizeParsed * 0.875}px`) ||
      (props.$size === 'large' && `${baseFontSizeParsed * 1.375}px`) ||
      baseFontSize
    )
  }};
  font-weight: ${(props) => props.theme.fontBold};
  line-height: ${(props) =>
    (props.$size === 'small' && '1.25') ||
    (props.$size === 'large' && '1.25') ||
    '1.75'};
  padding: ${(props) => {
    const spaceHorizontal =
      props.$wide === true
        ? 25
        : typeof props.$wide === 'number'
        ? props.$wide
        : 0

    return props.$size === 'large'
      ? `16px ${25 + spaceHorizontal}px 17px`
      : `0 ${12 + spaceHorizontal}px`
  }};
  position: relative;
  text-align: center;
  color: ${(props) =>
    (props.$inverted && props.theme[props.$variant]) ||
    (props.$outline && props.theme[props.$variant]) ||
    (props.$variant === 'link' && props.theme.baseFontColor) ||
    props.theme.colorWhite};

  text-transform: uppercase;
  transition: color 50ms, background-color 300ms ease-out,
    /* box-shadow 300ms ease-out;
  outline: 0; */

  &:hover ${TextBlock as any} {
    // ${(props) => props.$variant === 'link' && 'text-decoration: underline;'}
  }

  &:hover {
    color: ${(props) =>
      (props.$inverted && props.theme[props.$variant]) ||
      (props.$variant === 'link' && props.theme.baseFontColor) ||
      props.theme.colorWhite};

    ${(props) =>
      !props.$outline &&
      css`
        border-color: ${props.theme[props.$variant + 'Hover']};
      `}
    ${(props) =>
      props.$variant !== 'link' &&
      css`
        ${HoverBlock as any} {
          opacity: 1;
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      `}

    ${(props) =>
      props.$variant === 'link' &&
      css`
        ${TextBlock as any}:after {
          opacity: 1;
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      `}
  }
`
