import React from 'react'

import { ICompany } from '@monorepo/company_module'
import { Card } from './card'
import { ScheduleTable } from 'App/Components/schedule_table'

type ScheduleBlockProps = {
  office: ICompany
}

export const ScheduleBlock: React.FC<ScheduleBlockProps> = ({ office }) => (
  <Card title=" График работы">
    <ScheduleTable size="sm" schedule={office.workSchedule} />
  </Card>
)
