import { useCallback } from 'react'

import { useQueryClient } from 'react-query'
import { matches, some } from 'lodash/fp'

import { ICar } from '../interfaces'
import { QueryKey } from './constants'
import { updateCarSchema } from '../schema'
import { useCarsList } from './cars_list.hook'

const hasNewCar = some<ICar>(matches({ id: null }))

export function useAddCar() {
  const queryClient = useQueryClient()

  const createCar = useCallback(() => {
    const newCar = updateCarSchema.cast({}) as ICar
    queryClient.setQueryData<ICar[]>(QueryKey.carsList, (prev) => [
      { ...newCar, id: null },
      ...(prev ?? []),
    ])
  }, [queryClient])

  const { data: isNewCarExists } = useCarsList({
    select: hasNewCar,
  })

  return { createCar, isNewCarExists }
}
