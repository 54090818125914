import { useMutation, useQueryClient } from 'react-query'

import { ICar } from '../interfaces'
import { updateCar } from '../api'
import { QueryKey } from './constants'

export function useUpdateCar() {
  const queryClient = useQueryClient()

  return useMutation(updateCar, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData<ICar[]>(
        QueryKey.carsList,
        (prev) =>
          prev?.map((item) =>
            item.id === variables?.id ? data || item : item
          ) ?? []
      )
    },
  })
}
