import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { FormattedNumber, useIntl } from 'react-intl'
import Badge from 'react-bootstrap/Badge'
import Image from 'react-bootstrap/Image'

// import { useComponentVisible } from '@monorepo/application_module/hooks'
import {
  useCartItems,
  useCartTotalAmount,
  useCartTotalPrice,
  useRemoveCartItem,
} from '@monorepo/cart_module'

import { Dropdown } from 'App/Components'

type CartMenuPropsType = React.HTMLAttributes<HTMLElement> & {
  //
}

const TotalPrice = styled.div`
  color: ${(props) => props.theme.colorOrange};
  font-weight: ${(props) => props.theme.fontSemibold};
  font-size: 1.5em;
  font-style: oblique;
  text-align: right;
`
const TotalAmount = styled(Badge)`
  && {
    background-color: ${(props) => props.theme.colorDarkGrey};
    color: ${(props) => props.theme.colorWhite};
    font-size: 10px;
    position: absolute;
    top: -5px;
    right: 100%;
    margin-right: 3px;
  }
`

const OpenCartButton = styled(Dropdown.Item)`
  &&& {
    color: ${(props) => props.theme.colorBlack};

    &:hover {
      color: ${(props) => props.theme.colorWhite};
      background-color: ${(props) => props.theme.colorOrange};
    }
  }
`

const CartMenu: React.FC<CartMenuPropsType> = (props) => {
  const { formatMessage } = useIntl()
  // const componentRef = useRef(null)
  // const { isVisible, toggle } = useComponentVisible({ ref: componentRef })

  const { data: cartItems = [] } = useCartItems()
  const { data: totalAmount = 0 } = useCartTotalAmount()
  const { data: totalPrice = 0 } = useCartTotalPrice()
  const { remove: handleRemove } = useRemoveCartItem()

  return (
    <Dropdown.Button
      {...props}
      id="cart-menu"
      rootCloseEvent="click"
      variant="link"
      title={
        <div className="header-cart">
          <div
            className={cn(
              'cart-toggle',
              'hbe-toggle',
              totalAmount > 0 ? 'ien-ecart-9' : 'ien-ecart-12'
            )}
            data-close-icon="ien-eclose-3"
          >
            <div className="cart-contents" title="Просмотр корзины">
              <span className="cart-title text-uppercase">Корзина</span>
              <TotalPrice>
                {totalPrice > 0 ? (
                  <FormattedNumber value={totalPrice} format="RUB" />
                ) : (
                  <small>Пусто</small>
                )}
              </TotalPrice>

              {totalAmount > 0 && <TotalAmount>{totalAmount}</TotalAmount>}
            </div>
          </div>
        </div>
      }
      menuAlign="right"
      // disabled={!isFetched}
    >
      <Dropdown.Header>
        {formatMessage(
          {
            id: 'component.cart.total_items',
            defaultMessage:
              '{amount, plural, =0 {Корзина пуста} one {В корзине # товар} few {В корзине # товара} many {В корзине # товаров} other {В корзине # товаров} }',
          },
          { amount: totalAmount }
        )}
      </Dropdown.Header>
      {totalAmount > 0 && (
        <>
          <ul>
            {cartItems.map((item, idx) => (
              <li key={idx}>
                <div>
                  {item.good.thumbs?.[0] && (
                    <Image src={item.good.thumbs?.[0]} thumbnail alt="" />
                  )}
                </div>
                <article>
                  <strong>
                    {item.good.brand.name} {item.good.number.toUpperCase()}
                  </strong>
                  <em>{item.good.name}</em>
                  <p>
                    {item.amount}&nbsp;&times;&nbsp;
                    <FormattedNumber value={item.price} format="RUB" />
                  </p>
                </article>
                <button onClick={() => handleRemove(item)}>&times;</button>
              </li>
            ))}
          </ul>
          <Dropdown.Divider />
          <OpenCartButton href="/cart" className="text-center">
            Перейти в корзину
          </OpenCartButton>
        </>
      )}
    </Dropdown.Button>
  )
}

export default CartMenu
