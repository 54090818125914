import React from 'react'
import styled from 'styled-components'

type BannerProps = {
  title: string
  text: string
  image: string
  dark?: boolean
}

const Wrapper = styled.div<{ dark?: boolean }>`
  border-radius: ${(props) => props.theme.baseRadius};
  border: ${(props) =>
    props.dark ? 'none' : `1px solid ${props.theme.primary}`};
  padding: 48px 40px 40px;
  margin: 0;
  box-shadow: ${(props) => props.theme.baseBoxShadow};
`

const Text = styled.span<{ dark?: boolean }>`
  color: ${(props) => (props.dark ? props.theme.light : props.theme.primary)};
`

const Header = styled(Text)`
  font-size: ${(props) => parseInt(props.theme.baseFontSize) * 2}px;
  font-weight: ${(props) => props.theme.fontSemibold};
  line-height: ${(props) => parseInt(props.theme.baseFontSize) * 2}px;
  text-transform: uppercase;
`

const Content = styled.div`
  height: 64px;
`

const Background = styled.div<{ dark?: boolean }>`
  background-color: ${(props) =>
    props.dark ? props.theme.primary : props.theme.light};
  background-position: 100% 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  transform: scale(1);
  backface-visibility: hidden;
`

const BackgroundImage = styled.img`
  mix-blend-mode: multiply;
  position: absolute;
  z-index: 4;
  display: block;
  width: auto;
  max-width: none;
  transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  left: 300px;
  top: 50px;
`

export const Banner: React.FC<BannerProps> = ({
  title,
  text,
  image,
  dark,
  children,
}) => (
  <Wrapper
    dark={dark}
    className="et-ad back-repeat-no-repeat back-size-auto img-transform-true top"
  >
    <div className="et-ad-inner et-clearfix">
      <p className="et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type">
        <span className="text-wrapper">
          <Header dark={dark}>{title}</Header>
        </span>
      </p>
      <p className="et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type">
        <span className="text-wrapper mb-4">
          <Text dark={dark}>{text}</Text>
        </span>
      </p>
      <Content className="et-mailchimp widget_mailchimp">{children}</Content>
    </div>
    <BackgroundImage alt="" className="et-ad-img" src={image} />
    <Background dark={dark} />
  </Wrapper>
)
