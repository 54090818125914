import React from 'react'
import BaseTable, { TableProps } from 'react-bootstrap/Table'
import { flow, groupBy, values } from 'lodash/fp'
import styled from 'styled-components'

import { ILaximoCatalog, ILaximoUnitDetail } from '@monorepo/laximo_module'
import { DetailRow } from './components/detail_row'

interface DetailsTableProps extends TableProps {
  items: ILaximoUnitDetail[]
  catalog: ILaximoCatalog
  currentCode?: string | null
  scrollContainerRef?: React.RefObject<HTMLDivElement>
}

const key: keyof ILaximoUnitDetail = 'codeonimage'
const groupDetails: (items: ILaximoUnitDetail[]) => ILaximoUnitDetail[][] =
  flow(groupBy<ILaximoUnitDetail>(key), values)

const Table = styled(BaseTable)`
  font-size: ${(props) => parseInt(props.theme.baseFontSize) * 0.875}px;
  margin: 0;
`

export const DetailsTable: React.FC<DetailsTableProps> = ({
  items,
  catalog,
  currentCode,
  scrollContainerRef,
  ...props
}) => {
  const groups = groupDetails(items)

  return (
    <Table striped bordered={false} borderless hover size="sm" {...props}>
      <thead>
        <tr>
          <th />
          <th>Артикул</th>
          <th>Наименование</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {groups.map((items) => [
          items.map((item, idx) => (
            <DetailRow
              key={idx}
              scrollContainerRef={scrollContainerRef}
              item={item}
              catalog={catalog}
              variant={items.length > 1 ? idx + 1 : undefined}
              selected={item.codeonimage === currentCode}
            />
          )),
        ])}
      </tbody>
    </Table>
  )
}
