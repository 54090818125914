import { AxiosError } from 'axios'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import { fetchUnit, IUnitResponse } from '../api/fetch_unit.api'
import { CatalogCode } from '../interfaces'
import { defaultOptions } from './_default_query_options'

const QUERY_KEY = 'laximo_unit'

interface Options<TData>
  extends UseQueryOptions<IUnitResponse, AxiosError, TData> {
  catalogCode: CatalogCode
  ssd: string
  unitId: string
}

export function useUnit<TData = IUnitResponse>({
  catalogCode,
  ssd,
  unitId,
  ...options
}: Options<TData>) {
  const query = { catalogCode, ssd, unitId }

  return useQuery([QUERY_KEY, query] as QueryKey, fetchUnit.bind(null, query), {
    ...defaultOptions,
    enabled: catalogCode.length > 0 && ssd.length > 0 && unitId.length > 0,
    ...options,
  })
}
