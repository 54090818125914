import { Post } from 'App/View/Blog/Post'
import { PostsList } from 'App/View/Blog/PostsList'

export default () => [
  {
    path: '/blog',
    component: PostsList,
    exact: true,
  },
  {
    path: '/blog/:slug',
    component: Post,
    exact: true,
  },
]
