import styled from 'styled-components'
import BaseDropdownButton from 'react-bootstrap/DropdownButton'
import BaseDropdown from 'react-bootstrap/Dropdown'

const DropdownButton = styled(BaseDropdownButton).attrs((props) => ({
  variant: props.variant ?? 'link',
}))<{ danger: boolean }>`
  && button {
    color: ${(props) => props.theme.colorWhite};
    background-color: ${(props) => props.theme.colorOrange};
    text-decoration: none;
    display: flex;
    align-items: center;
    border-radius: ${(props) => props.theme.baseRadius};
  }
  && .dropdown-menu {
    border: 1px solid ${(props) => props.theme.colorWhite};
    border-radius: ${(props) => props.theme.baseRadius};
    box-shadow: ${(props) => props.theme.baseBoxShadow};
  }
`
const DropdownItem = styled(BaseDropdown.Item)`
  &&& {
    color: ${(props) => props.theme.colorBlack};

    transition: all 250ms ease-out;
    &:hover {
      /* color: ${(props) => props.theme.colorWhite}; */
      background-color: ${(props) => props.theme.colorOrange};
    }
    &:active,
    &.active {
      color: ${(props) => props.theme.colorWhite}!important;
      background-color: ${(props) => props.theme.colorOrange};
    }
  }
`
const DropdownItemText = styled(BaseDropdown.ItemText)`
  &&:active,
  &&.active {
    color: ${(props) => props.theme.light};
    background-color: ${(props) => props.theme.colorWhite};
  }
`

export const Dropdown = {
  Button: DropdownButton,
  Item: DropdownItem,
  ItemText: DropdownItemText,
  Header: BaseDropdown.Header,
  Divider: BaseDropdown.Divider,
}
