// import { anonymousСlient } from '@monorepo/core'

// import { ICatalog } from '../interfaces'
import data from '../data'

export type RefreshTokenResponse = {
  token: string
  refresh_token: string
}

export async function fetchCatalogs() {
  try {
    // const { data } = await anonymousСlient.get<ICatalog[]>('../data.ts')

    // return data
    return Promise.resolve(data)
  } catch (error) {
    throw error
  }
}
