import React, { useMemo } from 'react'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik'

import { OrdersFilter } from '../../interfaces'
// import { useOrdersList } from '../../hook'

type OrdersFilterProviderProps = {
  children:
    | ((props: FormikContextType<OrdersFilter>) => React.ReactNode)
    | React.ReactNode
}

export const OrdersFilterProvider: React.FC<OrdersFilterProviderProps> = ({
  children,
}) => {
  const location = useLocation()
  const history = useHistory()

  const query = useMemo(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as {
      statusGroup?: string
    }
  }, [location.search])

  const initialValues: OrdersFilter = {
    statusGroup: query.statusGroup ?? 'isActive',
  }

  const onSubmit = async (
    values: OrdersFilter,
    _helpers: FormikHelpers<OrdersFilter>
  ) => {
    const nextQuery = { ...query, ...values }
    const nextSearch = qs.stringify(nextQuery, { addQueryPrefix: true })

    history.push({
      ...location,
      search: nextSearch,
    })
  }

  const formik: FormikContextType<OrdersFilter> = useFormik<OrdersFilter>({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  })

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function' ? children(formik) : children}
    </FormikProvider>
  )
}

export function useOrdersFilterForm() {
  return useFormikContext<OrdersFilter>()
}

// export function useFilteredOrdersList() {
//   const { values: filter } = useOrdersFilterForm()

//   return useOrdersList(filter)
// }
