import React from 'react'
import Container from 'react-bootstrap/Container'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { Page } from 'App/Layout'
import { Callout } from 'App/Components/Callout'

export interface CatalogErrorPageProps {
  message?: React.ReactNode
}

export const CatalogErrorPage: React.FC<CatalogErrorPageProps> = ({
  message,
}) => (
  <Page title="Каталоги временно не доступны">
    <Container>
      <Callout variant="danger" icon={faTimesCircle}>
        {message ?? 'Оригинальные каталоги временно не доступны'}
      </Callout>
    </Container>
  </Page>
)
