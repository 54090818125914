import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

export const List = styled.ul`
  list-style-type: none;
`
const ItemWrapper = styled.li`
  & *,
  & *:hover {
    color: inherit;
  }
  & > svg {
    color: ${(props) => props.theme.colorOrange};
    margin-right: 8px;
  }
`
export const Item: React.FC<{ icon: IconDefinition }> = ({
  icon,
  children,
}) => (
  <ItemWrapper>
    <FontAwesomeIcon icon={icon} fixedWidth />
    <span>{children}</span>
  </ItemWrapper>
)
