import React from 'react'
import { Button, Col, Row, RowProps } from 'react-bootstrap'
import { filter } from 'lodash/fp'
import { Form } from 'formik'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faShoppingBag } from '@fortawesome/free-solid-svg-icons'

import { IContract, useContracts } from '@monorepo/contract_module'
import { useDeliveryMethods } from '@monorepo/delivery_module'
import { usePaymentMethods } from '@monorepo/payment_module'
import { usePlaceOrderForm } from '@monorepo/order_module'
import {
  CommentsField,
  ContractField,
  DeliveryField,
  PaymentField,
} from './fields'
import { ErrorsSection } from './errors_section'
import { CompositButton } from 'App/Components'

type PlaceOrderProps = RowProps & {
  //
}

export const PlaceOrder: React.FC<PlaceOrderProps> = ({ ...props }) => {
  const { isSubmitting, isValid, isValidating, errors } = usePlaceOrderForm()
  const { data: contracts } = useContracts({
    select: filter<IContract>('isForOrder'),
  })
  const { data: deliveryMethods } = useDeliveryMethods()
  const { data: paymentMethods } = usePaymentMethods()

  return (
    <Form>
      <Row {...props}>
        <Col md="6">
          <ContractField name="contract" contracts={contracts} />
          <PaymentField name="paymentMethod" paymentMethods={paymentMethods} />
          <DeliveryField
            name="deliveryMethod"
            deliveryMethods={deliveryMethods}
          />
        </Col>
        <Col md="6">
          <CommentsField name="customerComment" />
        </Col>

        {!isValid && !isSubmitting && (
          <Col xs="12" md={{ offset: 2, span: 8 }} className="mt-5">
            <ErrorsSection errors={errors} />
          </Col>
        )}

        <Col xs="12" className="text-center mt-5">
          <hr />
          <div className="d-flex justify-content-center align-items-center flex-column flex-md-row">
            <span className="m-2">
              Со всеми пунктами <Link to="/pages/oferta">договора</Link>{' '}
              ознакомлен,
            </span>
            <CompositButton
              className="m-2"
              type="submit"
              disabled={!isValid || isValidating || isSubmitting}
            >
              Согласен, отправить заказ{' '}
              <FontAwesomeIcon
                icon={isSubmitting ? faCircleNotch : faShoppingBag}
                spin={isSubmitting}
              />
            </CompositButton>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
