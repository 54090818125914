import styled from 'styled-components'

import { Message as BaseMessage } from './Message'

export const Message = styled(BaseMessage)`
  background-color: ${(props) => props.theme.colorLightGrey};
  /* border-bottom: 2px solid ${(props) => props.theme.colorOrange}; */
  /* border-left: 0.5rem solid ${(props) => props.theme.colorOrange}; */
  border-left: 0.5rem solid ${(props) => props.theme.colorDarkGrey};
  padding: 0.5rem 1rem;
  margin: 0 -0.5rem 0.25rem;
`
