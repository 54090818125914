import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.secondary};
  box-shadow: ${(props) => props.theme.baseBoxShadow};
  border-radius: ${(props) => props.theme.baseRadius};
  padding: 16px;

  & .nav-pills {
    & [role='tab'] {
      font-size: 0.875em;
      line-height: 1.5em;
      &.active {
        background-color: ${(props) => props.theme.primary};
      }
    }
  }
  & .tab-content {
    padding-top: 1.5rem;
  }
`
