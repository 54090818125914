import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { TableRow } from './components/base_table_row'
import { THead } from '../../../Components/table'
import {
  AmountCell,
  DeliveryTimeCell,
  InfoCell,
  PriceCell,
  ProductCell,
  ReferenceCell,
  TotalPriceCell,
} from './components/base_table_cells'

type CartItemHeaderProps = {
  //
}

export const CartItemHeader: React.FC<CartItemHeaderProps> = () => (
  <THead className="d-none d-md-block">
    <TableRow>
      <ProductCell>Наименование</ProductCell>
      <InfoCell>
        <FontAwesomeIcon icon={faInfoCircle} />
      </InfoCell>
      <ReferenceCell className="align-self-center text-center">
        Референс
      </ReferenceCell>
      <DeliveryTimeCell className="align-self-center text-center">
        Срок
      </DeliveryTimeCell>
      <PriceCell className="align-self-center text-center">Цена</PriceCell>
      <AmountCell className="align-self-center text-center">Кол-во</AmountCell>
      <TotalPriceCell className="align-self-center text-center">
        Сумма
      </TotalPriceCell>
    </TableRow>
  </THead>
)
