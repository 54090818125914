import { array, boolean, string, object, SchemaOf } from 'yup'

import { IContract } from '@monorepo/contract_module'
import { CreateOrderDTO } from '../dto'
import { createOrderItemSchema } from './create_order_item.schema'

export const createOrderSchema: SchemaOf<CreateOrderDTO> = object({
  contract: object()
    .shape({
      id: string().required('Договор не указан'),
      isForOrder: boolean().strip(), //.required(),
      isOrderDeny: boolean().strip(), //.required(),
    })
    .label('Договор')
    .nullable()
    .default(undefined)
    .required('Договор не указан')
    // .optional()
    .test({
      name: 'isForOrder',
      exclusive: true,
      message: 'Договор не предназначен для покупки',
      test: (value: IContract) => value?.isForOrder ?? true,
    })
    .test({
      name: 'isOrderDeny',
      exclusive: true,
      message: 'Операции запрещены по данному договору',
      test: (value: IContract) => !(value?.isOrderDeny ?? false),
    }),
  customerComment: string().default(''),
  deliveryCustomerName: string().default(''),
  deliveryAddress: object()
    .shape({
      id: string(),
      uid: string(),
      value: string(),
      unrestricted_value: string(),
      data: object(),
    })
    .label('Адрес доставки')
    .nullable()
    .default(undefined)
    .when('deliveryMethod.isAddressRequired', {
      is: true,
      then: (schema: any) =>
        schema.required('Адрес доставки не указан или не найден.').test({
          name: 'isAddresEmpty',
          exclusive: true,
          message: 'Адрес доставки не указан или не найден.',
          test: (value: any /*IDeliveryAddress*/) =>
            typeof value?.data !== 'undefined' ||
            typeof value?.id !== 'undefined',
        }),
      otherwise: (schema: any) => schema.strip(),
    }),
  deliveryMethod: object()
    .shape({
      id: string().required('Способ доставки не указан'),
      isAddressRequired: boolean(), //.strip(true),
      // isDefault: boolean().strip(true),
      // isDeliveryRouteRequired: boolean().strip(true),
      // isUsePickPoints: boolean().strip(true),
    })
    .label('Способ доставки')
    .required('Способ доставки не указан')
    .nullable()
    .default(undefined),
  // deliverySchedule: deliveryScheduleSchema
  //   .label('Время доставки')
  //   // .when('deliveryMethod', {
  //   //   is: (deliveryMethod: IDeliveryMethod) =>
  //   //     deliveryMethod?.isDeliveryRouteRequired ?? false,
  //   //   then: (schema) => schema.required('Время доставки не указано'),
  //   // })
  //   .nullable()
  //   .default(undefined),
  paymentMethod: object()
    .shape({
      id: string().required('Способ оплаты не указан'),
    })
    .label('Способ оплаты')
    .required('Способ оплаты не указан')
    .nullable()
    .default(undefined),
  items: array()
    .label('Товар')
    .of(createOrderItemSchema)
    .min(1, 'Не выбрано ни одного товара в корзине')
    .required()
    .ensure()
    // .nullable()
    .default([]),
})
