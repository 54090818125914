import styled from 'styled-components'

export const SocialLinksList = styled.ul`
  list-style-type: none;
  display: flex;

  li {
    transition: all 250ms ease-in-out;
    * {
      color: ${(props) => props.theme.footerColor};
    }
    &:hover {
      transform: scale(1.25);
    }
  }
`
