import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { IOrder, OrderId } from '../interfaces'

export async function fetchOrderById(id: OrderId) {
  try {
    const { data } = await apiClient.get<IOrder | null>(`/orders/show/${id}`)
    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при получении заказа')
  }
}
