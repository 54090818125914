// import { IMenu } from '../interfaces'

import {initialState} from '@monorepo/App/data/menu'

// const baseURL = 'http://localhost:3000'

export function fetchMenu() {
  return Promise.resolve(initialState)
  // return fetch(baseURL + '/api/menu').then<Record<string, IMenu>>((data) => data.json())
}
