import styled from 'styled-components'
import BaseCard from 'react-bootstrap/Card'

export const Container = styled.div.attrs((props: { width?: number }) => ({
  width: props.width || 380,
}))`
  width: ${(props) => props.width}px;
  max-width: 90vw;
  height: auto;
  margin-left: auto;
  margin-right: auto;
`

export const Card = styled(BaseCard)`
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */
  /* border: 1px solid ${(props) => props.theme.secindary}; */
  /* border-radius: ${(props) => props.theme.baseRadius}; */
`
