import { apiClient } from '@monorepo/core'
import { IStaticPage } from '../interfaces'

export async function fetchStaticPageBySlug(slug: string) {
  try {
    const { data } = await apiClient.get<IStaticPage>(`/page/${slug}`)

    return data
  } catch (error) {
    throw error
  }
}
