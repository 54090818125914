import { useCallback, useMemo, useState } from 'react'
import clamp from 'lodash/clamp'
import type { ViewType } from 'react-images'

export function useLightbox(
  images: string | string[] | ViewType | ViewType[],
  initialIndex: number = 0
) {
  const [show, toggleShow] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(initialIndex)

  const views: ViewType[] = useMemo(() => {
    return (Array.isArray(images) ? images : [images]).map((image) =>
      typeof image === 'string'
        ? {
            source: image,
          }
        : image
    )
  }, [images])

  const showHandler = useCallback(
    (index = initialIndex) => {
      setCurrentIndex(clamp(index, 0, views.length))
      toggleShow(true)
    },
    [initialIndex, views.length]
  )

  const hideHandler = toggleShow.bind(null, false)

  return { show, currentIndex, views, showHandler, hideHandler }
}
