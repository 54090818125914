import styled from 'styled-components'
import { PhoneLink } from 'App/Components'

export const PhoneNumber = styled(PhoneLink)`
  && {
    color: ${(props) => props.theme.footerColor};
    font-size: ${(props) => parseInt(props.theme.baseFontSize) * 1.5}px;
    font-weight: ${(props) => props.theme.fontSemibold};
    text-decoration: none;
  }
`
