import React, { useMemo } from 'react'
import { IGood } from '@monorepo/application_module'
import { FormattedMessage } from 'react-intl'

const message = `{
  totalOffers, plural,
    =0 {Ничего не найдено}
    one {Найдено # предложение}
    few {Найдено # предложения}
    many {Найдено # предложений}
    other {Найдено # предложений}
}{
  totalGoods, plural,
    =0 {}
    =1 {}
    one { # товара}
    other { # товаров}
}`

export const SearchInfo: React.FC<{ items: IGood[] }> = ({ items }) => {
  const values = useMemo(() => {
    const totalGoods = items.length
    const totalOffers = items.reduce(
      (acc, item) => acc + (item?.offers?.length ?? 0),
      0
    )
    return { totalGoods, totalOffers }
  }, [items])

  return (
    <FormattedMessage
      id="search_table.total_found"
      defaultMessage={message}
      values={values}
    />
  )
}
