import React, { useEffect, useRef } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import styled from 'styled-components'
import scrollIntoView from 'scroll-into-view-if-needed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'

import { ILaximoCatalog, ILaximoUnitDetail } from '@monorepo/laximo_module'
import { getSearchUrl } from '@monorepo/application_module/helpers'
import { IconButton } from 'App/Components'
import { renderPopover } from './render_popover'

interface DetailRowProps {
  item: ILaximoUnitDetail
  catalog: ILaximoCatalog
  variant?: number
  selected?: boolean
  scrollContainerRef?: React.RefObject<HTMLDivElement>
}

const Wrapper = styled.tr<{ $selected?: boolean }>`
  & > td {
    transition: all 250ms ease;
    &:first-child {
      border-left: 10px solid
        ${(props) => (props.$selected ? props.theme.danger : 'transparent')};
    }
  }
`

export const DetailRow: React.FC<DetailRowProps> = ({
  item,
  variant,
  catalog,
  selected,
  scrollContainerRef,
}) => {
  const itemRef = useRef<HTMLTableRowElement>(null)

  useEffect(() => {
    const node = itemRef.current
    const boundary = scrollContainerRef?.current
    if (
      boundary &&
      node &&
      selected &&
      (typeof variant === 'undefined' || variant === 1)
    ) {
      scrollIntoView(node, {
        block: 'center',
        inline: 'center',
        // block: 'nearest',
        // inline: 'nearest',
        scrollMode: 'if-needed',
        behavior: 'smooth',
        boundary,
      })
    }
  }, [scrollContainerRef, selected, variant])

  return (
    <Wrapper $selected={selected} ref={itemRef}>
      <td>{variant && `Вар.${variant}:`}</td>
      <td>
        <a
          href={getSearchUrl({
            number: item.oem,
            brand: { name: catalog.brand },
          })}
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          {item.oem}
        </a>
      </td>
      <td>{item.name || 'Не указано'}</td>
      <td>
        {item.attributes && (
          <OverlayTrigger placement="auto" overlay={renderPopover(item)}>
            <IconButton>
              <FontAwesomeIcon icon={faInfo} />
            </IconButton>
          </OverlayTrigger>
        )}
      </td>
    </Wrapper>
  )
}
