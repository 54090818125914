import React from 'react'
import styled from 'styled-components'

import { TopSection } from './TopSection'
import { MiddleSection } from './MiddleSection'
import { BottomSection } from './BottomSection'
import { useThemeVariant } from 'App/Layout/theme'

type MainHeaderProps = {
  //
}

const Wrapper = styled.header`
  && {
    color: ${(props) => props.theme.headerPrimaryColor};
    /* box-shadow: none; */
    box-shadow: ${(props) => props.theme.baseBoxShadow};
    a {
      color: ${(props) => props.theme.headerPrimaryColor};
    }
  }
`

export const MainHeader: React.FC<MainHeaderProps> = () => {
  const { isDark } = useThemeVariant()

  return (
    <Wrapper className="header et-desktop et-clearfix transparent-false sticky-true shadow-true shadow-sticky-false mobile-false tablet-portrait-false tablet-landscape-false desktop-true">
      <TopSection $isDark={isDark} />
      <MiddleSection $isDark={isDark} />
      <BottomSection />
    </Wrapper>
  )
}
