import React from 'react'
import { Col, Row, RowProps } from 'react-bootstrap'
import invariant from 'invariant'
import { TR } from '../../../../Components/table'

type TableRowProps = Omit<RowProps, 'children' | 'as'> & {
  before?: React.ReactNode
  after?: React.ReactNode
  // beforeInfoSection?: React.ReactNode
  // afterInfoSection?: React.ReactNode
  // beforeDataSection?: React.ReactNode
  // afterDataSection?: React.ReactNode
  children?: React.ReactNodeArray
  as?: React.ReactElement
}

export const TableRow: React.FC<TableRowProps> = ({
  children,
  className,
  before,
  after,
  as,
  ...props
}) => {
  const requireColomns = [7, 8]
  const givenColomns = React.Children.count(children)
  invariant(
    requireColomns.includes(givenColomns),
    `Table require ${requireColomns.join(
      ', '
    )} columns, ${givenColomns} passed.`
  )

  return (
    <TR {...props}>
      {before}
      <Col md={4} xs={12}>
        {children?.[0]}
      </Col>
      <Col
        md={8}
        xs={12}
        className="d-flex flex-column justify-content-between"
      >
        <Row noGutters>
          {children?.[1]}
          {children?.[2]}
          {children?.[3]}
          {children?.[4]}
          {children?.[5]}
          {children?.[6]}
        </Row>
        {children?.[7] && (
          <Row noGutters>
            <Col xs="12">{children?.[7]}</Col>
          </Row>
        )}
      </Col>
      {after}
    </TR>
  )
}
