import React from 'react'
import Table, { TableProps } from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk, faHammer } from '@fortawesome/free-solid-svg-icons'

import type { IWorkSchedule } from '@monorepo/application_module'
import { FormattedWorkSchedule } from '@monorepo/application_module/components/formatted_work_schedule'

export type ScheduleTableProps = TableProps & {
  schedule: IWorkSchedule
}

const ScheduleTable: React.FC<ScheduleTableProps> = ({
  schedule,
  ...props
}) => (
  <Table borderless {...props}>
    <tbody>
      <FormattedWorkSchedule items={schedule} groupByDays dayShort>
        {(props) => (
          <tr data-holiday={props.isHoliday && 'holiday'}>
            <td>{props.period.join(' - ')}:</td>
            <td>
              <FontAwesomeIcon icon={props.isHoliday ? faAsterisk : faHammer} />{' '}
              {props.isHoliday
                ? 'Выходной'
                : `${props.startsAt} - ${props.endsAt}`}
            </td>
          </tr>
        )}
      </FormattedWorkSchedule>
    </tbody>
  </Table>
)

export default ScheduleTable
