import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'

import { IMenuItem } from '@monorepo/menu_module'
import { Link } from 'App/Components'
import { Menu } from './Menu'

type PropType = {
  item: IMenuItem
  depth?: number
}

const Container = styled.li`
  // padding: 10px !important;

  a {
    /* padding: 0.75rem 1rem; */
    text-decoration: none;
    border-bottom: 3px solid transparent;
    border-radius: ${(props) => props.theme.baseRadius}!important;
    &:hover {
      background-color: ${(props) =>
        props.theme.headerButtonBackgroundHoverColor};
    }
  }
  /* &:hover a {
    background-color: ${(props) => props.theme.colorLightBlack};
  } */
  &.depth-0 > a {
    /* color: ${(props) => props.theme.colorWhite}; */
    font-weight: ${(props) => props.theme.fontSemibold};
    font-size: 110%;
    padding: 0.5rem 0.25rem;
    margin: 0 0.125rem;
  }

  ul {
    max-height: 75vh;
    overflow: auto;

    &.sub-menu {
      background-color: ${(props) => props.theme.headerSubmenuBackgroundColor};

      .menu-item:hover a {
        background-color: ${(props) =>
          props.theme.headerSubmenuBackgroundHoverColor};
      }
    }
  }
`

export const MenuItem: React.FC<PropType> = ({ item, depth = 0 }) => (
  <Container
    className={cn(
      'menu-item',
      { 'menu-item-has-children': !!item.menu },
      `depth-${depth}`
    )}
  >
    <Link to={item.url ?? '/'} external={item.external} className="mi-link">
      <span className="txt">{item.name}</span>
      <span className="arrow-down" />
    </Link>
    {item.menu && (
      <Menu
        items={item.menu}
        depth={depth + 1}
        className="sub-menu"
        // style={{ backgroundColor: '#212121' }}
      />
    )}
  </Container>
)
export const MenuItemOriginal2: React.FC<PropType> = ({ item, depth = 0 }) => (
  <Container
    className={cn(
      'menu-item',
      { 'menu-item-has-children': !!item.menu },
      `depth-${depth}`
    )}
  >
    <Link to={item.url ?? '/'} external={item.external} className="mi-link">
      <span className="txt">{item.name}</span>
      <span className="arrow-down" />
    </Link>
    {item.menu && (
      <Menu
        items={item.menu}
        depth={depth + 1}
        className="sub-menu"
        // style={{ backgroundColor: '#fff' }}
      />
    )}
  </Container>
)

export const MenuItemOriginal1: React.FC<PropType> = ({ item, depth = 0 }) => (
  <li
    className={cn(
      'menu-item',
      { 'menu-item-has-children': !!item.menu },
      `depth-${depth}`
    )}
  >
    <Link to={item.url ?? '/'} external={item.external} className="mi-link">
      <span className="txt" style={{ color: 'blue' }}>
        {item.name}
      </span>
      <span className="arrow-down" />
    </Link>
    {!item.menu || (
      <Menu items={item.menu} depth={depth + 1} className="sub-menu" />
    )}
  </li>
)
