import React from 'react'

import { ICartItem } from '@monorepo/cart_module'
import { IPaymentMethod } from '@monorepo/payment_module'
import { useRemoveCartItem } from '@monorepo/cart_module/hooks'
import { Table } from 'App/Components/table'
import { CartItemHeader } from './cart_item_header'
import { CartItemFooter } from './cart_item_footer'
import { CartItemRow } from './cart_item_row'

type CartItemsTableProps = {
  items: ICartItem[]
  paymentMethod?: IPaymentMethod
}

export const CartItemsTable: React.FC<CartItemsTableProps> = ({
  items,
  paymentMethod,
}) => {
  const { remove: removeCartItem } = useRemoveCartItem()

  return (
    <Table>
      <CartItemHeader />
      {items.map((item, idx) => (
        <CartItemRow
          key={item.id}
          item={item}
          className="py-1"
          offersLoading={false}
          onRemove={removeCartItem.bind(null, item)}
        />
      ))}
      <CartItemFooter items={items} paymentMethod={paymentMethod} />
    </Table>
  )
}
