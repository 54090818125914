import { ILaximoVehicle } from '@monorepo/laximo_module'
import { apiClient } from '@monorepo/core'
import {
  CatalogCode,
  ILaximoCatalog,
  ILaximoMapObject,
  ILaximoUnit,
  ILaximoUnitDetail,
  LaximoUnitId,
  Nullable,
} from '../interfaces'

export interface IUnitResponse {
  readonly catalog: Nullable<ILaximoCatalog>
  readonly vehicle: Nullable<ILaximoVehicle>
  readonly unit: Nullable<ILaximoUnit>
  readonly details: Nullable<ILaximoUnitDetail[]>
  readonly mapObjects: Nullable<ILaximoMapObject[]>
}

interface QueryParams {
  readonly ssd: string
  readonly unitId: LaximoUnitId
  readonly catalogCode: CatalogCode
}

export async function fetchUnit(params: QueryParams) {
  try {
    const { data } = await apiClient.get<IUnitResponse>('/laximo2/unit', {
      params,
    })

    return data
  } catch (error) {
    throw error
  }
}
