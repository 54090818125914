import React from 'react'
import { Field } from 'formik'

import { BaseFormGroup } from 'App/Components/FormFields'

type AuthSectionProps = {
  //
}

export const AuthSection: React.FC<AuthSectionProps> = () => (
  <div className="row clearfix">
    <div className="col-lg-6 col-md-12">
      <BaseFormGroup name="email" label="email">
        <Field
          className="form-control"
          placeholder="email"
          type="text"
          name="email"
        />
      </BaseFormGroup>
    </div>

    <div className="col-lg-6 col-md-12">
      <BaseFormGroup name="plainPassword[first]" label="Пароль">
        <Field
          className="form-control"
          placeholder="Пароль"
          type="password"
          name="plainPassword[first]"
          autoComplete="off"
        />
      </BaseFormGroup>
      <BaseFormGroup name="plainPassword[second]" label="Повторите пароль">
        <Field
          className="form-control"
          placeholder="Повторите пароль"
          type="password"
          name="plainPassword[second]"
          autoComplete="off"
        />
      </BaseFormGroup>
    </div>
  </div>
)
