import { useQuery, UseQueryOptions } from 'react-query'

import { ICatalog } from '../interfaces'
import { fetchCatalogs } from '../api/fetch_catalogs.api'

const QUERY_KEY = 'catalogs-parts-catalog'

type CatalogsFilter = {
  //
}

export function useCatalogsList<TData = ICatalog[]>(
  filter?: CatalogsFilter,
  options?: UseQueryOptions<ICatalog[], unknown, TData>
) {
  return useQuery([QUERY_KEY, filter] as any, fetchCatalogs, options)
}
