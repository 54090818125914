import React from 'react'

type PhoneLinkProps = Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> & {
  phone: string
  children?: React.ReactNode | ((phone: string) => React.ReactNode)
}

export const PhoneLink: React.FC<PhoneLinkProps> = ({
  phone,
  children,
  ...props
}) => (
  <a href={`tel:${phone.replace(/[^+0-9]?/g, '')}`} {...props}>
    {typeof children === 'undefined'
      ? phone
      : typeof children === 'function'
      ? children(phone)
      : children}
  </a>
)
