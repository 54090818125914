import React from 'react'
import cn from 'classnames'

type TableRowProps = React.HTMLAttributes<HTMLDivElement> & {
  //
}

export const TableRow: React.FC<TableRowProps> = ({ className, children }) => {
  const cols = React.Children.toArray(children)

  return (
    <div className={cn(className, 'row py-1')}>
      <div className="col-2 d-flex align-items-center">{cols?.[0]}</div>
      <div className="col-6 d-flex flex-column flex-md-row px-0 no-gutters justify-content-center align-items-md-center">
        {cols?.[1]}
      </div>
      <div className="col-2 d-flex justify-content-end pr-2 align-items-center">
        {cols?.[2]}
      </div>
      <div className="col-2 d-flex px-1 px-sm-4 align-items-center">
        {cols?.[3]}
      </div>
    </div>
  )
}
