import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'qs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faCogs, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { GroupsList } from '../groups_list'

export interface IBaseItem {
  name: string
  quickgroupid: string | number
  childGroups: IBaseItem[] | null
}

const ListItem = styled.li`
  //
`

export function GroupsListItem<T extends IBaseItem>({ item }: { item: T }) {
  const KEY = 'expanded'
  const location = useLocation()
  const hasChildrens = (item.childGroups?.length ?? 0) > 0

  const [isExpand, query] = useMemo(() => {
    const { [KEY]: expanded = [], ...rest } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as {
      [KEY]?: string[]
    }
    const current = expanded.includes(String(item.quickgroupid))

    return [
      current,
      {
        [KEY]: expanded,
        ...rest,
      },
    ]
  }, [item.quickgroupid, location.search])

  const url = useMemo(() => {
    if (hasChildrens) {
      const prev = query[KEY] ?? []
      const next = isExpand
        ? prev.filter((current) => current !== item.quickgroupid)
        : prev.concat(String(item.quickgroupid))

      return [
        location.pathname,
        qs.stringify(
          { ...query, /*groupId: item.quickgroupid,*/ [KEY]: next },
          { addQueryPrefix: true }
        ),
      ].join('')
    }

    return [
      location.pathname,
      qs.stringify(
        { ...query, groupId: item.quickgroupid },
        { addQueryPrefix: true }
      ),
    ].join('')
  }, [hasChildrens, isExpand, item.quickgroupid, location.pathname, query])

  return (
    <ListItem key={item.quickgroupid}>
      <Link to={url} className={hasChildrens ? 'text-reset' : undefined}>
        <span className="fa-li">
          <FontAwesomeIcon
            icon={
              !hasChildrens ? faCogs : isExpand ? faMinusSquare : faPlusSquare
            }
          />
        </span>
        {item.name}
      </Link>
      {item.childGroups && isExpand && <GroupsList items={item.childGroups} />}
    </ListItem>
  )
}
