import React, { useMemo } from 'react'
import { partition } from 'lodash/fp'
import { faRing, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { IGood } from '@monorepo/application_module'
import { AddToCartProvider, useSearchResult } from '@monorepo/search_module'
import { ResultsTable } from './ResultsTable'
import { AddToCartModal } from './components/add_to_cart.modal'
import { Callout } from 'App/Components/Callout'
import {
  sortGoodsBy,
  SortGoodsByProps,
} from '@monorepo/search_module/hooks/utils'

type SearchResultsProps = {
  number: string
  brandName: string
}

const partitionByCross = partition<IGood>('isCross')
const partitionByOriginalCross = partition<IGood>('isOriginalCross')

export const SearchResults: React.FC<SearchResultsProps> = ({
  number,
  brandName,
}) => {
  const {
    data: goods,
    isFetching,
    isFetched,
  } = useSearchResult({ number, brandName })

  const sortBy: SortGoodsByProps['by'] = 'price'
  const sortDirection: SortGoodsByProps['direction'] = 'asc'

  const [result, originalCrosses, crosses] = useMemo(() => {
    const sortGoods = sortGoodsBy({ by: sortBy, direction: sortDirection })
    const [allCrosses, result] = partitionByCross(goods)
    const [originalCrosses, crosses] = partitionByOriginalCross(allCrosses)

    return [sortGoods(result), sortGoods(originalCrosses), sortGoods(crosses)]
  }, [goods])

  // if (true || isFetching) {
  //   return (
  //     <Callout loading>
  //       <span className="lead">Поиск товаров на складе...</span>
  //     </Callout>
  //   )
  // }

  if (isFetched && !goods?.length) {
    return (
      <Callout icon={faTimesCircle} variant="danger">
        <span className="lead">
          По запросу{' '}
          <strong>
            {brandName} «{number.toUpperCase()}»
          </strong>{' '}
          ничего не найдено
        </span>
        <hr className="m-0" />
        <em>Измените параметры и попробуйте ещё раз...</em>
      </Callout>
    )
  }

  return (
    <>
      {/* <Callout icon={faRing} loading={isFetching}>
        <span className="lead">
          {!goods?.length ? (
            'Поиск товаров на складе...'
          ) : (
            <SearchInfo items={goods} />
          )}
        </span>
      </Callout> */}
      {isFetching && (
        <Callout icon={faRing} loading={isFetching}>
          <span className="lead">Поиск товаров на складе...</span>
        </Callout>
      )}
      <AddToCartProvider>
        {({ isAdding, close, offer }) => (
          <>
            {offer && <AddToCartModal onClose={close} item={offer} />}
            {Boolean(result.length) && (
              <ResultsTable
                items={result}
                title={`Результаты поиска «${brandName} ${number.toUpperCase()}»:`}
              />
            )}
            {Boolean(originalCrosses.length) && (
              <ResultsTable
                items={originalCrosses}
                title={`Оригинальные замены «${brandName} ${number.toUpperCase()}»:`}
              />
            )}
            {Boolean(crosses.length) && (
              <ResultsTable
                items={crosses}
                title={`Неоригинальные замены «${brandName} ${number.toUpperCase()}»:`}
              />
            )}
          </>
        )}
      </AddToCartProvider>
    </>
  )
}
