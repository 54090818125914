import { useCallback, useEffect, useState } from 'react'
import SwiperCore from 'swiper'
import delay from 'lodash/delay'

export function useSwiperControl(options?: { stopOnHover?: boolean }) {
  const [swiper, setSwiper] = useState<SwiperCore | undefined>()

  const nextHandler = useCallback(() => {
    swiper?.slideNext()
    swiper && delay(swiper?.autoplay.start, 1000)
  }, [swiper])

  const prevHandler = useCallback(() => {
    swiper?.slidePrev()
    swiper && delay(swiper?.autoplay.start, 1000)
  }, [swiper])

  useEffect(() => {
    function start() {
      swiper?.autoplay.start()
    }

    function stop() {
      swiper?.autoplay.stop()
    }
    const stopOnHover = !(options?.stopOnHover === false)

    if (stopOnHover && swiper?.autoplay.running) {
      swiper?.el.addEventListener('mouseover', stop)
      swiper?.el.addEventListener('mouseout', start)
    }

    return () => {
      if (stopOnHover) {
        swiper?.el?.removeEventListener('mouseover', stop)
        swiper?.el?.removeEventListener('mouseout', start)
      }
    }
  }, [options, swiper])

  return {
    nextHandler,
    prevHandler,
    bind: {
      onSwiper: setSwiper,
    },
  }
}
