import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Page } from 'App/Layout'
import { CabinetLayout } from 'App/PageComponents/Cabinet/CabinetLayout'
import { useCurrentUser } from '@monorepo/user_module'
import { ProfileCard } from 'App/PageComponents/Cabinet/Dashboard/ProfileCard'
// import { AddressCard } from 'App/PageComponents/Cabinet/Dashboard/AddressCard'
import { OrdersTable } from 'App/PageComponents/Order/orders_list/orders_table'

// import { FilterForm, OperationsTable } from 'App/Components/Balance'

type DashboardProps = {
  //
}

const ordersFilter = { statusGroup: 'isActive' }

const Dashboard: React.FC<DashboardProps> = () => {
  const { data: user } = useCurrentUser()

  return (
    <Page title="Мой кабинет" authenticated>
      <Container>
        <CabinetLayout>
          <Row className="mb-5">
            <Col xs="12" md="6">
              {user && <ProfileCard user={user} />}
            </Col>
            {/* <Col xs="12" md="6">
              {user && <AddressCard user={user} />}
            </Col> */}
          </Row>
          <Row className="mt-5">
            <Col xs="12" className="lead">
              Текущие заказы:
            </Col>
            <Col xs="12">
              <hr />
            </Col>
            <Col xs="12">
              <OrdersTable filter={ordersFilter} />
            </Col>
          </Row>
          {/* <OperationsFilterProvider>
          {({ values }) => (
            <div className="row clearfix">
              <div className="card">
                <div className="body"><FilterForm /></div>
              </div>
              <OperationsTable filter={values} />
            </div>
          )}
        </OperationsFilterProvider> */}
        </CabinetLayout>
      </Container>
    </Page>
  )
}

export default Dashboard
