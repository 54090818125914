import React, { useMemo } from 'react'
import Container from 'react-bootstrap/Container'
import { useLocation } from 'react-router'
import qs from 'qs'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

import { Page } from 'App/Layout'
import { Callout } from 'App/Components/Callout'
import { SearchResults } from 'App/PageComponents/Search/SearchResults'
import { BrandsList } from 'App/PageComponents/Search/BrandsList'

type SearchProps = {
  //
}

const Search: React.FC<SearchProps> = () => {
  const { search } = useLocation()
  const query = useMemo(
    () =>
      qs.parse(search, { ignoreQueryPrefix: true }) as {
        brandName?: string
        number?: string
      },
    [search]
  )

  if (!query.number) {
    return (
      <Page title="Не указана строка поиска." grid topSpace bottomSpace>
        <Container>
          <Callout icon={faQuestion}>
            Что ищем? Не указана строка поиска.
          </Callout>
        </Container>
      </Page>
    )
  }

  return (
    <Page
      title={
        query.brandName
          ? `Поиск «${query.brandName} ${query.number.toUpperCase()}»`
          : `Выбор производителя «${query.number.toUpperCase()}»`
      }
      grid
      // topSpace
      bottomSpace
    >
      <Container>
        {query.brandName ? (
          <SearchResults number={query.number} brandName={query.brandName} />
        ) : (
          <BrandsList query={query.number} />
        )}
      </Container>
    </Page>
  )
}

export default Search
