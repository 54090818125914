import React from 'react'
// import { Section as Section1 } from './Section1'

// import { useGoodsCarousel } from '@monorepo/goods_carousel_module'
import { useBlogPostsList } from '@monorepo/blog_post_module'
import { useSlider } from '@monorepo/slide_module'
// import { SlideShow } from './SlideShow'
// import { Section as Section2 } from './Section2'
import { Catalogs } from './sections/catalogs'
import { Section as Section4 } from './Section4'
// import { Section as Section5 } from './Section5'
// import { Section as Section6 } from './Section6'
// import { Section as Section7 } from './Section7'
// import { Section as Section8 } from './Section8'
// import { Articles } from './sections/articles_section'
import { Section as Section10 } from './Section10'
import { AdvantagesSection } from './sections/advantages_section/advantages_section'
// import { CertsSection } from './sections/certs_section'
import { GoodsSlider } from './GoodsSlider'
import { Page } from 'App/Layout'
import { BlogPostsSlider } from './sections/blog_posts_section'
import { MainSlider } from './MainSlider'
// import { LandingSection } from './sections/landing_section'
// import { ShopSection } from './sections/shop_section'
// import LazyLoad from 'react-lazyload'

// import { Section as Section11 } from './Section11'
// import { Section as Section12 } from './Section12'

import goodsJson from './goods.json'
import catalogsJson from './catalogs.json'
import { IGood } from '@monorepo/application_module'
import { useCompany } from '@monorepo/company_module'
import { useThemeVariant } from 'App/Layout/theme'
import { ILaximoCatalog, useCatalogsList } from '@monorepo/laximo_module'

export const Home = () => {
  // const { data: items = [] } = useGoodsCarousel('search')
  const { data: recentNews } = useBlogPostsList('news', {
    select: (data) => data.items,
  })
  const { data: recentPosts } = useBlogPostsList('articles', {
    select: (data) => data.items,
  })
  const { data: slides } = useSlider('main')

  // const { data: catalogs = [], isFetched, isError } = useCatalogsList()
  const items = goodsJson as unknown as IGood[]
  const catalogs = catalogsJson as unknown as ILaximoCatalog[]
  const { isDark } = useThemeVariant()

  const { data: company } = useCompany()

  return (
    <div
      id="et-content"
      className="content et-clearfix"
      // style={{ backgroundColor: '#8A8D8F', color: 'var(--text-color1)' }}
    >
      {/* <div className="post-3230 page type-page status-publish hentry"> */}
      <main className="page-content et-clearfix">
        {/* <LazyLoad offset={100} height={481}> */}
        {slides && (slides.length ?? 0) > 0 && (
          <Page.Section $variant={isDark ? 'dark' : 'grey'} $condensed>
            <MainSlider items={slides} />
          </Page.Section>
        )}

        <Page.Section $variant={isDark ? 'grey' : 'secondary'}>
          <GoodsSlider items={items as IGood[]} rows={1} rowHeight="350px" />
        </Page.Section>

        {/* <SlideShow /> */}
        <Catalogs
          $variant="primary"
          $condensed
          // title="Каталоги online"
          // description="Общие каталоги"
        />

        {/* <Section5 /> */}
        {/* <Section2 /> */}
        {company?.name && (
          <AdvantagesSection
            subTitle={`Преимущества ${company.name} –`}
            title="профессиональный ремонт авто"
          />
        )}
        <Page.Section
          title="Каталоги подбора автозапчастей"
          description="Оригинальные детали"
          $variant="secondary"
        >
          {!!catalogs.length && <Section4 items={catalogs} />}
        </Page.Section>

        {/* <CertsSection /> */}
        {/* <LandingSection /> */}
        {/* <ShopSection /> */}
        {/* <Section1 /> */}

        {/* {!demo && <Section6 />} */}
        {/* {!demo && (
          <Section7
            title="Отзывы клиентов"
            // description="Check our latest reviews from customers"
          />
        )} */}
        {/* <Section8
            title="Получите скидку - 20%"
            subTitle="на установку деталей в dviga.pro"
            url="/"
          /> */}

        <Page.Section title="Наши новости">
          <BlogPostsSlider
            items={recentNews ?? []}
            height="300px"
            imageHeight="66%"
          />
        </Page.Section>
        <Page.Section title="Статьи">
          <BlogPostsSlider
            items={recentPosts ?? []}
            height="300px"
            imageHeight="66%"
          />
        </Page.Section>
        {/* <Page.Section title="Это интересно"></Page.Section> */}
        {/* <Articles
            title="Новости от dviga.pro"
            // description="Check our latest news and events"
          /> */}

        <Section10 />
        {/* <Section11 /> */}
        {/* <Section12 /> */}
      </main>
      {/* </div> */}
    </div>
  )
}
