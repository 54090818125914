import React from 'react'
import { FormattedDate, FormattedNumber, FormattedTime } from 'react-intl'
import { Spinner, Table } from 'react-bootstrap'

import { IOffer } from '@monorepo/application_module'
import { useInfo } from '../hooks/useInfo'

export interface CalculationDetailsProps {
  title?: React.ReactNode
  offer: IOffer
}

export const CalculationDetails: React.FC<CalculationDetailsProps> = ({
  title,
  offer,
}) => {
  const { data: calculation, isFetching } = useInfo({ itemKey: offer.itemKey })

  if (isFetching) {
    return (
      <>
        <Spinner animation="border" variant="primary" /> Загрузка данных...
      </>
    )
  }

  return (
    <>
      {title && <h5>{title}:</h5>}
      {calculation?.priceLevel && (
        <div>
          <strong>Уровень цен:</strong> {calculation?.priceLevel.name}
        </div>
      )}
      {calculation?.priceCreatedAt && (
        <div>
          <strong>Прайс от:</strong>{' '}
          <FormattedDate value={calculation?.priceCreatedAt} />{' '}
          <FormattedTime value={calculation?.priceCreatedAt} />
        </div>
      )}
      {calculation?.purchasePrice && (
        <div>
          <strong>Закупочная цена:</strong>{' '}
          <FormattedNumber value={calculation?.purchasePrice} format="RUB" />
        </div>
      )}
      {calculation?.retailPrice && (
        <div>
          <strong>Розничная цена:</strong>{' '}
          <FormattedNumber value={calculation?.retailPrice} format="RUB" />
        </div>
      )}
      {calculation?.wholesalePrice && (
        <div>
          <strong>Оптовая цена:</strong>{' '}
          <FormattedNumber value={calculation?.wholesalePrice} format="RUB" />
        </div>
      )}
      {calculation?.fixedPrice && (
        <div>
          <strong>ФЦ:</strong>{' '}
          <FormattedNumber value={calculation?.fixedPrice} format="RUB" />
        </div>
      )}
      {calculation?.minimumRetailPrice && (
        <div>
          <strong>МРЦ:</strong>{' '}
          <FormattedNumber
            value={calculation?.minimumRetailPrice}
            format="RUB"
          />
        </div>
      )}
      <Table bordered striped className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Операция</th>
            <th>Вход</th>
            <th>Выход</th>
          </tr>
        </thead>
        <tbody>
          {calculation?.operations?.map((item, idx) => (
            <tr key={idx}>
              <th>{idx + 1}</th>
              <td>
                <strong>{item.name}</strong>
                {item.description && (
                  <div>
                    <em>{item.description}</em>
                  </div>
                )}
              </td>
              <td>
                {item.price && (
                  <FormattedNumber value={item.price} format="RUB" />
                )}
              </td>
              <td>
                {item.nextPrice && (
                  <FormattedNumber value={item.nextPrice} format="RUB" />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
