import styled from 'styled-components'

import { TR } from './tr'

export const Table = styled.div.attrs((props) => ({
  role: 'table',
}))`
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */
  border: ${(props) => props.theme.baseTableBorder};
  border-radius: ${(props) => props.theme.baseRadius};

  ${TR} {
    border-bottom: ${(props) => props.theme.baseTableBorder};
    &:last-child {
      border-bottom: none;
    }
  }
`
