import React, { useContext } from 'react'
import { ThemeProvider as BaseThemeProvider } from 'styled-components'
import invariant from 'invariant'

import { ThemeContext } from './theme.context'
import { configure, darkThemeVariants } from './theme-config'
import { ThemeVariant } from './enums'
import { useLocalStorage } from './useLocalStorage.hook'

// Extract our Sass variables into a JS object
// eslint-disable-next-line import/no-webpack-loader-syntax
const baseTheme = require('sass-extract-loader?{"plugins": ["sass-extract-js"]}!../../Resources/styles/config.scss')

type ThemeProviderProps = {
  defaultThemeVariant: ThemeVariant
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  defaultThemeVariant,
  children,
}) => {
  const { value: themeVariant, setValue: setThemeVariant } = useLocalStorage(
    'theme',
    defaultThemeVariant
  )
  // const [themeVariant, setThemeVariant] =
  //   useState<ThemeVariant>(defaultThemeVariant)
  const nextThemeVariant = (): void => {
    const variants = Object.values(ThemeVariant)
    const nextIdx = variants.indexOf(themeVariant) + 1
    const nextVariant = variants[nextIdx >= variants.length ? 0 : nextIdx]

    setThemeVariant(nextVariant)
  }

  const isDark = darkThemeVariants.includes(themeVariant)

  const contextValue = {
    themeVariant,
    setThemeVariant,
    nextThemeVariant,
    isDark,
  }
  const theme = configure[themeVariant](baseTheme)

  return (
    <ThemeContext.Provider value={contextValue}>
      <BaseThemeProvider theme={{ ...baseTheme, ...theme }}>
        {children}
      </BaseThemeProvider>
    </ThemeContext.Provider>
  )
}

export function useThemeVariant() {
  const context = useContext(ThemeContext)

  invariant(context, 'Add [ThemeProvider] to your app!')

  return context
}
