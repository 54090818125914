import React, { useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { Button, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import { ICartItem, UpdateCartItemProvider } from '@monorepo/cart_module'
import {
  formatDeliveryTime,
  getSearchUrl,
} from '@monorepo/application_module/helpers'
import { OrderItemValidatorProvider } from '@monorepo/order_module'
import { LightBox } from '@monorepo/application_module/components/light_box'
import { NumberInput, CheckBox, AutoSave } from '../../../Components/FormFields'
import { ImagePlaceholder as BaseImagePlaceholder } from '../../../Components/ImagePlaceholder'
import { TableRow } from './components/base_table_row'
import { ErrorsIndicator, ErrorsList } from './components'
import {
  AmountCell,
  DeliveryTimeCell,
  InfoCell,
  PriceCell,
  ProductCell,
  ReferenceCell,
  TotalPriceCell,
} from './components/base_table_cells'

type CartItemPropsType = React.HtmlHTMLAttributes<HTMLDivElement> & {
  item: ICartItem
  offersLoading: boolean
  onRemove(): void
}

const FieldContainer = styled(Col)`
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dotted #999;
    min-height: 40px;
    text-align: right !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    &:last-child {
      border-bottom: none;
    }

    &:before {
      content: attr(data-title) ':';
      display: block;
      width: calc(40% - 6px);
      flex-shrink: 0;
      font-weight: 500;
      text-align: start;
      white-space: nowrap;
    }
  }
`

const Image = styled.img<{ size: number | string }>`
  height: ${(props) => parseInt(`${props.size}`)}px!important;
  width: ${(props) => parseInt(`${props.size}`)}px;
  object-fit: contain;
`

const ImagePlaceholder = styled(BaseImagePlaceholder)<{
  size: number | string
}>`
  height: ${(props) => parseInt(`${props.size}`)}px!important;
  width: ${(props) => parseInt(`${props.size}`)}px;
  object-fit: contain;
`

const RemoveButton = styled(Button)`
  color: ${(props) => props.theme.danger};
  right: 0;
  top: 0;
`

export const CartItemRow: React.FC<CartItemPropsType> = ({
  item,
  onRemove,
  className,
}) => {
  // const { isLoading } = useCartItemOffer(item)

  const [images, thumbs] = useMemo(() => {
    const images = [item.images, item.good.images].find((images) =>
      Boolean(images?.length)
    )
    const thumbs = [item.thumbs, item.good.thumbs].find((images) =>
      Boolean(images?.length)
    )
    return [images, thumbs]
  }, [item.good.images, item.good.thumbs, item.images, item.thumbs])

  return (
    <OrderItemValidatorProvider item={item}>
      <UpdateCartItemProvider item={item}>
        {({ values, isValid }) => (
          <TableRow
            className={className}
            // after={<Controls item={item} onRemove={onRemove} />}
            before={
              <Field
                id={`select-${item.id}`}
                name="isSelected"
                type="checkbox"
                className="m-1 position-absolute"
                style={{ left: 0, top: 0, zIndex: 999 }}
                // as={ItemSelector}
                as={CheckBox}
              />
            }
            after={
              <RemoveButton
                aria-label="Удалить"
                variant="link"
                size="sm"
                className="position-absolute"
                onClick={onRemove}
              >
                <FontAwesomeIcon icon={faTimes} />
              </RemoveButton>
            }
          >
            <ProductCell className="px-4 d-flex">
              <div className="d-flex flex-grow-0 flex-shrink-0 pr-2">
                {images && thumbs ? (
                  <LightBox images={images}>
                    <Image
                      className="img-thumbnail"
                      size="50"
                      src={thumbs[0]}
                      height="50"
                      width="50"
                      alt=""
                    />
                  </LightBox>
                ) : (
                  <ImagePlaceholder
                    className="img-thumbnail"
                    size="50"
                    height={50}
                    width={50}
                  />
                )}
              </div>
              <div className="d-flex flex-column">
                <Link to={getSearchUrl(item.good)}>
                  {item.good.brand.name} «{item.number}»
                </Link>
                <small style={{ lineHeight: 1.5 }}>{item.name}</small>
              </div>
            </ProductCell>

            <InfoCell>
              <FieldContainer data-title="Информация">
                <ErrorsIndicator />
                <AutoSave debounceMs={500}>
                  {({ isSubmitting }) => (
                    <FontAwesomeIcon
                      icon={faSave}
                      size="lg"
                      className={isSubmitting ? 'text-primary' : 'text-warning'}
                    />
                  )}
                </AutoSave>
              </FieldContainer>
            </InfoCell>

            <ReferenceCell>
              <FieldContainer data-title="Референс">
                <Field name="reference" className="form-control" />
              </FieldContainer>
            </ReferenceCell>

            <DeliveryTimeCell className="text-center">
              <FieldContainer data-title="Срок">
                <span className="form-control border-0 bg-transparent text-nowrap">
                  {formatDeliveryTime(values.deliveryTime)}
                </span>
              </FieldContainer>
            </DeliveryTimeCell>

            <PriceCell>
              <FieldContainer className="text-right px-0" data-title="Цена">
                <span className="form-control border-0 bg-transparent text-nowrap">
                  <FormattedNumber value={values.price} format="RUB" />
                </span>
              </FieldContainer>
            </PriceCell>

            <AmountCell>
              <FieldContainer data-title="Кол-во">
                <Field
                  name="amount"
                  as={NumberInput}
                  className="form-control"
                  min={
                    item.offer?.multiplicityAmount ?? item.offer?.minAmount ?? 0
                  }
                  max={
                    item.amount <= (item.offer?.amount ?? 0)
                      ? item.offer?.amount
                      : undefined
                  }
                  step={isValid ? item.offer?.multiplicityAmount ?? 1 : 1}
                />
              </FieldContainer>
            </AmountCell>

            <TotalPriceCell>
              <FieldContainer className="text-right px-3" data-title="Сумма">
                <span className="form-control border-0 bg-transparent text-nowrap">
                  <FormattedNumber
                    value={values.amount * values.price}
                    format="RUB"
                  />
                </span>
              </FieldContainer>
            </TotalPriceCell>
            <ErrorsList item={item} className="px-3 px-md-1" />
          </TableRow>
        )}
      </UpdateCartItemProvider>
    </OrderItemValidatorProvider>
  )
}
