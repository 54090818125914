import React from 'react'
import { useField } from 'formik'

import { IContract } from '@monorepo/contract_module'
// import Callout from '../../../../../../components/callout'
// import { Currency } from '../../../../../../app/i18n'
import { BaseFormGroup, SelectField } from 'App/Components/FormFields'

type ContractFieldProps = {
  name: string
  contracts?: IContract[]
}

export const ContractField: React.FC<ContractFieldProps> = ({
  name,
  contracts = [],
}) => {
  const isHidden = (contracts?.length ?? 0) < 2

  return isHidden ? null : (
    <>
      <BaseFormGroup name={name} label="Договор">
        <SelectField
          items={contracts}
          keyProp="id"
          valueProp={(contract) => `
        ${contract.name} [${
            contract.balance < 0 ? 'Задолженность' : 'Баланс'
          } ${contract.balance} ${contract.currency}]
        `}
          name={name}
          className="form-control"
        />
      </BaseFormGroup>
      {/* {currentContract && (
        <Callout type={currentContract?.balance < 0 ? 'danger' : 'info'}>
          {currentContract?.balance < 0 ? 'Задолженность' : 'Баланс'}{' '}
          <FormattedNumber value={currentContract?.balance} format="RUB" />
        </Callout>
      )} */}
      {/* {(currentContract?.isOverdueCreditLimit ||
        currentContract?.isOverdueDebt) && (
        <Callout type="danger">
          <ul>
            {currentContract?.isOverdueCreditLimit && (
              <li>
                <FormattedMessage id="app.user.debt.max" />
              </li>
            )}
            {currentContract?.isOverdueDebt && (
              <li>
                <FormattedMessage
                  id="app.user.debt.overdue"
                  values={{ overdueDebt: currentContract?.overdueDebt }}
                />
              </li>
            )}
          </ul>
        </Callout>
      )} */}
    </>
  )
}
