import { orderBy } from 'lodash/fp'

import { IOffer } from '@monorepo/application_module'
import { IVendor } from '@monorepo/vendor_module'

export enum sortOffersOrder {
  price = 'price',
  deliveryTime = 'deliveryTime',
}

export interface SortOffersByProps {
  sortProp: sortOffersOrder
  priorityVendor?: IVendor
}

export function sortOffersBy({ sortProp, priorityVendor }: SortOffersByProps) {
  return orderBy<IOffer>(
    [
      'isStock',
      (offer) => (offer.vendor.id === priorityVendor?.id ? 1 : 0),
      sortProp,
      ...Object.values(sortOffersOrder).filter(
        (current) => current !== sortProp
      ),
      'amount',
    ],
    ['desc', 'desc', 'asc', 'asc', 'desc']
  )
}
