import styled from 'styled-components'
import Base from './schedule_table'

export * from './schedule_table'

export const ScheduleTable = styled(Base)`
  color: inherit;

  tr {
    &[data-holiday='holiday'] {
      color: ${(props) => props.theme.danger};
    }

    td:nth-child(1) {
      width: 1%;
      white-space: nowrap;
    }

    td:nth-child(1) {
      text-align: right;
    }
  }
`
