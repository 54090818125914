import React from 'react'

import { useMenu } from '@monorepo/menu_module'
import { Navigation } from './Navigation'

type BottomSectionProps = React.HTMLAttributes<HTMLElement> & {
  //
}

const BottomSection: React.FC<BottomSectionProps> = (props) => {
  const { data: mainMenu = [] } = useMenu('main')

  return (
    <section {...props}>
      <div className="container et-clearfix">
        <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
          <div className="vc_column-inner">
            <div className="d-flex justify-content-end align-items-center flex-grow-1">
              {/* <CompositButton outline wide to="/catalogs">
                <FontAwesomeIcon icon={faBars} size="lg" />
                &nbsp; Каталоги
              </CompositButton> */}
              <Navigation menu={mainMenu} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BottomSection
