import React from 'react'
import { useHistory, useLocation } from 'react-router'

import { IGood, IOffer } from '@monorepo/application_module'
import { OffersControl, useAddToCart } from '@monorepo/search_module'
import { useCurrentUser } from '@monorepo/user_module'
import { Control } from './control'
import { OfferItem } from './offer.item'

type OffersTableProps = {
  items: IOffer[]
  good: IGood
}

export const OffersTable: React.FC<OffersTableProps> = ({ items, good }) => {
  const { addToCart } = useAddToCart()
  const history = useHistory()
  const location = useLocation()
  const { data: user, isFetching } = useCurrentUser()
  const checkPermissions = () => {
    if (user) {
      return true
    }

    history.push('/login', {
      referrer: location,
    })
    return false
  }

  return (
    <OffersControl
      items={items}
      renderControl={(props) => <Control {...props} />}
    >
      {({ items }) => (
        <div>
          {items.map((offer) => (
            <OfferItem
              key={offer.itemKey}
              item={offer}
              className="border-bottom"
              isReady={!isFetching}
              onAddToCart={() => checkPermissions() && addToCart(offer)}
            />
          ))}
        </div>
      )}
    </OffersControl>
  )
}
