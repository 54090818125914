import React from 'react'
import styled from 'styled-components'
import BaseBadge, { BadgeProps as BaseBadgeProps } from 'react-bootstrap/Badge'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger, {
  OverlayTriggerProps,
} from 'react-bootstrap/OverlayTrigger'
import Color from 'color'

type BadgeProps = { color: string }

const Badge = styled(BaseBadge)<BadgeProps>`
  background-color: ${(props) => props.color};
  padding: 8px 8px;
  color: ${(props) =>
    Color(props.color).luminosity() <= 0.5
      ? props.theme.light
      : props.theme.primary};
  border: 1px solid ${(props) => Color(props.color).darken(0.25).string()};
  box-shadow: ${(props) => props.theme.baseBoxShadow};
`

export type StatusBadgeProps = BadgeProps &
  BaseBadgeProps & {
    trigger?: OverlayTriggerProps
    tooltip: React.ReactNode
  }

function renderPopover(content: React.ReactNode, title?: React.ReactNode) {
  return (
    <Popover id="popover-basic">
      {title && <Popover.Title>{title}</Popover.Title>}
      <Popover.Content>{content}</Popover.Content>
    </Popover>
  )
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  tooltip,
  trigger,
  ...props
}) => (
  <OverlayTrigger
    delay={{ show: 250, hide: 400 }}
    {...trigger}
    overlay={renderPopover(tooltip, props.children)}
  >
    <Badge {...props} />
  </OverlayTrigger>
)
