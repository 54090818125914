import React from 'react'
import cn from 'classnames'

import { Link } from 'App/Components'

import { ICatalog } from '@monorepo/catalogs_parts'

export type CatalogItemProps = React.HTMLProps<HTMLDivElement> & {
  item: ICatalog & { icon?: string }
  dark?: boolean
}

export const CatalogItem: React.FC<CatalogItemProps> = ({
  item,
  // className,
  dark,
  ...props
}) => (
  <div
    {...props}
    data-parallax="false"
    data-move="false"
    data-coordinatex="0"
    data-coordinatey="0"
    data-speed="10"
  >
    <div className="et-icon-box-inner et-item-inner~ et-clearfix">
      <Link to="/">
        <div className="et-icon large">
          <span className={cn('el-icon enova', item.icon)} />
        </div>
        <div className="et-icon-content et-clearfix">
          <h6 className="et-icon-box-title">{item.name}</h6>
        </div>
      </Link>
    </div>
  </div>
)
