import React, { useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import { IContractOperation, useTransactions } from '@monorepo/contract_module'
import { TR } from 'App/Components/table'
import {
  OperationCell,
  TypeCell,
  StartBallanceCell,
  IncDebtCell,
  DecDebtCell,
  EndBallanceCell,
} from './cells'
import { TransactionsTable } from './transactions_table'

type OperationRowProps = {
  item: IContractOperation
}

export const OperationRow: React.FC<OperationRowProps> = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggle = () => setIsExpanded((prev) => !prev)
  const { data: transaction } = useTransactions(
    { operationId: item.id },
    { enabled: isExpanded }
  )

  return (
    <TR className="align-items-center">
      <OperationCell data-title="Операция" className="text-left">
        <Button variant="link" onClick={toggle} className="p-0">
          <FontAwesomeIcon
            icon={faCaretDown}
            rotation={isExpanded ? 180 : undefined}
          />
          &nbsp;
          {item.number} от <FormattedDate value={item.createdAt} />
        </Button>
      </OperationCell>
      <TypeCell data-title="Тип" className="text-center">
        <Badge variant="primary">{item.type}</Badge>
      </TypeCell>
      <StartBallanceCell data-title="Начальный остаток" className="text-center">
        <FormattedNumber value={item.initialBalance} format="RUB" />
      </StartBallanceCell>
      <IncDebtCell data-title="Увеличение долга" className="text-center">
        <FormattedNumber value={item.increaseDebt} format="RUB" />
      </IncDebtCell>
      <DecDebtCell data-title="Уменьшение долга" className="text-center">
        <FormattedNumber value={item.decreaseDebt} format="RUB" />
      </DecDebtCell>
      <EndBallanceCell data-title="Конечный остаток" className="text-center">
        <FormattedNumber value={item.finalBalance} format="RUB" />
      </EndBallanceCell>
      {isExpanded && transaction && (
        <Col xs="12" className="px-3 my-1 py-1 border-top~ border-secondary~">
          <Row>
            <Col xs="12" className="lead">
              {transaction.name} на сумму{' '}
              <FormattedNumber value={transaction.sum} format="RUB" />
            </Col>
            {transaction.items && (
              <Col xs="12">
                <TransactionsTable items={transaction.items} />
              </Col>
            )}
          </Row>
        </Col>
      )}
    </TR>
  )
}
