import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedNumber, useIntl } from 'react-intl'
import { faReceipt } from '@fortawesome/free-solid-svg-icons'

import { useOrdersList, OrdersFilter } from '@monorepo/order_module'
import { getSearchUrl } from '@monorepo/application_module/helpers'
import { Callout } from 'App/Components/Callout'
import { StatusBadge } from 'App/Components'
import { Table, THead } from 'App/Components/table'
import { TableRowNew, TableSubRow } from './components/base_table_row'
import {
  AmountCell,
  InformationCell,
  NameCell,
  OrderNumberCell,
  PriceCell,
  ReferenceCell,
  StatusCell,
  TotalPrceCell,
} from './components/cells'

type OrdersTableProps = {
  filter: OrdersFilter
}

export const OrdersTable: React.FC<OrdersTableProps> = ({ filter }) => {
  const { data: items = [], isFetching, isFetched } = useOrdersList(filter)
  const { formatDate } = useIntl()

  if (isFetching) {
    return (
      <Callout loading>
        <span className="lead">Загрузка заказов...</span>
      </Callout>
    )
  }

  if (isFetched && items.length === 0) {
    return (
      <Callout icon={faReceipt}>
        <span className="lead">Заказов не найдено</span>
      </Callout>
    )
  }

  return (
    <Table>
      <THead className="d-none d-md-block">
        <TableRowNew>
          <OrderNumberCell data-title="Заказ">Заказ</OrderNumberCell>
          <NameCell>Наименование</NameCell>
          <AmountCell className="text-center">Кол-во</AmountCell>
          <InformationCell className="text-center">Инфо</InformationCell>
          <PriceCell className="text-right">Цена</PriceCell>
          <TotalPrceCell className="text-right">Сумма</TotalPrceCell>
          <StatusCell className="text-center">Статус</StatusCell>
        </TableRowNew>
      </THead>
      {items?.map((order, idx) => (
        <TableRowNew key={idx}>
          <OrderNumberCell data-title="Заказ">
            <div className="d-flex flex-column">
              {order.id ? (
                <Link to={`/order/details/${order.id}`}>
                  {`${order.number} от ${formatDate(order.createdAt)}`}
                </Link>
              ) : (
                `${order.number ?? 'Б/Н'} от ${formatDate(order.createdAt)}`
              )}{' '}
              {order.customerComment && <em>{order.customerComment}</em>}
            </div>
          </OrderNumberCell>
          <>
            {order.items.map((item, idx) => (
              <TableSubRow key={idx}>
                <NameCell data-title="Наименование">
                  <Link to={getSearchUrl(item.good)}>
                    {item.good.brand.name} {item.good.number.toUpperCase()}
                  </Link>
                </NameCell>
                <AmountCell data-title="Кол-во" className="text-center">
                  {item.amount}
                </AmountCell>
                <InformationCell data-title="Инфо">&nbsp;</InformationCell>
                <PriceCell data-title="Цена" className="text-right">
                  <FormattedNumber value={item.price} format="RUB" />
                </PriceCell>
                <TotalPrceCell data-title="Сумма" className="text-right">
                  <FormattedNumber
                    value={item.price * item.amount}
                    format="RUB"
                  />
                </TotalPrceCell>
                <StatusCell data-title="Статус" className="text-center">
                  <StatusBadge
                    className="text-wrap"
                    color={item.status.color}
                    tooltip={item.status.description}
                  >
                    {item.status.name}
                  </StatusBadge>
                  {/* <StatusBadge className="text-wrap" color={item.status.color}>
                    {item.status.name}
                  </StatusBadge> */}
                </StatusCell>
                {Boolean(item.reference) && (
                  <ReferenceCell data-title="Reference">
                    <em>{item.reference}</em>
                  </ReferenceCell>
                )}
              </TableSubRow>
            ))}
          </>
        </TableRowNew>
      ))}
    </Table>
  )
}
