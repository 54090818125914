import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { Link, useRouteMatch } from 'react-router-dom'

import { IMenuItem } from '@monorepo/menu_module'

type MenuItemProps = IMenuItem & {}

const ListItem = styled.li.attrs(
  (props: { active: boolean; disabled: boolean }) => ({
    active: props.active || false,
    disabled: props.disabled || false,
  })
)`
  a {
    display: block;
    color: ${(props) =>
      props.disabled ? props.theme.grey : props.theme.primary};
    padding: 7px 1.5rem;
    text-decoration: none;
    transition: all 250ms;

    font-weight: ${(props) =>
      props.active ? props.theme.fontSemibold : 'inherit'};

    pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};

    &:hover {
      color: ${(props) => props.theme.light};
      background-color: ${(props) => props.theme.primary};
    }

    @media (min-width: 992px) {
      & {
        ${(props) =>
          props.active && `box-shadow: inset 3px 0 ${props.theme.primary};`}
      }
    }

    @media (max-width: 991.98px) {
      & {
        padding: 14px 20px;

        ${(props) =>
          props.active && `box-shadow: inset 0 -3px ${props.theme.primary};`};
      }
    }
  }

  @media (max-width: 991.98px) {
    a {
      border-right: 1px solid ${(props) => props.theme.secondary};
    }
    &:last-child a {
      border-right: none;
    }
  }
`

export const MenuItem: React.FC<MenuItemProps> = ({
  name,
  external,
  ...props
}) => {
  const disabled = props.disabled || !props.url
  const url = disabled || !props.url ? '' : props.url

  const match =
    useRouteMatch({
      path: url,
    }) && !disabled

  return (
    <ListItem
      active={Boolean(match) && !disabled}
      disabled={disabled}
      className={cn('account-nav__item', {
        'account-nav__item--active': match && !disabled,
      })}
    >
      {external ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      ) : (
        <Link to={url}>{name}</Link>
      )}
    </ListItem>
  )
}
