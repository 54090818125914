import React from 'react'
import cn from 'classnames'
// import {
//   faTruck,
//   faPallet,
//   faPercent,
//   faTools,
//   faMapMarkedAlt,
//   faRecycle,
// } from '@fortawesome/free-solid-svg-icons'

import { htmlParser } from '@monorepo/application_module/helpers'
// import { useOfficesList } from '@monorepo/company_module'
import { useStaticPage } from '@monorepo/static_page_module'
import { Page } from 'App/Layout'
// import { Loader } from 'App/Components'
// import { ItemsList, ItemsType } from './ItemsList'
// import { OfficeList } from './office_list'

// import { Currency, Image } from '@monorepo/components/common'
// import { Page as PageAPI, Company } from '@monorepo/hooks'

type AboutUsProps = {
  //
}

// const items: ItemsType = [
//   {
//     title: 'Удобный график',
//     content: 'Работаем ежедневно без выходных',
//     icon: faTruck,
//   },
//   {
//     title: 'Гарантия',
//     content: (
//       <>
//         Безусловная гарантия один год на все запчасти{' '}
//         <span className="text-patron">Patron</span>,{' '}
//         <span className="font-weight-bold font-italic">Masuma</span>
//       </>
//     ),
//     icon: faPallet,
//   },
//   {
//     title: 'Возврат',
//     content:
//       'Гарантированный возврат товара в течение 14 дней с момента покупки',
//     icon: faPercent,
//   },
//   {
//     title: 'Ассортимент',
//     content: 'Широкий выбор автозапчастей в наличии и под заказ',
//     icon: faTools,
//   },
//   {
//     title: 'Цена',
//     content: 'Предлагаем лучшие цены на качественный товар',
//     icon: faRecycle,
//   },
//   {
//     title: 'Оплата',
//     content: 'Удобные условия оплаты товара',
//     icon: faMapMarkedAlt,
//   },
// ]

export const AboutUs: React.FC<AboutUsProps> = () => {
  // const { page, loading, htmlParser } = PageAPI.useContent('about-us')
  const { data: page, isFetching } = useStaticPage('about-us')
  // const { data: offices } = useOfficesList()

  return (
    <Page title="О нас" bottomSpace>
      <div
        id="post-2184"
        className="post-2184 page type-page status-publish hentry"
      >
        <main className="page-content et-clearfix">
          <div className="vc_row wpb_row vc_row-fluid vc_custom_1559119990390 vc_column-gap-40 vc_row-o-equal-height vc_row-o-content-middle vc_row-flex vc-row-902069">
            <div className="container et-clearfix">
              {page ? (
                <>
                  <div
                    className={cn(
                      'wpb_column',
                      'vc_column_container',
                      'vc_col-sm-12',
                      !page?.image ? 'vc_col-lg-12' : 'vc_col-lg-6',
                      'vc_col-md-12',
                      'vc_col-xs-12',
                      'text-align-none',
                      'vc-column-327822'
                    )}
                  >
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <Page.Title subTitle={page?.description}>
                          {page?.title}
                        </Page.Title>
                        <div
                          className="wpb_text_column wpb_content_element "
                          data-animation-delay={0}
                        >
                          <div className="wpb_wrapper text-justify">
                            {htmlParser(page.content)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {page?.image && (
                    <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12 vc_col-xs-12 text-align-none vc_col-has-fill vc-column-369855">
                      <div className="vc_column-inner vc_custom_1559121990447">
                        <div className="wpb_wrapper">
                          <span className="et-gap et-clearfix et-gap-112034 hide1280" />
                          <div
                            id="et-image-177465"
                            className="et-image alignnone curtain-left active"
                          >
                            <Image
                              src={page.image}
                              alt=""
                              style={{ animationDelay: '1000ms' }}
                            />
                            <div className="curtain" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                </>
              ) : (
                isFetching && (
                  <div className="w-100 d-flex justify-content-center">
                    Loading...
                    {/* <Loader /> */}
                  </div>
                )
              )}
            </div>
          </div>
          <div className="vc_row wpb_row vc_row-fluid vc_column-gap-24 vc-row-3841">
            <div className="container et-clearfix">
              <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper">
                    {/* <ItemsList items={items} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {offices && <OfficeList offices={offices} title="Наши магазины" />} */}
        </main>
      </div>
    </Page>
  )
}
