import React from 'react'
import { Clusterer, Placemark } from 'react-yandex-maps'
import Container from 'react-bootstrap/Container'
import { camelCase, min, max } from 'lodash'

import { IAddress } from '@monorepo/application_module'
import { YandexMap } from '@monorepo/components/common'
import { useOfficesList } from '@monorepo/company_module'
import { renderFullAddress } from '@monorepo/application_module/helpers'
import { Page } from 'App/Layout'
import { OfficeList } from './OfficeList'
// import { PickPointPlacemarks } from 'App/Components'

// import { Delivery, Company } from '@monorepo/hooks'
// import { IAddress } from '@monorepo/interfaces'
// import { renderFullAddress } from '@monorepo/core/helpers'

export const createIcon = (...items: string[]) =>
  `islands#${camelCase(items.map((item) => item.toLowerCase()).join('-'))}`

type ContactsProps = {
  //
}

function getCenter(addresses?: IAddress[]) {
  const points = addresses?.map((item) => item.mapPlace.center)
  if (typeof points === 'undefined') return undefined
  const lons = points.map((point) => point.lon)
  const lats = points.map((point) => point.lat)
  const lon = (max(lons)! + min(lons)!) / 2
  const lat = (max(lats)! + min(lats)!) / 2
  return [lon, lat]
}

export const Contacts: React.FC<ContactsProps> = () => {
  const { data: offices } = useOfficesList()
  // const { data: pickPoints } = Delivery.usePickPointsList()

  const addresses = offices?.map((item) => item.address)

  return (
    <Page title="Контакты">
      {/* <div
       id="post-2190"
      className="post-2190 page type-page status-publish hentry"
      > */}
      <main className="page-content et-clearfix">
        {/* <div className="mb-5 vc_row wpb_row vc_row-fluid vc_custom_1559750933065 vc_column-gap-40 vc_row-o-equal-height vc_row-o-content-middle vc_row-flex vc-row-322862"> */}
        <Container className="et-clearfix">
          <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none vc-column-712819">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <Page.Title subTitle="Свяжитесь с нами">
                  Наши контакты
                </Page.Title>
              </div>
            </div>
          </div>
        </Container>
        {offices && offices?.length > 0 && (
          <Container fluid className="et-clearfix">
            <YandexMap
              defaultState={{
                center: getCenter(addresses)!,
                zoom: 10,
              }}
            >
              {addresses && (
                <Clusterer
                  options={{
                    preset: createIcon('inverted', 'red', 'cluster', 'icons'),
                    groupByCoordinates: false,
                  }}
                  // {...props}
                >
                  {addresses.map((item, idx) =>
                    item.mapPlace.center.lon && item.mapPlace.center.lat ? (
                      <Placemark
                        key={idx}
                        geometry={[
                          item.mapPlace.center.lon,
                          item.mapPlace.center.lat,
                        ]}
                        properties={{
                          hintContent: renderFullAddress(item),
                        }}
                        options={{
                          preset: true
                            ? createIcon('red', 'stretchy', 'icon')
                            : createIcon('blue', 'delivery', 'icon'),
                        }}
                        // onClick={onSelect}
                      />
                    ) : null
                  )}
                </Clusterer>
              )}
            </YandexMap>
          </Container>
        )}
        {/* </div> */}

        <div className="vc_row wpb_row vc_row-fluid vc_column-gap-48 vc-row-155286">
          <Container className="et-clearfix">
            <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none vc-column-712819">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper">
                  <div className="m-5 et-separator et-clearfix animate-false solid left horizontal et-separator-375960">
                    <div className="line" />
                  </div>
                  {/* {offices && offices?.length > 0 && (
                      <OfficeList items={offices} />
                    )} */}
                </div>
              </div>
            </div>
          </Container>
          {offices && offices?.length > 0 && <OfficeList items={offices} />}
        </div>
      </main>
      {/* </div> */}
    </Page>
  )
}
