import React from 'react'
import Popover from 'react-bootstrap/Popover'
import Table from 'react-bootstrap/Table'

import { ILaximoUnitDetail } from '@monorepo/laximo_module'

export function renderPopover(item: ILaximoUnitDetail) {
  return (
    <Popover id={`info-popover-${item.oem}`}>
      <Popover.Title as="h3">{item.oem}</Popover.Title>
      <Popover.Content>
        <Table borderless size="sm">
          <tbody>
            {item.attributes?.map((item) => (
              <tr key={item.key}>
                <td>{item.name ?? 'Наименование не указано'}</td>
                <td className="text-break">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Popover.Content>
    </Popover>
  )
}
