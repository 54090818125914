import React, { useMemo } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Page } from 'App/Layout'
import { CabinetLayout } from 'App/PageComponents/Cabinet/CabinetLayout'
import { CarsList, Control } from 'App/PageComponents/Garage'

import { useCarsList } from '@monorepo/garage_module'
import { Callout } from 'App/Components/Callout'
import { useIntl } from 'react-intl'
import { faCar } from '@fortawesome/free-solid-svg-icons'

type GarageProps = {
  //
}

const Garage: React.FC<GarageProps> = () => {
  const { data: cars = [], isFetching, isFetched } = useCarsList()

  const { formatMessage } = useIntl()

  const title = useMemo(
    () =>
      formatMessage(
        {
          id: 'garage.cart.amount',
          defaultMessage:
            '{amount, plural, =0{Гараж пуст} one {В гараже # автомобиль} few {В гараже # автомобиля} many {В гараже # автомобилей} other {В гараже # автомобилей}}',
        },
        { amount: cars.length }
      ),
    [cars.length, formatMessage]
  )

  return (
    <Page title={title} authenticated>
      <Container>
        <CabinetLayout>
          <Row>
            <Col xs="12">
              <Callout
                loading={isFetching}
                icon={faCar}
                className="d-flex justify-content-between align-items-center"
              >
                <span className="lead">
                  {isFetching ? 'Загрузка списка автомобилей...' : title}
                </span>
                {isFetched && <Control />}
              </Callout>
            </Col>
            <Col xs="12">
              <CarsList items={cars} />
            </Col>
          </Row>
        </CabinetLayout>
      </Container>
    </Page>
  )
}

export default Garage
