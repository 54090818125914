import { useQuery } from 'react-query'
// import { useParams } from 'react-router'

import { fetchStaticPageBySlug } from '../api'

const QUERY_KEY = 'static-page'

export function useStaticPage(slug: string) {
  return useQuery([QUERY_KEY, { slug }], () => fetchStaticPageBySlug(slug), {
    staleTime: 15 * 60 * 1000,
    retry: 1,
    enabled: Boolean(slug),
  })
}
