import styled from 'styled-components'
import { DateBadge as Base } from './date_badge'

export * from './date_badge'

export const DateBadge = styled(Base)`
  border-radius: ${(props) => props.theme.baseRadius};
  padding: 12px 14px;
  text-align: center;
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 15;
  background-color: ${(props) => props.theme.colorLightBlack};
  opacity: 0.9;

  & > * {
    display: block;
    color: ${(props) => props.theme.colorWhite};
    font-weight: 700;
    line-height: 1.2em;

    &:first-child {
      font-size: 18px;
    }
    &:last-child {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
`
