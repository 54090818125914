import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { getCatalogPageUrl, ILaximoCatalog } from '@monorepo/laximo_module'
import { getBrandsLetter } from './components/utils/getBrandsLetter'
import { FirstLetter } from './components/first_letter'
import { ListItem } from './components/list_item'
import { List } from './components/list'

export interface CatalogsListProps {
  items: ILaximoCatalog[]
}

export const CatalogsList: React.FC<CatalogsListProps> = ({ items }) => {
  const brandLetters = useMemo(() => getBrandsLetter(items), [items])

  return (
    <div className="catalogs-wrapper cars">
      <div className="guayaquil-catalogs-wrapper">
        <h2>Поиск автомобиля по марке</h2>

        <div className="border-top" />

        <List>
          {items?.map((item) => (
            <ListItem key={item.code}>
              <FirstLetter className="letter">
                {brandLetters[item.brand]}
              </FirstLetter>
              <Link to={getCatalogPageUrl(item)}>{item.brand}</Link>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  )
}
