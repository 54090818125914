import styled from 'styled-components'

import { GoodItem as Base } from './good_item'
export * from './good_item'

export const GoodItem = styled(Base)`
  display: flex;
  flex-direction: column;

  & > a {
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(props) => props.theme.colorBlack};
  }

  & > p {
    line-height: ${(props) => props.theme.baseLineHeight};
    flex: auto;
    overflow: hidden;
    font-size: 0.75em;
  }
`
