import styled from 'styled-components'

import Base, { LogoProps } from './logo'

export const Logo = styled(Base)<LogoProps>`
  & {
    height: ${(props) =>
      typeof props.$height === 'string' ? props.$height : `${props.$height}px`};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;

    & > svg {
      transition: 350ms ease;
      max-width: 100%;
      max-height: 100%;
      color: ${(props) => props.theme.logoColor};
    }

    & > span {
      font-size: 0.75rem;
      letter-spacing: 0.75rem;
      padding-left: 0.75rem;
      margin-top: 0.25rem;
      text-transform: uppercase;
      color: ${(props) => props.theme.colorYellow};
      text-shadow: 0px 0px 1px ${(props) => props.theme.colorOrange};
    }

    &:hover {
      text-decoration: none;
      & > svg {
        filter: opacity(0.95);
      }
    }
  }
`
