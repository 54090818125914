import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { IOrder, OrderId } from '../interfaces'

import { useAuthenticatation } from '@monorepo/auth_module'
import { fetchOrderById } from '../api'

const QUERY_KEY = 'orders'

export function useOrder<TData = IOrder>(
  orderId: OrderId,
  options?: UseQueryOptions<IOrder | null, AxiosError, TData>
) {
  const { isAuthenticated } = useAuthenticatation()

  return useQuery(
    [QUERY_KEY, { orderId }] as any,
    () => fetchOrderById(orderId),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      ...options,
      enabled: isAuthenticated && (options?.enabled ?? true),
    }
  )
}
