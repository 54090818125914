import React, { useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import { filter, flow, sumBy } from 'lodash/fp'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { IOffer } from '@monorepo/application_module'
import { ICartItem } from '@monorepo/cart_module'
import { IPaymentMethod } from '@monorepo/payment_module'
import { TR } from '../../../Components/table'

type CartItemFooterProps = {
  items: ICartItem[]
  paymentMethod?: IPaymentMethod
}

const getSelectedAmount = flow(
  filter<IOffer>('isSelected'),
  sumBy<IOffer>('amount')
)
const getSelectedPrice = flow(
  filter<IOffer>('isSelected'),
  sumBy<IOffer>((item) => item.amount * item.price)
)

export const CartItemFooter: React.FC<CartItemFooterProps> = ({
  items,
  paymentMethod,
}) => {
  const [seletedAmount, selectedPrice] = useMemo(
    () => [getSelectedAmount(items), getSelectedPrice(items)],
    [items]
  )
  return (
    <TR>
      <Col xs="12" className="d-flex flex-column align-items-end px-3">
        <span>
          <FormattedMessage
            id="cart.selectedItems"
            defaultMessage={`
                {amount, plural,
                  =0 {Ничего не выбрано}
                  one {Выбран # товар}
                  few {Выбрано # товара}
                  many {Выбрано # товаров}
                  other {Выбрано # товаров}
                }
              `}
            values={{ amount: seletedAmount }}
          />
        </span>
        {selectedPrice > 0 && (
          <>
            <span>
              На сумму: <FormattedNumber value={selectedPrice} format="RUB" />
            </span>
            {paymentMethod?.commission && (
              <span>Комиссия: {paymentMethod?.commission}%</span>
            )}
            <span className="border-top mt-2 pt-2 lead">
              Всего к оплате:{' '}
              <FormattedNumber value={selectedPrice} format="RUB" />
            </span>
          </>
        )}
      </Col>
    </TR>
  )
}
