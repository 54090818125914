import React from 'react'
import { useLocation, useParams } from 'react-router'
import Container from 'react-bootstrap/Container'
import { faReceipt } from '@fortawesome/free-solid-svg-icons'

import { PaymentProvider } from '@monorepo/payment_module'
import { IOrder, useOrder } from '@monorepo/order_module'
import { Page } from 'App/Layout'
import { Callout } from 'App/Components/Callout'

type PayOrderProps = {
  //
}

const PayOrder: React.FC<PayOrderProps> = () => {
  const { state } = useLocation<
    { order?: IOrder; successUrl?: string } | undefined
  >()
  const { id: orderId } = useParams<{ id: string }>()

  const { data: order, isFetched } = useOrder(orderId, {
    initialData: state?.order,
  })

  return (
    <Page authenticated>
      <Container>
        {order ? (
          <PaymentProvider order={order}>
            {({ isPaid }) => (
              <Callout icon={faReceipt} loading={!isPaid}>
                {isPaid ? 'Заказ оплачен' : 'Формирование платежа...'}
              </Callout>
            )}
          </PaymentProvider>
        ) : (
          <Callout
            icon={faReceipt}
            loading={!isFetched}
            variant={isFetched ? 'danger' : undefined}
          >
            {isFetched ? 'Заказ не найден' : 'Поиск заказа'}
          </Callout>
        )}
      </Container>
    </Page>
  )
}

export default PayOrder
