import { number, object, string, SchemaOf } from 'yup'

import { UpdateCarDTO } from '../dto'

export const updateCarSchema: SchemaOf<UpdateCarDTO> = object({
  id: string().nullable().default(null),
  mark: string().label('Марка автомобиля').min(3).max(100).required(),
  model: string().label('Модель автомобиля').min(3).max(100).required(),
  modification: string()
    .label('Модификация')
    // .min(3)
    .max(100)
    .default(''),
  productionYear: number()
    .label('Год выпуска')
    .integer()
    .positive()
    .min(1900)
    .max(new Date().getFullYear())
    .required(),
  vin: string().vin().label('VIN код(Frame)').default(''),
  // .vinNumber()
  engineModel: string().label('Модель двигателя').min(0).max(50).default(''),
  engineSize: string().label('Объём двигателя'), //.integer().positive(),
  enginePower: string().label('Мощность двигателя'), //.integer().positive(),
  description: string()
    .label('Дополнительная информация')
    .max(1000)
    .default(''),
})
