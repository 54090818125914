import React, { useEffect, useMemo } from 'react'
import { filter } from 'lodash/fp'
import Container from 'react-bootstrap/Container'
import { useIntl } from 'react-intl'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import { ICartItem, useCartItems } from '@monorepo/cart_module'
import { CreateOrderProvider } from '@monorepo/order_module'
import { Page } from 'App/Layout'
import { CartItemsTable, PlaceOrder } from 'App/PageComponents/Cart'
import { Callout } from 'App/Components/Callout'

type CartProps = {
  //
}

const Cart: React.FC<CartProps> = () => {
  const { data: cartItems = [], isFetching, refetch } = useCartItems()
  const { data: selectedCartItems } = useCartItems({
    select: filter<ICartItem>('isSelected'),
  })

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { formatMessage } = useIntl()
  const pageTitle = useMemo(
    () =>
      formatMessage(
        {
          id: 'page.cart.title',
          defaultMessage:
            '{amount, plural, =0 {Корзина пуста} one {В корзине # товар} few {В корзине # товара} many {В корзине # товаров} other {В корзине # товаров}}',
        },
        { amount: cartItems.length ?? 0 }
      ),
    [cartItems.length, formatMessage]
  )

  return (
    <Page title={isFetching ? 'Загрузка корзины...' : pageTitle} authenticated>
      <Container>
        <Callout loading={isFetching} icon={faShoppingCart}>
          <span className="lead">
            {isFetching ? 'Загрузка содержимого корзины...' : pageTitle}
          </span>
        </Callout>
        {cartItems.length > 0 && (
          <CreateOrderProvider items={selectedCartItems ?? []}>
            <CartItemsTable items={cartItems} />
            <div className="mt-5">
              <h3>Оформить заказ</h3>
              <hr />
              <PlaceOrder />
            </div>
          </CreateOrderProvider>
        )}
      </Container>
    </Page>
  )
}

export default Cart
