import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { ICompany, initialState } from 'App/data/company'

const QUERY_KEY = 'company'

export function useCompany<TData = ICompany>(
  options?: UseQueryOptions<ICompany, AxiosError, TData>
) {
  return useQuery([QUERY_KEY] as any, () => initialState, {
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
    ...options,
  })
}
