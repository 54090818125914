import styled from 'styled-components'

export const CategoryItem = styled.li<{ $current?: boolean }>`
  & a {
    color: ${(props) =>
      props.$current ? props.theme.danger : props.theme.primary};
    font-weight: ${(props) =>
      props.$current ? props.theme.fontSemibold : props.theme.fontNormal};
  }
`
