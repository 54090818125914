import { ICartItem } from '@monorepo/cart_module'
import { useMutation, useQueryClient } from 'react-query'

import { createOrder } from '../api'
import { IOrder } from '../interfaces'

const QUERY_KEY = 'cart'

export function useCreateOrder() {
  const queryClient = useQueryClient()

  return useMutation(createOrder, {
    onSuccess: async (_newOrder: IOrder, variables) => {
      const ids = variables.items?.map((item) => item.id) ?? []

      queryClient.setQueryData<ICartItem[]>(QUERY_KEY, (prev = []) =>
        prev.filter((item) => !ids.includes(item.id))
      )
    },
  })
}
