import React from 'react'
import Container from 'react-bootstrap/Container'

import { Page } from 'App/Layout'
import { Registration as RegistrationSection } from 'App/PageComponents/Auth'
import { Card } from 'App/PageComponents/Auth/window'

const Registration: React.FC = () => {
  return (
    <Page authenticated={false} className="m-t-35">
      <Container>
        <Card>
          <Card.Body>
            <Card.Title className="lead mb-5">Регистрация на сайте</Card.Title>
            <RegistrationSection />
          </Card.Body>
        </Card>
      </Container>
    </Page>
  )
}

export default Registration
