import React from 'react'
import { truncate } from 'lodash/fp'

import { getPostUrl, IBlogPost } from '@monorepo/blog_post_module'
import { Link } from 'App/Components'
import { Card, CardProps, OverlayEnum } from 'App/ui/card'
import { DateBadge } from './date_badge'

export type PostItemProps = CardProps & {
  item: IBlogPost
  height: string | number
  imageHeight: string | number
}

export const PostItem: React.FC<PostItemProps> = ({
  item,
  height,
  imageHeight,
  ...props
}) => {
  const getText = truncate({ length: 100 })

  return (
    <Card
      image={item.postThumb}
      href={getPostUrl(item)}
      title={`Подробно о «${item.title}»`}
      height={height}
      imageHeight={imageHeight}
      overlay={OverlayEnum.FADEZOOMEXTREME}
      badge={<DateBadge value={item.createdAt} />}
      {...props}
    >
      <article>
        <Link to={getPostUrl(item)} title={`Подробно о «${item.title}»`}>
          {getText(item.title)}
        </Link>
      </article>
    </Card>
  )
}
