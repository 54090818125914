import BaseTable from 'react-bootstrap/Table'
import styled from 'styled-components'

import backgroundImage from 'App/Resources/images/layout/header-bg.png'

export const Table = styled(BaseTable)`
  box-shadow: ${(props) => props.theme.baseBoxShadow};

  & > thead {
    background-color: ${(props) => props.theme.primary};
    background: ${(props) => props.theme.primary} url(${backgroundImage});

    & > tr > th {
      background-color: transparent;
      color: ${(props) => props.theme.light};
    }
  }
`
