import React from 'react'

import { UpdatePasswordProvider } from '@monorepo/user_module'
import { UpdatePasswordForm } from './update_password.form'

export const AuthSetting: React.FC = () => (
  <div className="body p-0 p-md-4">
    <h6>Параметры авторизации</h6>
    <UpdatePasswordProvider>
      <UpdatePasswordForm />
    </UpdatePasswordProvider>
  </div>
)
