import React from 'react'
import cn from 'classnames'

import { Sidebar } from './Sidebar'

type BlogLayoutProps = {
  className?: string
}

export const BlogLayout: React.FC<BlogLayoutProps> = ({
  children,
  className = '',
}) => (
  <div className="container et-clearfix">
    <div className={cn(className, 'layout-content', 'et-clearfix')}>
      {children}
    </div>
    <div className="blog-sidebar layout-sidebar et-clearfix">
      <Sidebar />
    </div>
  </div>
)
