import React, { useMemo } from 'react'
import qs from 'qs'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Field, Form } from 'formik'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faKey } from '@fortawesome/free-solid-svg-icons'

import { ForgotPasswordProvider } from '@monorepo/auth_module'
import { BaseFormGroup } from 'App/Components/FormFields'
import { CompositButton } from 'App/Components'
// import { useToasts } from 'App/modules/ToastModule'

type ForgotPasswordSectionProps = {
  //
}

const REQUESTED_KEY = 'isRequested'

export const ForgotPasswordSection: React.FC<ForgotPasswordSectionProps> =
  () => {
    // const { addToast } = useToasts()
    const history = useHistory()
    const { search, pathname, hash } = useLocation()

    // const [isRequested, setIsRequested] = useState(initialState)

    const isRequested = useMemo(() => {
      const query = qs.parse(search, { ignoreQueryPrefix: true })
      return Boolean(query[REQUESTED_KEY])
    }, [search])

    const successHandler = () => {
      toast.success(
        'Инструкция по восстановлению пароля выслана на указанный адрес Email'
      )

      const nextSearch = {
        ...qs.parse(search, { ignoreQueryPrefix: true }),
        [REQUESTED_KEY]: true,
      }

      history.push({
        pathname,
        search: qs.stringify(nextSearch, { addQueryPrefix: true }),
        hash,
      })
    }

    return (
      <>
        <p>
          {isRequested
            ? 'Инструкция по восстановлению пароля выслана на указанный адрес Email'
            : 'Для получения дальнейших инструкций для восстановления пароля, укажите ваш адрес email'}
        </p>
        <ForgotPasswordProvider
          onSuccess={successHandler}
          onError={(error) => {
            toast.error(
              <ul>
                {error.errors.map((error, idx) => (
                  <li key={idx}>{error.message}</li>
                ))}
              </ul>
            )
          }}
        >
          {({ isSubmitting, isValid }) => (
            <Form
              className="form-auth-small ng-untouched ng-pristine ng-valid"
              noValidate
            >
              <BaseFormGroup name="email">
                <Field
                  className="form-control"
                  placeholder="Email"
                  type="email"
                  name="email"
                />
              </BaseFormGroup>
              <div className="d-flex flex-column align-items-center">
                <CompositButton
                  type="submit"
                  className="mt-3"
                  disabled={!isValid || isSubmitting || isRequested}
                  block
                >
                  Восстановить пароль{' '}
                  <FontAwesomeIcon
                    icon={isSubmitting ? faCircleNotch : faKey}
                    spin={isSubmitting}
                    fixedWidth
                  />
                </CompositButton>
                <div className="mt-3">
                  <span className="helper-text">
                    Вспомнили пароль? <Link to="/login">Войти</Link>
                  </span>
                </div>
              </div>
            </Form>
          )}
        </ForgotPasswordProvider>
      </>
    )
  }
