import React from 'react'
import Col from 'react-bootstrap/Col'
import { FormattedNumber } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons'

import { IOffer } from '@monorepo/application_module'
import { useCurrentUser } from '@monorepo/user_module'
import { timeFormatter } from '@monorepo/application_module/helpers/timeFormatter'
import {
  CompositButton,
  OfficeInfo,
  RefundInfo,
  VendorDeliveryInfo,
} from 'App/Components'

import { TableRow } from './table.row'
import { OfficeName } from './components/office_name'
import { PriceCalculatorInfo } from '@monorepo/price_calculation_moudle'

type OfferItemProps = React.HTMLAttributes<HTMLDivElement> & {
  item: IOffer
  isReady?: boolean
  onAddToCart?(): void
}

export const OfferItem: React.FC<OfferItemProps> = ({
  item,
  onAddToCart,
  isReady = true,
  ...props
}) => {
  const { data: user } = useCurrentUser()

  return (
    <TableRow {...props}>
      <>
        <OfficeInfo item={item} />
        <RefundInfo item={item} />
        <VendorDeliveryInfo item={item} />
      </>
      <>
        <Col
          xs="8"
          className="d-flex align-items-center justify-content-center"
        >
          {item.isStock ? (
            <OfficeName item={item} />
          ) : (
            <span>{timeFormatter(item.deliveryTime)}</span>
          )}
        </Col>
        <Col
          xs="4"
          className="d-flex justify-content-center align-items-center"
        >
          <span className="text-nowrap">{`${item.amountString} шт.`}</span>
        </Col>
      </>
      {user?.isAdmin ? (
        <PriceCalculatorInfo offer={item} variant="link" className="text-reset">
          <FormattedNumber value={item.price} format="RUB" />
        </PriceCalculatorInfo>
      ) : (
        <FormattedNumber value={item.price} format="RUB" />
      )}
      <CompositButton onClick={onAddToCart} disabled={!isReady}>
        <FontAwesomeIcon icon={faShoppingBasket} fixedWidth />
      </CompositButton>
    </TableRow>
  )
}
