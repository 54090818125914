import parse from 'html-react-parser'
// import { get, has } from 'lodash'
import { Element } from 'domhandler/lib/node'

import renderers from './renderers'

function replace(domNode: any) {
  if (
    domNode instanceof Element &&
    domNode.type === 'tag' &&
    typeof (renderers as Record<string, any>)[String(domNode.name)] !==
      'undefined'
    //  &&   has(renderers, domNode.name)
  ) {
    // const renderer = get(renderers, domNode.name)
    const renderer = (renderers as Record<string, any>)[domNode.name]
    return renderer(domNode)
  }
}

export const parserOptions = {
  replace,
  trim: true,
}

export default (content: any) => parse(content, parserOptions)
