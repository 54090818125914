import { IRoute } from './interface'

import Balance from 'App/View/Cabinet/Balance'
import Garage from 'App/View/Cabinet/Garage'
import Orders from 'App/View/Cabinet/Orders'
import Profile from 'App/View/Cabinet/Profile'
import Dashboard from 'App/View/Cabinet/Dashboard'
// const Inbox: React.FC = React.lazy<React.FC>(() => import('../../screens/App/Inbox'))

export default (): IRoute[] => [
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/cabinet`,
    component: Dashboard,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/balance`,
    component: Balance,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/garage`,
    component: Garage,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/orders`,
    component: Orders,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/profile`,
    component: Profile,
  },
]
