import { useAuthenticatation } from '@monorepo/auth_module'
import React, { useEffect } from 'react'
import { Redirect } from 'react-router'

type LogoutProps = {
  //
}

const Logout: React.FC<LogoutProps> = () => {
  const { logout, isAuthenticated } = useAuthenticatation()

  useEffect(() => {
    isAuthenticated && logout()
  }, [isAuthenticated, logout])

  return <Redirect to="/" />
}

export default Logout
