import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons'

import { useSuggestions } from '@monorepo/search_module'
import { getSearchUrl } from '@monorepo/application_module/helpers'
import { getVehiclesListPageUrl } from '@monorepo/laximo_module'
import { BrandItem } from './BrandItem'
import { Redirect } from 'react-router'
import { Callout } from 'App/Components/Callout'

type BrandsListProps = {
  query: string
}

const Wrapper = styled.ul`
  list-style-type: none;
  border: 2px solid ${(props) => props.theme.primary};
  border-left-width: 16px;
  border-radius: ${(props) => props.theme.baseRadius};
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */
`

const vinRe = /^[A-HJ-NPR-Z\d]{9}[A-HJ-NPR-Z\d]{2}[A-Z\d]{6}$/i

export const BrandsList: React.FC<BrandsListProps> = ({ query }) => {
  const {
    data: brands,
    isFetching,
    isFetched,
  } = useSuggestions({
    query,
    useFTS: false,
    strict: true,
  })

  const isVin = vinRe.test(query)

  if (brands?.length === 1) {
    return <Redirect to={getSearchUrl(brands[0])} />
  }
  if (brands?.length === 0 && isVin) {
    return <Redirect to={getVehiclesListPageUrl({ identString: query })} />
  }

  return (
    <>
      <Callout
        loading={isFetching}
        variant={isFetched && !brands?.length ? 'danger' : undefined}
        icon={faPuzzlePiece}
      >
        {isFetched ? (
          <FormattedMessage
            id="search.brands.amount"
            defaultMessage="Артикул «{article}» {amount, plural, =0 {не найден ни у одного производителя} one {найден у # производителя} other {найден у # производителей}}"
            values={{
              article: query.toUpperCase(),
              amount: brands?.length ?? 0,
            }}
          />
        ) : (
          'Поиск производителей'
        )}
      </Callout>
      {!!brands?.length && (
        <Wrapper id="results">
          {brands?.map((item) => (
            <BrandItem key={item.id} item={item} />
          ))}
        </Wrapper>
      )}
    </>
  )
}
