import { apiClient } from '@monorepo/core'
import { ISlide } from '../interface'

export async function fetchSlidesBySlug(slug: string) {
  const params = { slug }
  const { data } = await apiClient.get<ISlide[]>('/slides', {
    params,
  })

  return data
}
