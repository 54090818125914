import React from 'react'
import { Form } from 'react-bootstrap'
import { Field, Form as FormikForm } from 'formik'

import { AddressField } from '@monorepo/dadata_module'
import { useOfficesList } from '@monorepo/company_module'
import { BaseFormGroup, SelectField } from 'App/Components/FormFields'
import { renderOfficeName } from '../../../helpers/render_office_name'

type UpdateProfileFormProps = {
  //
}

export const UpdateProfileForm: React.FC<UpdateProfileFormProps> = () => {
  const { data: offices } = useOfficesList()

  return (
    <Form as={FormikForm}>
      <div className="row clearfix">
        <div className="col-lg-6 col-md-12">
          <BaseFormGroup name="firstName" label="Имя">
            <Field
              className="form-control"
              placeholder="Имя"
              type="text"
              name="firstName"
            />
          </BaseFormGroup>
          <BaseFormGroup name="middleName" label="Отчество">
            <Field
              className="form-control"
              placeholder="Отчество"
              type="text"
              name="middleName"
            />
          </BaseFormGroup>
          <BaseFormGroup name="lastName" label="Фамилия">
            <Field
              className="form-control"
              placeholder="Фамилия"
              type="text"
              name="lastName"
            />
          </BaseFormGroup>
          {/* <div className="form-group">
                  <div>
                    <label className="fancy-radio">
                      <input
                        name="gender2"
                        type="radio"
                        value="male"
                        onChange={() => {}}
                      />
                      <span>
                        <i></i>Male
                      </span>
                    </label>
                    <label className="fancy-radio">
                      <input
                        name="gender2"
                        type="radio"
                        value="female"
                        onChange={() => {}}
                      />
                      <span>
                        <i></i>Female
                      </span>
                    </label>
                  </div>
                </div> */}
          {/* <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-calendar"></i>
                      </span>
                    </div>
                    <input
                      className="form-control"
                      data-date-autoclose="true"
                      data-provide="datepicker"
                      placeholder="Birthdate"
                    />
                  </div>
                </div> */}
          {/* <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="http://"
                    type="text"
                  />
                </div> */}
        </div>
        <div className="col-lg-6 col-md-12">
          <BaseFormGroup name="office" label="Офис обслуживания">
            <SelectField
              name="office"
              items={offices}
              keyProp="id"
              valueProp={renderOfficeName}
              className="form-control"
            />
          </BaseFormGroup>
          <BaseFormGroup name="address" label="Адрес">
            <Field
              className="form-control"
              placeholder="Адрес"
              type="text"
              name="address"
              as={AddressField}
            />
          </BaseFormGroup>
          <BaseFormGroup name="phone" label="Телефон">
            <Field
              className="form-control"
              placeholder="Телефон"
              type="text"
              name="phone"
              // as={NumberInput}
            />
          </BaseFormGroup>
          {/* <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Address Line 1"
                    type="text"
                  />
                </div> */}
          {/* <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Address Line 2"
                    type="text"
                  />
                </div> */}
          {/* <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="City"
                    type="text"
                  />
                </div> */}
          {/* <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="State/Province"
                    type="text"
                  />
                </div> */}
          {/* <div className="form-group">
                  <select className="form-control">
                    <option value="">-- Select Country --</option>
                    <option value="RU">Russian Federation</option>
                  </select>
                </div> */}
        </div>
      </div>
      <button className="btn btn-primary" type="submit">
        Сохранить
      </button>{' '}
      &nbsp;&nbsp;
      <button className="btn btn-default" type="reset">
        Отмена
      </button>
    </Form>
  )
}
