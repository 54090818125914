import styled from 'styled-components'

import Base from './Footer'

export * from './Footer'

export const Footer = styled(Base)`
  margin-top: 2rem;

  /* background-color: ${(props) => props.theme.colorLightBlack}; */
  /* color: ${(props) => props.theme.colorWhite}; */
`
