import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { fetchPostBySlug } from '../api'

const QUERY_KEY = 'blog-posts'

export function useBlogPost(slug?: string) {
  const params = useParams<{ slug: string }>()

  return useQuery(
    [QUERY_KEY, { slug }],
    () => fetchPostBySlug(slug ?? params.slug),
    { enabled: Boolean(slug ?? params.slug) }
  )
}
