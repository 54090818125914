import styled from 'styled-components'

export const List = styled.ul`
  padding: 0;
  display: inline-block;
  /* margin: -20px 0 0 0; */
  font-size: 18px;
  font-weight: 300;
  list-style: none outside none;
  vertical-align: top;
  column-count: 5;
  width: 100%;
`
