import { useQuery } from 'react-query'

import { fetchSlidesBySlug } from '../api'

export function useSlider(slug: string) {
  return useQuery(['fetchSlidesBySlug', slug], () => fetchSlidesBySlug(slug), {
    // refetchOnWindowFocus: false,
    refetchOnWindowFocus: false,
    staleTime: 15 * 60 * 1000,
    retry: true,
  })
}
