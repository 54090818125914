import React, { useCallback } from 'react'
import debounce from 'lodash/debounce'
import { useFormikContext } from 'formik'

interface AutoSubmitFormProps {
  debounceMs: number
  children?:
    | ((props: { isDirty: boolean; isSubmitting: boolean }) => React.ReactNode)
    | React.ReactNode
}

export const AutoSubmitForm: React.FC<AutoSubmitFormProps> = ({
  debounceMs,
  children = null,
}) => {
  const formik = useFormikContext()
  // const [lastSaved, setLastSaved] = React.useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(async () => {
      setIsSubmitting(true)
      try {
        await formik.submitForm()
        setIsSubmitting(false)
      } catch (error) {
        setIsSubmitting(false)
      }
    }, debounceMs),
    [debounceMs, formik.submitForm]
  )

  React.useEffect(() => {
    formik.dirty && debouncedSubmit()
  }, [debouncedSubmit, formik.dirty, formik.values])

  //   const r = (formik.dirty || isSubmitting)
  //   ? (typeof children === 'function'
  //     ? children({
  //         isDirty: formik.dirty,
  //         isSubmitting,
  //       })
  //     : children)
  //   : null
  // console.log(formik.dirty, isSubmitting, r);

  //   return <>{r}</>

  return formik.dirty || isSubmitting
    ? typeof children === 'function'
      ? children({
          isDirty: formik.dirty,
          isSubmitting,
        })
      : children
    : null

  // return typeof children === 'function'
  //   ? children({
  //       isDirty: formik.dirty,
  //       isSubmitting,
  //     })
  //   : formik.dirty || isSubmitting
  //   ? children
  //   : null

  // return (
  //   <>
  //     {formik.dirty ||
  //       (isSubmitting &&
  //         (typeof children === 'function'
  //           ? (
  //               children as (props: {
  //                 isDirty: boolean
  //                 isSubmitting: boolean
  //               }) => React.ReactNode
  //             )({
  //               isDirty: formik.dirty,
  //               isSubmitting,
  //             })
  //           : children))}
  //   </>
  // )
}
