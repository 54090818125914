import React, { useCallback, useEffect, useState } from 'react'

import { IOrder } from '@monorepo/order_module'
import { IPaymentComponent } from '../interfaces'
import { registerOrder } from './api/register_order.api'

export const Component: React.FC<IPaymentComponent> = ({
  order,
  successUrl,
  errorUrl,
  ...props
}) => {
  const [isStarted, setStarted] = useState<boolean>(false)

  const registerOrderHandler = useCallback(
    async (order: IOrder, successUrl: string, errorUrl: string) => {
      try {
        const baseUrl = window.location.origin

        const { formUrl } = await registerOrder({
          orderId: order.id,
          returnUrl: [baseUrl, successUrl].join(''),
          failUrl: [baseUrl, errorUrl].join(''),
        })

        window.location.replace(formUrl)
      } catch (error) {
        props?.onError((error as any)?.message ?? undefined)
      }
    },
    [props]
  )

  useEffect(() => {
    if (isStarted) {
      return
    }
    setStarted(true)
    registerOrderHandler(order, successUrl, errorUrl)
  }, [errorUrl, isStarted, order, registerOrderHandler, successUrl])

  return null
}
