import React from 'react'
import { Field } from 'formik'

import { BaseFormGroup } from 'App/Components/FormFields'
import { AddressField as BaseAddressField } from '@monorepo/dadata_module'

type AddressFieldProps = {
  name: string
}

export const AddressField: React.FC<AddressFieldProps> = ({
  name,
  children,
}) => (
  <BaseFormGroup name={name} label="Адрес доставки">
    <Field as={BaseAddressField} className="form-control" name={name} />
    {children}
  </BaseFormGroup>
)
