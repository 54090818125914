import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'

import { useCatalogsList } from '@monorepo/laximo_module'
import { Page } from 'App/Layout'
import { CatalogsList } from 'App/PageComponents/Laximo/catalogs_list'
import { CatalogErrorPage } from 'App/PageComponents/Laximo/catalog_error.page'
import { FindForm } from './components/find_form'

type CatalogsListPageProps = {
  //
}

const CatalogsListPage: React.FC<CatalogsListPageProps> = () => {
  const { data: catalogs = [], isFetched, isError } = useCatalogsList()

  if (isFetched && isError) {
    return <CatalogErrorPage />
  }

  return (
    <Page title="Оригинальные каталоги">
      <Container>
        <Col md="6" className="mb-4">
          <FindForm />
        </Col>
        <CatalogsList items={catalogs} />
      </Container>
    </Page>
  )
}

export default CatalogsListPage
