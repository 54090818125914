import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { IOrder } from '../interfaces'

import { useAuthenticatation } from '@monorepo/auth_module'
import { fetchOrdersList } from '../api'
import { OrdersFilter } from '../interfaces'

const QUERY_KEY = 'orders'

export function useOrdersList<TData = IOrder[]>(
  filter: OrdersFilter = {},
  options?: UseQueryOptions<IOrder[], AxiosError, TData>
) {
  const { isAuthenticated } = useAuthenticatation()

  return useQuery([QUERY_KEY, filter] as any, () => fetchOrdersList(filter), {
    refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000,
    ...options,
    enabled: isAuthenticated && (options?.enabled ?? true),
  })
}
