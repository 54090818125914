import React from 'react'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik'
// import { TypeOf } from 'yup'

import { FormError } from '@monorepo/application_module/components/form_error'
import { UpdatePasswordDTO } from '../dto'
import { IUser } from '../interfaces'
import { useCurrentUser } from '../hooks'
// import { FormError } from '@monorepo/application_module/components/form_error'
// import { ICartItem } from '@monorepo/cart_module'
// import { IOrder } from '../../interfaces'
// import { CreateOrderDTO } from '../../dto'
// import { useCreateOrder } from '../../hook'
// import { createOrderSchema } from '../../schema'
// import { useInitialValues } from './initial_values.hook'
// import { useSyncValues } from './sync_values.hook'

type UpdatePasswordProviderProps = {
  // items: ICartItem[]
  onSuccess?(user: IUser): void | Promise<void>
  onError?(error: FormError): void
  children:
    | ((props: FormikContextType<UpdatePasswordDTO>) => React.ReactNode)
    | React.ReactNode
}

export const UpdatePasswordProvider: React.FC<UpdatePasswordProviderProps> = ({
  // items,
  children,
  // onSuccess,
  // onError,
}) => {
  const { data: user } = useCurrentUser()

  const initialValues: UpdatePasswordDTO = {
    email: user?.email ?? '',
    password: '',
    newPassword: '',
    confirmPassword: '',
  }
  // const { mutateAsync } = useCreateOrder()

  // const history = useHistory()

  const onSubmit = async (
    values: UpdatePasswordDTO,
    helpers: FormikHelpers<UpdatePasswordDTO>
  ) => {
    console.log(values)
    // const dto: TypeOf<typeof createOrderSchema> = createOrderSchema.cast(values)

    // try {
    //   const order = await mutateAsync(dto)

    //   helpers.setSubmitting(false)
    //   await onSuccess?.(order)
    //   history.push(`/order/${order.id}/pay`, {
    //     order,
    //     successUrl: `/order/${order.id}/status`,
    //   })
    // } catch (error) {
    //   helpers.setSubmitting(false)
    //   FormError.isFormError(error)
    //     ? onError?.(error)
    //     : onError?.(new FormError())
    //   // onError?.(error)
    // }
  }

  const formik: FormikContextType<UpdatePasswordDTO> =
    useFormik<UpdatePasswordDTO>({
      initialValues,
      onSubmit,
      enableReinitialize: true,
      validateOnMount: true,
      // validationSchema: createOrderSchema,
    })

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function' ? children(formik) : children}
    </FormikProvider>
  )
}

export function useUpdatePasswordForm() {
  return useFormikContext<UpdatePasswordDTO>()
}
