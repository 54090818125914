import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'
import { ResetPasswordDTO } from '../dto'

export async function resetPassword({ token, ...params }: ResetPasswordDTO) {
  try {
    const { data } = await apiClient.post<{ message: string }>(
      `/user/resetting/reset/${token}`,
      params
    )

    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при восстановлении')
  }
}
