import React from 'react'
import {
  ButtonProps,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { OverlayInjectedProps } from 'react-bootstrap/Overlay'
import ConditionalWrap from 'conditional-wrap'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faQuestion } from '@fortawesome/free-solid-svg-icons'

import { IOffer, useComponentVisible } from '@monorepo/application_module'
import { CalculationDetails } from './calculation_details'

type PriceCalculatorInfoProps = ButtonProps & {
  offer: IOffer
}

export const PriceCalculatorInfo: React.FC<PriceCalculatorInfoProps> = ({
  offer,
  children,
  ...props
}) => {
  const { isVisible, show, hide } = useComponentVisible({ isVisible: false })

  const renderTooltip = (props: OverlayInjectedProps) => (
    <Tooltip id="tooltip-price-calc" {...props}>
      Расчет цены
    </Tooltip>
  )

  return (
    <>
      <ConditionalWrap
        condition={!isVisible}
        wrap={(children: any) => (
          <OverlayTrigger overlay={renderTooltip}>{children}</OverlayTrigger>
        )}
      >
        <Button {...props} onClick={show}>
          {children}
        </Button>
      </ConditionalWrap>

      <Modal show={isVisible} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Расчёт цены {/*offer.good.name*/}
            <br />
            {offer.vendor.name && (
              <em>
                <strong>Поставщик:</strong> {offer.vendor.name}
              </em>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CalculationDetails offer={offer} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
