import React from 'react'
import { find } from 'lodash/fp'

import { ICompany, useOfficesList, useCompany } from '@monorepo/company_module'
// import { TopSection } from './top_section'
import { MiddleSection } from './middle_section'
import { BottomSection } from './bottom_section'

type FooterProps = React.HTMLAttributes<HTMLElement> & {
  //
}

const Footer: React.FC<FooterProps> = ({ ...props }) => {
  const { data: mainOffice } = useOfficesList({
    // select: find<ICompany>({ isMain: true }),
    select: (data) => find<ICompany>({ isMain: true })(data) || data?.[0],
  })

  const { data: company } = useCompany()

  return (
    <footer {...props}>
      {/* <TopSection logoImage={logoImage} mainOffice={mainOffice} /> */}
      <MiddleSection mainOffice={mainOffice} />
      <BottomSection
        companyName={company?.name ? `${company.name} АВТОЗАПЧАСТИ` : ''}
      />
    </footer>
  )
}

export default Footer
