import styled from 'styled-components'
import { Base } from './base'

export const Wrapper = styled.span`
  display: flex;
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */

  & > ${Base} {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: ${(props) => props.theme.baseRadius};
      border-bottom-left-radius: ${(props) => props.theme.baseRadius};
      /* border-left-width: 1px; */
    }
    &:last-child {
      border-top-right-radius: ${(props) => props.theme.baseRadius};
      border-bottom-right-radius: ${(props) => props.theme.baseRadius};
      /* border-right-width: 1px; */
    }
  }
`
