import React, { useMemo, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { Link } from 'react-router-dom'
import { take } from 'lodash/fp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { ILaximoVehicle, getVehiclePageUrl } from '@monorepo/laximo_module'
import { Table } from './components/table'
import { getAttributes } from './utils/getAttributes'

interface VehiclesTableProps {
  items: ILaximoVehicle[]
  collapse?: number
}
export const VehiclesTable: React.FC<VehiclesTableProps> = ({
  items,
  collapse: collapseAmount = 3,
}) => {
  const [expanded, setExpanded] = useState(false)
  const toggle = () => setExpanded((prev) => !prev)

  const attributes = useMemo(() => getAttributes(items || []), [items])

  const visibleItems = useMemo(
    () => (expanded ? items : take(collapseAmount, items)),
    [collapseAmount, expanded, items]
  )

  return (
    <section className="mb-5">
      <h5 className="text-uppercase">
        {items[0].brand} {items[0].name}
      </h5>
      <Table responsive hover striped size="sm">
        {/* <caption>
              {items[0].brand} {items[0].name}
            </caption> */}
        <thead>
          <tr>
            <th scope="col">#</th>
            {attributes.map((item) => (
              <th scope="col" key={item.key}>
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {visibleItems.map((item, idx) => (
            <tr key={idx}>
              <th scope="row">{idx + 1}.</th>
              {attributes.map((attr) => (
                <td key={attr.key}>
                  <Link to={getVehiclePageUrl(item)}>
                    {item.attributes?.[attr.key]?.value}
                  </Link>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        {items.length > collapseAmount && (
          <tfoot>
            <tr>
              <td colSpan={(attributes.length ?? 0) + 1}>
                <Button variant="link" onClick={toggle}>
                  {expanded ? 'Спрятать' : 'Больше модификаций'}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    fixedWidth
                    rotation={expanded ? 180 : undefined}
                  />
                </Button>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </section>
  )
}
