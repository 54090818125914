import React from 'react'
import { Link } from 'react-router-dom'

import {
  getUnitPageUrl,
  ILaximoCatalog,
  ILaximoUnit,
} from '@monorepo/laximo_module'
import { DetailsTable } from '../../../../../../../../../../PageComponents/Laximo/components/details_table/details_table'

interface UnitRowProps {
  item: ILaximoUnit
  catalog: ILaximoCatalog
}

export const UnitRow: React.FC<UnitRowProps> = ({ item, catalog }) => {
  const unitUrl = getUnitPageUrl(catalog, item)

  return (
    <tr>
      <td className="p-0" width={179} align="center" valign="top">
        <div className="guayaquil_unit_icons">
          <div className="guayaquil_zoom cboxElement" title={item.name || ''}>
            <div className="zoom-small-icon" />
          </div>
        </div>
        <div>
          {item.imageurl && (
            <img
              alt=""
              className="img_group"
              src={item.imageurl.replace('%size%', String(175))}
            />
          )}
        </div>
        <Link to={unitUrl}>{item.name}</Link>
        <br />
        <Link to={unitUrl}>{item.code}</Link>
      </td>
      <td valign="top" className="p-0">
        {item.details && (
          <DetailsTable items={item.details} catalog={catalog} />
        )}
      </td>
    </tr>
  )
}
