import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams } from 'react-router'

import { useOrder } from '@monorepo/order_module'
import { Page } from 'App/Layout'
import { OrderDetails } from 'App/PageComponents/Order/order_details'

const OrderDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>()
  const { data: order, isFetching, isFetched } = useOrder(params.id)

  return (
    <Page
      title={
        isFetching
          ? 'Поиск заказа...'
          : order
          ? `Заказ «${order.number}»`
          : 'Заказ не найден'
      }
      authenticated
    >
      <Container>
        <Row>
          <Col xs="12">
            <OrderDetails
              item={order}
              isFetching={isFetching}
              isFetched={isFetched}
            />
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

export default OrderDetailsPage
