import React from 'react'
import { Field, useField } from 'formik'

import { IDeliveryMethod } from '@monorepo/delivery_module'
import { BaseFormGroup, SelectField } from 'App/Components/FormFields'
import { AddressField } from './address_field'

type DeliveryFieldProps = {
  name: string
  deliveryMethods?: IDeliveryMethod[]
}

export const DeliveryField: React.FC<DeliveryFieldProps> = ({
  name,
  deliveryMethods,
}) => {
  const addresFieldName = 'deliveryAddress'

  const [{ value: deliveryMethod }] = useField<IDeliveryMethod | undefined>(
    name
  )

  return (
    <>
      <BaseFormGroup name={name} label="Способ доставки">
        <SelectField
          items={deliveryMethods}
          keyProp="id"
          valueProp="name"
          name={name}
          className="form-control"
        />
      </BaseFormGroup>
      {deliveryMethod?.isAddressRequired && (
        <BaseFormGroup name="deliveryCustomerName" label="ФИО получателя">
          <Field
            name="deliveryCustomerName"
            placeholder="Укажите ФИО..."
            className="form-control"
          />
        </BaseFormGroup>
      )}

      {deliveryMethod?.isAddressRequired && (
        <AddressField name={addresFieldName}>
          {/* {!!deliveryAddressError && (
            <HelpBlock>Выберите нужный адрес из списка.</HelpBlock>
          )} */}
        </AddressField>
      )}

      {/* {deliveryMethod?.isAddressRequired &&
        recentAddresses &&
        (recentAddresses?.length ?? 0) > 0 && (
          <>
            <div className="text-center margin-vertical-25">ИЛИ</div>
            <RecentAddressesList
              recentAddresses={recentAddresses}
              className="margin-vertical-25"
              value={deliveryAddress}
              onChange={setDeliveryAddress}
              label="Ваши адреса доставки"
            />
          </>
        )} */}

      {/* {deliveryMethod?.isDeliveryRouteRequired &&
        (isFetching ? (
          <Alert>
            <FontAwesomeIcon
              icon={faCircleNotch}
              spin={isFetching}
              className="margin-right-15"
            />
            Загрузка графика доставки...
          </Alert>
        ) : (
          !!schedules?.length && (
            <DeliveryTimeFormGroup
              name="deliverySchedule"
              schedules={schedules}
            />
          )
        ))} */}
    </>
  )
}
