import { useQuery, UseQueryOptions } from 'react-query'
import intersection from 'lodash/intersection'

import { IUser, useCurrentUser } from '@monorepo/user_module'
import { IMenu } from '../interfaces'
import { fetchMenu } from '../api'

const QUERY_KEY = 'menu'

const filterMenu = (menu: IMenu, user?: IUser): IMenu => {
  return menu.reduce((acc, item) => {
    if (
      !!item.roles?.length &&
      intersection(item.roles, user?.roles ?? []).length === 0
    ) {
      return acc
    }

    return typeof item.menu !== 'undefined'
      ? acc.concat({ ...item, menu: filterMenu(item.menu, user) })
      : acc.concat(item)
  }, [] as IMenu)
}

export function useMenu<TData = IMenu>(
  menuName: string,
  options?: UseQueryOptions<
    Record<string, IMenu>,
    /*AxiosError*/ any,
    TData
  > & {
    select?(menu: IMenu): TData
  }
) {
  const { data: user } = useCurrentUser()

  return useQuery(QUERY_KEY as any, () => fetchMenu(), {
    refetchOnWindowFocus: false,
    ...options,
    select(data: Record<string, IMenu>) {
      // const menu = data[menuName].filter((item) => {
      //   console.log(item, user?.roles)
      //   return (
      //     !item.roles?.length ||
      //     intersection(item.roles, user?.roles ?? []).length > 0
      //   )
      // })
      const menu = filterMenu(data[menuName], user)
      return typeof options?.select === 'function'
        ? options.select(menu)
        : (menu as unknown as TData)
    },
  })
}
