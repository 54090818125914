import * as Catalogs from './catalogs'

export const debug = process.env.NODE_ENV !== 'production'

export const serverURL = process.env.REACT_APP_SERVER_URL?.replace(/\/$/, "") ?? ''
export const imageURL = process.env.REACT_APP_IMAGE_URL?.replace(/\/$/, "") ?? ''
export const sentryDSN = process.env.REACT_APP_SENTRY_DSN

export { default as ilcats } from './ilcats'

export const metrics = {
  yandex: {
    accounts: JSON.parse(process.env.REACT_APP_METRIC_YANDEX || '[]'),
  },
}

export { Catalogs }
