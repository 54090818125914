import React from 'react'
import { FormikErrors } from 'formik'

import { CreateOrderItemDTO } from '../../dto'

export interface IOrderItemValidatorContext {
  isValid: boolean
  isValidating: boolean
  errors: FormikErrors<CreateOrderItemDTO>
}

export const OrderItemValidatorContext =
  React.createContext<IOrderItemValidatorContext | null>(null)
