import React from 'react'
import { Form } from 'react-bootstrap'
import { Field, FieldProps } from 'formik'

import { BaseFormGroup } from 'App/Components/FormFields'

type CommentsFieldProps = {
  name: string
}

export const CommentsField: React.FC<CommentsFieldProps> = ({ name }) => (
  <BaseFormGroup name="customerComment" label="Комментарий к заказу">
    <Field name={name}>
      {({ field }: FieldProps) => (
        <Form.Control
          {...field}
          className="px-2 py-1 form-control"
          as="textarea"
          placeholder="Укажите комментарий к заказу..."
          rows={6}
          custom
        />
      )}
    </Field>
  </BaseFormGroup>
)
