import React from 'react'
import cn from 'classnames'
import {
  YMaps,
  Map,
  Placemark,
  MapProps,
  FullscreenControl,
  GeolocationControl,
  ZoomControl,
  TypeSelector,
  MapStateCenter,
  // YMapsApi,
} from 'react-yandex-maps'
// import { chain } from 'lodash'
// import { isMobile } from 'react-device-detect'

import style from './style.module.scss'

// const useMapSetup = (/*ymap: any*/) =>
//   useCallback((ymap) => {
//     if (!ymap) {
//       return
//     }

//     const disabledBehaviors = chain({
//       scrollZoom: true,
//       drag: isMobile,
//       rightMouseButtonMagnifier: true,
//     })
//       .pickBy()
//       .keys()
//       .value()

//     ymap.behaviors.disable(disabledBehaviors)
//     return (...args: any) => {
//       console.log(args)
//     }
//   }, [])

type YandexMapProps = MapProps & {
  setYmaps?(ymaps: any): void
}

export const YandexMap: React.FC<YandexMapProps> = ({
  height = 450,
  center,
  zoom,
  className,
  style: componentStyle,
  children,
  setYmaps,
  ...props //: passYmapsToParent,
}) => {
  // const [ymaps, setYmaps2] = useState<any>()
  // const [initialized, setInitialized] = useState<boolean>(false)
  // console.log('setYmaps:', setYmaps)

  const defaultState: MapStateCenter = {
    center,
    zoom,
  }
  const mapStyle = { ...componentStyle, height }

  // const loadHandler = useCallback(
  //   (ymaps: YMapsApi) => {
  //     setYmaps?.(ymaps)
  //     // setYmaps2(ymaps)
  //   },
  //   [setYmaps]
  // )

  // const setCenter = (ref: any) => {
  //   if (initialized) {
  //     return
  //   }

  //   const map = ref.getMap()
  //   const bounds = map.geoObjects.getBounds()
  //   if (bounds) {
  //     const result = ymaps.util.bounds.getCenterAndZoom(
  //       bounds,
  //       map.container.getSize()
  //     )

  //     map.setCenter(result.center, result.zoom)

  //     setInitialized(true)
  //   }
  // }
  // https://github.com/gribnoysup/react-yandex-maps/issues/136
  return (
    <div className={cn(style.container, className)} style={mapStyle}>
      <YMaps
        query={{
          lang: 'ru_RU',
          load: 'util.bounds,geoObject.addon.hint',
        }}
      >
        <Map
          defaultState={defaultState}
          height={height}
          width="100%"
          // onLoad={loadHandler}
          instanceRef={ref => { ref && (ref as any).behaviors.disable('scrollZoom'); }}
          {...props}
        >
          <ZoomControl />
          <FullscreenControl />
          <GeolocationControl /*instanceRef={(ref) => ref && setCenter(ref)}*/
          />
          <TypeSelector />
          {children || (
            <Placemark geometry={center} options={{ iconColor: 'red' }} />
          )}
        </Map>
      </YMaps>
    </div>
  )
}
