import React from 'react'
import { FormattedNumber } from 'react-intl'
import styled from 'styled-components'
import truncate from 'lodash/truncate'

import { Card, OverlayEnum } from 'App/ui/card'
import { CompositButton, Link } from 'App/Components'
import { getSearchUrl } from '@monorepo/application_module/helpers'
import { IGood } from '@monorepo/application_module'

export type GoodItemProps = React.HTMLAttributes<HTMLElement> & {
  item: IGood
  height: string | number
}

const PriceBadge = styled(({ value, ...props }) =>
  value ? (
    <span {...props}>
      от <FormattedNumber value={value} format="RUB" />
    </span>
  ) : null
)`
  position: absolute;
  background-color: ${(props) => props.theme.colorOrange};
  // background: linear-gradient(
  //   90deg,
  //   ${(props) => props.theme.colorLightBlack},
  //   ${(props) => props.theme.colorBlack}
  // );
  color: ${(props) => props.theme.colorWhite};
  box-shadow: ${(props) => props.theme.baseBoxShadow};

  // opacity: 0.75;
  transform: rotateZ(45deg);
  right: -75px;
  top: -25px;
  height: 100px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: ${(props) => props.theme.fontSemibold};
  font-size: ${(props) => parseInt(props.theme.baseFontSize) * 1.1}px;
  z-index: 10;
`

export const GoodItem: React.FC<GoodItemProps> = ({
  item,
  height,
  ...props
}) => {
  const searchUrl = getSearchUrl(item)

  return (
    <Card
      image={item.thumbs?.[0]}
      overlay={item.thumbs?.[0] ? OverlayEnum.FADEZOOM : OverlayEnum.FADE}
      href={searchUrl}
      badge={<PriceBadge value={item.offers?.[0]?.price} />}
      height={height}
      // $variant="grey"
      footerClassName="text-center"
      footer={
        <CompositButton to={searchUrl} outline>
          Найти
        </CompositButton>
      }
    >
      <article {...props}>
        <Link to={searchUrl} title={`Найти о ${item.name}`}>
          <strong>{item.brand.name}</strong> {item.number}
        </Link>
        <p>
          {truncate(item.name, {
            length: 80,
            separator: /\s/,
          })}
        </p>
      </article>
    </Card>
  )
}
