import qs from 'qs'

interface ByIdentStringParams {
  identString: string
}

interface BySsdParams {
  ssd: string
  catalogCode: string
}

export function getVehiclesListPageUrl(
  params: ByIdentStringParams | BySsdParams
) {
  return [
    '/catalogs/vehicles',
    qs.stringify(params, { addQueryPrefix: true }),
  ].join('')
}
