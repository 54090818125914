import type { IRoute } from './interface'

import AuthRoutes from './auth'

import AboutUsRoutes from './about-us'
// import AccountRoutes from './account'
import BlogRoutes from './blog'
import CabinetRoutes from './cabinet'
import CartRoutes from './cart'
import ContactsRoutes from './contacts'
// import GoodsRoutes from './goods'
import HomeRoutes from './home'
import OrderRoutes from './order'
import SearchRoutes from './search'
import StaticPagesRoutes from './static'
import LaximoRoutes from './laximo'
import ErrorsRoutes from './errors'
// import OriginalCatalogsRoutes from './original-catalogs'

const routes: IRoute[] = [
  ...AboutUsRoutes(),
  ...AuthRoutes(),
  ...BlogRoutes(),
  // ...AccountRoutes(),
  ...CabinetRoutes(),
  ...CartRoutes(),
  ...ContactsRoutes(),
  // ...GoodsRoutes(),
  ...HomeRoutes(),
  ...OrderRoutes(),
  ...SearchRoutes(),
  ...LaximoRoutes(),
  ...StaticPagesRoutes(),
  // ...OriginalCatalogsRoutes(),
  ...ErrorsRoutes(),
]

export default routes
