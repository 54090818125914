import React from 'react'
import { useParams } from 'react-router'

// import { Blog } from '@monorepo/hooks'
import { useBlogPost } from '@monorepo/blog_post_module'
import { htmlParser } from '@monorepo/application_module/helpers'
import { Page } from 'App/Layout'
import { BlogLayout } from '../components/blog_layout'
// import { Comments } from './Comments'
// import { TagsList } from './TagsList'
import { Navigation } from './Navigation'
import { RelatedPostsCarousel } from '../components/related_posts_carousel'
import { BlogPost } from '../components/post'

type PostProps = {
  //
}

export const Post: React.FC<PostProps> = () => {
  const { slug } = useParams<{ slug: string }>()
  const { data: post } = useBlogPost(slug)

  return (
    <Page
      topSpace
      bottomSpace
      padding={false}
      sidebar="right"
      // className="blog-layout-single"
      title="Блог"
    >
      <BlogLayout className="blog-content">
        <div
          id="single-post-page"
          className="single-post-page social-links-true"
        >
          {post && (
            <BlogPost
              title={post.title}
              category={post.categoryName}
              createdAt={post.createdAt}
              image={post.postImage}
            >
              {!!post.content && htmlParser(post.content)}
            </BlogPost>
          )}

          {post?.categoryType && (
            <RelatedPostsCarousel category={post.categoryType} />
          )}
          <Navigation />
          {/* <Comments /> */}
        </div>
      </BlogLayout>
    </Page>
  )
}
