// import cloudpayments from "./cloudpayments"
import { IPaymentComponent } from './interfaces'
import { Component as sberbank } from './sberbank'
// export * from './base'
// import { Component as tochka } from './tochka'

export const paymentSystems: Record<string, React.FC<IPaymentComponent>> = {
  sberbank,
  // cloudpayments,
  // tochka,
}

export function register(
  name: string,
  paymentSystem: React.FC<IPaymentComponent>
) {
  Object.assign(paymentSystems, { [name]: paymentSystem })
}
