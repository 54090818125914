import React from 'react'
import { useField } from 'formik'

import { IOffer } from '@monorepo/application_module'
import { CompositButton } from 'App/Components'
import { BaseError, BaseErrorProps } from './base_error'

export const AskError: React.FC<BaseErrorProps> = (props) => {
  const [, , { setValue }] = useField(props.fieldName)

  const updateHandler = () => {
    const value = props.item.offer?.[props.fieldName as keyof IOffer]
    if (value) {
      setValue(value, true)
    }
  }

  return (
    <BaseError {...props}>
      <CompositButton size="small" variant="danger" onClick={updateHandler}>
        Согласиться?
      </CompositButton>
    </BaseError>
  )
}
