import { IRoute } from './interface'

import Login from 'App/View/Auth/Login'
import Logout from 'App/View/Auth/Logout'
import ForgotPassword from 'App/View/Auth/ForgotPassword'
import ResetPassword from 'App/View/Auth/ResetPassword'
import Registration from 'App/View/Auth/Registration'

// import Page404 from '../../screens/Auth/Page404'
// import Page403 from '../../screens/Auth/Page403'
// import Page500 from '../../screens/Auth/Page500'
// import Page503 from '../../screens/Auth/Page503'
// import Lockscreen from '../../screens/Auth/Lockscreen'

export default (): IRoute[] => [
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/login`,
    component: Login,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/logout`,
    component: Logout,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/forgotpassword`,
    component: ForgotPassword,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/resetting/reset/:token`,
    component: ResetPassword,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/registration`,
    component: Registration,
  },

  // {
  //   exact: true,
  //   path: `${process.env.PUBLIC_URL}/page404`,
  //   component: Page404,
  // },
  // {
  //   exact: true,
  //   path: `${process.env.PUBLIC_URL}/page403`,
  //   component: Page403,
  // },
  // {
  //   exact: true,
  //   path: `${process.env.PUBLIC_URL}/page500`,
  //   component: Page500,
  // },
  // {
  //   exact: true,
  //   path: `${process.env.PUBLIC_URL}/page503`,
  //   component: Page503,
  // },
  // {
  //   exact: true,
  //   path: `${process.env.PUBLIC_URL}/lockscreen`,
  //   component: Lockscreen,
  // },
]
