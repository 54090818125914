import React from 'react'
import { FormattedDate } from 'react-intl'
// import { LinkContainer } from 'react-router-bootstrap'
import styled from 'styled-components'

// import { getPostUrl, IBlogPost } from '@monorepo/blog_post_module'
// import { htmlParser } from '@monorepo/application_module/helpers'
import { Link } from 'App/Components'

type BlogPostProps = {
  title: string
  category: string
  createdAt: string
  image?: string
  url?: string
}

const ContentWrapper = styled.div`
  margin-top: 48px;

  & > p {
    hyphens: auto;
    text-indent: 2em;
    text-align: justify;
    margin-top: 32px;
  }
  & > * {
    max-width: 100%;
  }
`

export const BlogPost: React.FC<BlogPostProps> = ({
  title,
  category,
  createdAt,
  image,
  url,
  children,
}) => {
  // const postUrl = getPostUrl(item)

  return (
    <article
      className={[
        // 'post-761',
        'post',
        'type-post',
        // 'status-publish',
        // 'format-status',
        // 'has-post-thumbnail',
        // 'hentry',
        // 'category-box',
        // 'tag-enova',
        // 'tag-grey',
        // 'tag-large',
        // 'tag-pink',
        // 'post_format-post-format-status',
        // 'mb-5',
      ].join(' ')}
    >
      <div className="post-inner et-clearfix">
        <div className="post-title-section">
          <div className="post-meta et-clearfix">
            <div className="post-category">{category}</div> от{' '}
            <div className="post-date-inline">
              <FormattedDate
                value={createdAt}
                day="numeric"
                month="long"
                year="numeric"
              />
            </div>
          </div>
          {url ? (
            <Link to={url}>
              <h2 className="post-title entry-title">{title}</h2>
            </Link>
          ) : (
            <h2 className="post-title entry-title">{title}</h2>
          )}
        </div>
        {image && (
          <div className="post-image overlay-hover post-media">
            <div className="image-container">
              <div className="image-preloader" />
              <img
                src={image}
                alt={title}
                // className="attachment-mobimax_1200X556~ size-mobimax_1200X556~ wp-post-image"
              />
            </div>
          </div>
        )}
        <ContentWrapper className="post-body et-clearfix">
          {/* {item.short && htmlParser(item.short)} */}
          {/* <TagsList items={item.tags} /> */}
          {/* <div className="text-right">
            <LinkContainer to={postUrl}>
              <CompositButton>Читать далее</CompositButton>
            </LinkContainer>
          </div> */}
          {children}
        </ContentWrapper>
      </div>
    </article>
  )
}
