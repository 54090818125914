import { CatalogCode } from '../interfaces/catalog.interface'
import { AxiosError } from 'axios'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import { fetchCatalogInfo, IResponse } from '../api/fetch_catalog_info.api'
import { defaultOptions } from './_default_query_options'

const QUERY_KEY = 'laximo_catalog_info'

interface Options<TData> extends UseQueryOptions<IResponse, AxiosError, TData> {
  wizard?: boolean
  ssd?: string
  catalogCode: CatalogCode
}

export function useCatalogInfo<TData = IResponse>({
  wizard = true,
  ssd,
  catalogCode,
  ...options
}: Options<TData>) {
  const params = { catalogCode, ssd, wizard }

  return useQuery(
    [QUERY_KEY, params] as QueryKey,
    fetchCatalogInfo.bind(null, params),
    {
      ...defaultOptions,
      ...options,
    }
  )
}
