// import lazyPage from '@monorepo/core/hoc/lazyPage'
import { Home } from 'App/View/Home'

export default () => [
  {
    path: '/',
    // component: lazyPage(() => import('../../containers/Home')),
    component: Home,
    exact: true,
  },
]
