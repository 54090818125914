import { ICar } from '../interfaces'

export function getCarName({
  id,
  mark,
  model,
  modification,
}: Partial<ICar>): string {
  const carName =
    [mark, model, modification ? `(${modification})` : undefined]
      .filter(Boolean)
      .join(' ')
      .trim() || ''

  return carName.length > 0
    ? carName
    : !id
    ? 'Новый автомобиль'
    : 'Неизвестный автомобиль'
}
