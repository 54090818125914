import React from 'react'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import { IOffer } from '@monorepo/application_module'
import { timeFormatter } from '@monorepo/application_module/helpers'
import { useCurrentUser } from '@monorepo/user_module'
import { StockName } from './components/stock_name'
import { Wrapper } from './components/wrapper'
import { DeliveryInfo } from './components/delivery_info'

type OfficeNameProps = {
  item: IOffer
  showDelivery?: boolean
}

export const OfficeName: React.FC<OfficeNameProps> = ({
  item,
  showDelivery = true,
}) => {
  const { data: user } = useCurrentUser()

  const userOffice = user?.office
  const isUserOffice = userOffice && userOffice?.id === item.vendor.stock?.id

  return (
    <OverlayTrigger
      placement="auto"
      show={!user ? false : undefined}
      overlay={
        <Popover id="office-info-popover">
          <Popover.Content>
            {isUserOffice
              ? 'Товар находится в вашем офисе обслуживания'
              : item.deliveryTime > 0
              ? `Доставка до вашего офиса обслуживания: ${timeFormatter(
                  item.deliveryTime
                )}`
              : 'Доставка до вашего офиса обслуживания'}
          </Popover.Content>
        </Popover>
      }
    >
      <Wrapper>
        <StockName>{item.stockName}</StockName>
        {showDelivery && isUserOffice ? (
          <DeliveryInfo>
            <FontAwesomeIcon icon={faHome} />
          </DeliveryInfo>
        ) : (
          showDelivery &&
          user &&
          item.deliveryTime > 0 && (
            <DeliveryInfo>{`+ ${timeFormatter(
              item.deliveryTime
            )}`}</DeliveryInfo>
          )
        )}
      </Wrapper>
      {/* <span className="position-relative">
        <StockName>{item.stockName}</StockName>
        {user && isUserOffice ? (
          <Badge>
            <FontAwesomeIcon icon={faHome} />
          </Badge>
        ) : (
          user &&
          item.deliveryTime > 0 && (
            <Badge>{`+ ${timeFormatter(item.deliveryTime)}`}</Badge>
          )
        )}
      </span> */}
    </OverlayTrigger>
  )
}
