import qs from 'qs'

import { ILaximoVehicle } from '../interfaces'

export function getVehiclePageUrl(item: ILaximoVehicle) {
  return [
    'vehicle',
    qs.stringify(
      { vehicleId: item.vehicleid, ssd: item.ssd, catalogCode: item.catalog },
      { addQueryPrefix: true }
    ),
  ].join('')
}
