import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { CustomFormats, IntlProvider } from 'react-intl'
import { ToastContainer as BaseToastContainer } from 'react-toastify'
import styled from 'styled-components'

import 'react-toastify/dist/ReactToastify.css'

import { Application } from '@monorepo/application_module'
import routes from './Resources/routes'
// import { ThemeProvider } from './modules/ThemeModule'
// import { SideMenuProvider } from './modules/SideMenuModule'
// import { ToastProvider } from './modules/ToastModule'
import { Layout } from './Layout'

// import './index.scss'
// import '../../node_modules/bootstrap/scss/bootstrap.scss'
// import './assets/assets/scss/main.scss'
// import './assets/assets/scss/color_skins.scss'
// import '../../node_modules/font-awesome/scss/font-awesome.scss'
import './Resources/styles/style.scss'

const formats: CustomFormats = {
  number: {
    RUB: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    USD: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
}

const ToastContainer = styled(BaseToastContainer)`
  z-index: 9999;
`

const App: React.FC = () => (
  <Application>
    <IntlProvider locale="ru" defaultLocale="ru" formats={formats}>
      <ToastContainer />
      <Router>
        <Switch>
          <Route path={routes.map((route) => route.path)}>
            <Layout>
              <Suspense fallback="Loading...">{renderRoutes(routes)}</Suspense>
            </Layout>
          </Route>
        </Switch>
      </Router>
    </IntlProvider>
  </Application>
)

export default App
