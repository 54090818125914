import React from "react"
import isPlainObject from "lodash/isPlainObject"

export function extractErrors(errors: any): React.ReactNodeArray | undefined {
  if (!errors) {
    return
  }
  if (React.isValidElement(errors) || typeof (errors as any) === 'string') {
    return [errors]
  }
  if (Array.isArray(errors)) {
    return errors?.filter(
      (error) => React.isValidElement(error) || typeof error === 'string'
    )
  }
  if (typeof errors === 'object' && isPlainObject(errors)) {
    return Object.values(errors as Record<string, any>).filter(
      (error) => React.isValidElement(error) || typeof error === 'string'
    )
  }
}