import styled from 'styled-components'
import { Link } from 'react-router-dom'

import arrowImage from '../images/arrow-btn-open.png'
import arrowSmallImage from '../images/arrow-btn-open-320.png'

export const SlideButton = styled(Link)`
  background-color: ${(props) => props.theme.colorOrange};
  border-color: ${(props) => props.theme.colorOrange};
  border-radius: ${(props) => props.theme.baseRadius};
  color: ${(props) => props.theme.colorGrey};
  font-weight: ${(props) => props.theme.fontSemibold};

  display: table;
  margin-top: 30px;
  margin-left: 140px;
  padding: 11.5px 58px 11.5px 35px;
  font-size: 18px;
  background-image: url(${arrowImage});
  background-repeat: no-repeat;
  background-position: right 20px center;

  @media (max-width: 767px) {
    margin-top: 5px;
    margin-left: 15px;
    padding: 5px 30px 5px 15px;
    font-size: 14px;
    background-image: url(${arrowSmallImage});
    background-position: 80px;
  }

  &:hover {
    color: ${(props) => props.theme.colorWhite};
    text-decoration: none;
  }
`
