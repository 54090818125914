import { useMutation, useQueryClient } from 'react-query'

import { FormError } from '@monorepo/application_module/components/form_error'
import { ICar } from '../interfaces'
import { removeCar } from '../api'
import { QueryKey } from './constants'

export function useRemoveCar() {
  const queryClient = useQueryClient()

  return useMutation<ICar[], FormError, ICar>(
    QueryKey.carsList,
    (car) => (car.id ? removeCar(car.id) : Promise.resolve(car)),
    {
      onSuccess: (_data, car) => {
        queryClient.setQueryData<ICar[]>(
          QueryKey.carsList,
          (prev) => prev?.filter((currentCar) => currentCar.id !== car.id) ?? []
        )
      },

      onError: (_err, _newCartItem, _context: any) => {
        // queryClient.setQueryData(QUERY_KEY, context.previousCartItems)
      },
    }
  )
}
