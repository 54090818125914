import styled from 'styled-components'

import { TD } from 'App/Components/table'

const BaseCell = styled(TD)`
  @media (max-width: 760px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dotted #999;
    min-height: 40px;
    text-align: right !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    &:last-child {
      border-bottom: none;
    }

    &:before {
      content: attr(data-title) ':';
      display: block;
      width: calc(40% - 6px);
      flex-shrink: 0;
      font-weight: 500;
      text-align: start;
    }
  }
`

export const OperationCell = styled(BaseCell).attrs({ md: 2 })`
  /*  */
`
export const TypeCell = styled(BaseCell).attrs({ md: 2 })`
  /*  */
`
export const StartBallanceCell = styled(BaseCell).attrs({ md: 2 })`
  /*  */
`
export const EndBallanceCell = styled(BaseCell).attrs({ md: 2 })`
  /*  */
`
export const IncDebtCell = styled(BaseCell).attrs({ md: 2 })`
  /*  */
`
export const DecDebtCell = styled(BaseCell).attrs({ md: 2 })`
  /*  */
`
