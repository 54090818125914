import qs from 'qs'
import { curry } from 'lodash/fp'

import { ILaximoCatalog, ILaximoUnit } from '../interfaces'

function getUrl(catalog: ILaximoCatalog, unit: ILaximoUnit) {
  return [
    'unit',
    qs.stringify(
      { catalogCode: catalog.code, unitId: unit.unitid, ssd: unit.ssd },
      { addQueryPrefix: true }
    ),
  ].join('')
}

export const getUnitPageUrl = curry(getUrl)
