import React from 'react'
import cn from 'classnames'

import { Link, ImagePlaceholder } from 'App/Components'
// import { ImagePreloader } from './image_preloader'
import { StyleVariant } from 'App/Resources/mixins/variant.mixin'

export enum OverlayEnum {
  FADE = 'overlay-fade',
  FADEZOOM = 'overlay-fade-zoom',
  FADEZOOMEXTREME = 'overlay-fade-zoom-extreme',
  FALL = 'overlay-fall',
  MOVE = 'overlay-move',
}

export type CardProps = React.HTMLAttributes<HTMLElement> & {
  image?: string
  badge?: React.ReactNode
  footer?: React.ReactNode
  footerClassName?: string
  title?: string
  href?: string
  height?: number | string
  imageHeight?: number | string
  overlay?: boolean | OverlayEnum
  $padding?: false | string
  $border?: false | string
  $rounded?: boolean
  $variant?: StyleVariant
}

export const Card: React.FC<CardProps> = ({
  image,
  badge,
  title,
  href,
  height = 500,
  imageHeight = 300,
  overlay = false,
  children,
  footer,
  footerClassName,
  ...props
}) => {
  imageHeight =
    typeof imageHeight === 'number' ? imageHeight : parseInt(imageHeight)
  height = typeof height === 'number' ? height : parseInt(height)

  return (
    <div
      // {...props}
      className={cn(
        overlay === true ? OverlayEnum.FADE : overlay,
        props.className
      )}
    >
      <header className={cn({ 'overlay-hover': overlay !== false })}>
        {badge}
        {overlay !== false && typeof href !== 'undefined' && (
          <Link className="post-image-overlay" to={href} title={title}>
            <span className="overlay-read-more" />
          </Link>
        )}
        {/* <ImagePreloader /> */}
        <img
          src={image ?? ImagePlaceholder.placeholder}
          alt={title ?? ''}
          height={imageHeight}
        />
      </header>
      <main>{children}</main>
      {footer && <footer className={footerClassName}>{footer}</footer>}
    </div>
  )
}
