import { apiClient } from '@monorepo/core'
import { IPagination } from '@monorepo/pagination_module'
import { IBlogPost } from '../interfaces'

type FetchPostsListParams = {
  page?: number
  perPage?: number
  category?: string
  slug?: string
}

export async function fetchBlogPostsList(params?: FetchPostsListParams) {
  try {
    const { data } = await apiClient.get<IPagination<IBlogPost>>(
      '/v2/blog/posts',
      {
        params,
      }
    )

    return data
  } catch (error) {
    throw error
  }
}
