import React, { useCallback } from 'react'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
} from 'formik'

import { FormError } from '@monorepo/application_module/components/form_error'
import type { ICar } from '../interfaces'
import { UpdateCarDTO } from '../dto'
import { useUpdateCar } from '../hooks'
import { updateCarSchema } from '../schema'

type UpdateCarProviderProps = {
  item: ICar
  onSuccess?(car: ICar): void
  onError?(errors: FormError): void
  children:
    | ((props: FormikContextType<UpdateCarDTO>) => React.ReactNode)
    | React.ReactNode
}

export const UpdateCarProvider: React.FC<UpdateCarProviderProps> = ({
  children,
  item,
  onSuccess,
  onError,
}) => {
  const initialValues: UpdateCarDTO = {
    id: item?.id,
    mark: item?.mark ?? '',
    model: item?.model ?? '',
    modification: item?.modification ?? '',
    description: item?.description ?? '',
    productionYear: item?.productionYear ?? new Date().getFullYear(),
    engineModel: item?.engineModel ?? '',
    enginePower: item?.enginePower ?? '',
    engineSize: item?.engineSize ?? '',
    vin: item?.vin ?? '',
  }
  const { mutateAsync } = useUpdateCar()

  const submitHandler = useCallback(
    async (values: UpdateCarDTO, _helpers: FormikHelpers<UpdateCarDTO>) => {
      try {
        const dto = await mutateAsync(values)

        if (!!dto) {
          onSuccess?.(dto)
        }
      } catch (error) {
        onError?.(FormError.isFormError(error) ? error : new FormError())
      }
    },
    [mutateAsync, onError, onSuccess]
  )

  const formik: FormikContextType<UpdateCarDTO> = useFormik<UpdateCarDTO>({
    initialValues,
    onSubmit: submitHandler,
    enableReinitialize: true,
    validationSchema: updateCarSchema,
  })

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function' ? children(formik) : children}
    </FormikProvider>
  )
}
