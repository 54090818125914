import React from 'react'
import styled from 'styled-components'

import { IGood } from '@monorepo/application_module'
import { GoodCard } from './good_card'
import { OffersTable } from './offers_table'

type TableRowProps = {
  item: IGood
}

const Wrapper = styled.div`
  /* border-bottom: 1px solid ${(props) => props.theme.primary}; */
  /* border-top: none; */
`

export const TableRow: React.FC<TableRowProps> = ({ item }) => {
  if (!item.offers) {
    return null
  }

  return (
    <div className="col-12">
      <Wrapper className="row">
        <div className="col-sm-5">
          <GoodCard item={item} />
        </div>
        <div className="col-sm-7">
          <div className="d-flex flex-column h-100 justify-content-between">
            <OffersTable items={item.offers} good={item} />
          </div>
        </div>
      </Wrapper>
    </div>
  )
}
