import { AxiosError } from 'axios'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import { fetchVehicle, IGetVehicleResponse } from '../api/fetch_vehicle.api'
import { CatalogCode } from '../interfaces'
import { defaultOptions } from './_default_query_options'

const QUERY_KEY = 'laximo_vehicle'

interface Options<TData>
  extends UseQueryOptions<IGetVehicleResponse, AxiosError, TData> {
  readonly catalogCode: CatalogCode
  readonly ssd: string
  readonly vehicleId?: string
  readonly categoryId?: string
}

export function useVehicle<TData = IGetVehicleResponse>({
  catalogCode,
  ssd,
  vehicleId,
  categoryId,
  ...options
}: Options<TData>) {
  const params = { catalogCode, ssd, vehicleId, categoryId }

  return useQuery(
    [QUERY_KEY, params] as QueryKey,
    fetchVehicle.bind(null, params),
    {
      ...defaultOptions,
      enabled: catalogCode.length > 0 && ssd.length > 0,
      ...options,
    }
  )
}
