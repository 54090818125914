import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'
import { CreateOrderDTO } from '../dto'
import { IOrder } from '../interfaces'

export async function createOrder(dto: CreateOrderDTO) {
  try {
    // const data = await new Promise<IOrder>(resolve => {
    //   setTimeout(resolve.bind(null, {id: +new Date()} as IOrder), 2000)
    // })
    const { data } = await apiClient.post<IOrder>('/orders/create', dto)
    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при размещении')
  }
}
