import React, { useMemo, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import qs from 'qs'

import { ILaximoCatalog, ILaximoVehicle } from '@monorepo/laximo_module'
import { Page } from 'App/Layout'
import { createBreadcrumbs } from 'App/PageComponents/Laximo/helpers/createBreadcrumbs'
// import { CatalogErrorPage } from 'App/PageComponents/Laximo/catalog_error.page'
import { Vehicle } from './components/vehicle'
import { QuickGroup } from './components/vehicle/components/quick_group'

type VehiclePageProps = {
  //
}

enum CatalogVariantsEnum {
  QuickGroups = 'QuickGroups',
  Original = 'Original',
}

export interface Metadata {
  catalog: ILaximoCatalog | null
  vehicle: ILaximoVehicle | null
}

const VehiclePage: React.FC<VehiclePageProps> = () => {
  const { code } = useParams<{ code: string }>()
  const location = useLocation()
  const history = useHistory()

  const query = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }) as {
        ssd?: string
        vehicleId?: string
        groupId?: string
        catalogVariant?: CatalogVariantsEnum
        catalogCode?: string
      },
    [location.search]
  )
  const {
    ssd = '',
    catalogVariant = CatalogVariantsEnum.QuickGroups,
    catalogCode,
  } = query

  const changeVariantHandler = (catalogVariant: string | null) => {
    history.push(
      qs.stringify(
        {
          ...query,
          catalogVariant: catalogVariant || CatalogVariantsEnum.Original,
        },
        { addQueryPrefix: true }
      )
    )
  }

  const [{ vehicle, catalog }, setMetadata] = useState<Metadata>({
    catalog: null,
    vehicle: null,
  })

  const updateMetadata = ({ catalog, vehicle }: Metadata) =>
    setMetadata({ catalog, vehicle })

  return (
    <Page
      title={vehicle?.name ?? 'Загрузка...'}
      breadcrumbs={createBreadcrumbs({ catalog })}
    >
      <Container>
        <Tabs
          activeKey={catalogVariant}
          id="uncontrolled-tab-example"
          onSelect={changeVariantHandler}
          mountOnEnter
        >
          <Tab
            eventKey={CatalogVariantsEnum.QuickGroups}
            title="Поиск по группам"
          >
            <QuickGroup
              catalogCode={catalogCode ?? code}
              ssd={ssd}
              onLoad={updateMetadata}
            />
          </Tab>
          <Tab eventKey={CatalogVariantsEnum.Original} title="Поиск по узлам">
            <Vehicle
              catalogCode={catalogCode ?? code}
              ssd={ssd}
              onLoad={updateMetadata}
            />
          </Tab>
        </Tabs>
      </Container>
    </Page>
  )
}

export default VehiclePage
