import Row from 'react-bootstrap/Row'

import styled from 'styled-components'

export const TR = styled(Row).attrs((props) => ({
  role: 'table-row',
  noGutters: true,
}))`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin: 0;
`
