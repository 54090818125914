import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { IOrder, OrdersFilter } from '../interfaces'

export async function fetchOrdersList(filter: OrdersFilter) {
  try {
    const { data } = await apiClient.get<IOrder[]>('/orders', {
      params: filter,
    })
    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при получении заказов')
  }
}
