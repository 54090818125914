import React from 'react'
import cn from 'classnames'
import { LinkContainer } from 'react-router-bootstrap'

import { ILaximoCatalog } from '@monorepo/laximo_module'
// import { Page, PageSectionProps } from 'App/Layout'
import { Item } from './Item'

import style from './style.module.scss'
import { CompositButton } from 'App/Components'

export type SectionProps = {
  items: ILaximoCatalog[]
}

// function isDark(col: number, colAmount: number) {
//   return (col + (Math.floor(col / colAmount) % 2 === 0 ? 0 : -1)) % 2 !== 0
// }

const iconsMap: Record<string, string | false> = {
  abarth: false,
  'alfa romeo': 'AlfaRomeo',
  audi: 'Audi',
  bmw: 'BMW',
  'bmw motorrad': false,
  buick: false,
  cadillac: false,
  chevrolet: 'Chevrolet',
  chrysler: 'Chrysler', //'Chrysler_old'
  citroen: 'Citroen',
  dacia: 'Dacia',
  daewoo: 'Daewoo',
  dodge: 'Dodge',
  fiat: 'Fiat',
  'fiat professional': false,
  ford: 'Ford',
  gmc: false,
  holden: false,
  honda: 'Honda',
  hummer: false,
  hyundai: 'Hyundai',
  infiniti: 'Infiniti',
  isuzu: 'Isuzu',
  jaguar: 'Jaguar',
  jeep: 'Jeep',
  kia: 'Kia',
  lancia: false,
  'land rover': 'LandRover',
  lexus: 'Lexus',
  mazda: 'Mazda',
  'mercedes-benz': 'Mercedes',
  mini: 'Mini',
  mitsubishi: 'Mitsubishi',
  nissan: 'Nissan',
  oldsmobile: false,
  opel: 'Opel',
  peugeot: 'Peugeot',
  // Plymouth: 'Plymouth',
  pontiac: false,
  porsche: 'Porsche',
  ram: false,
  ravon: false,
  'rolls-royce': 'RR',
  renault: 'Renault',
  saab: 'Saab',
  saturn: false,
  seat: 'Seat',
  skoda: 'Skoda',
  smart: 'Smart',
  ssangyong: 'SsangYong',
  subaru: 'Subaru',
  suzuki: 'Suzuki',
  toyota: 'Toyota',
  vauxhall: 'Vauxhall',
  volkswagen: 'VW',
  volvo: false,
  zaz: false,
}

const applyIcon = (item: ILaximoCatalog): ILaximoCatalog => ({
  ...item,
  icon: iconsMap?.[item.name.toLowerCase()] || '',
})

const prepareCatalogs = (items: ILaximoCatalog[]) =>
  items.map(applyIcon).filter((item) => !!item.icon.length)

export const Section: React.FC<SectionProps> = ({ items }) => {
  // const {
  //   data: catalogs = [],
  //   isFetched,
  //   isError,
  // } = useCatalogsList({
  //   select: prepareCatalogs,
  // })

  // if (isError || (isFetched && !catalogs.length)) {
  //   return null
  // }

  const catalogs = prepareCatalogs(items)

  return (
    <>
      <div
        data-gap="8"
        className={cn(
          style.container,
          'column-8 et-icon-box-container et-item-set et-make-container effect-none animation-type-sequential small no-border'
        )}
      >
        {catalogs.map((item, idx) => (
          <Item key={idx} item={item} isDark={false} />
        ))}
        {/* {catalogs.map((item, idx) => (
          <Item key={idx} item={item} isDark={isDark(idx, 8)} />
        ))} */}
      </div>
      <div className="text-center mt-4">
        <LinkContainer to="/catalogs">
          <CompositButton>Все каталоги</CompositButton>
        </LinkContainer>
      </div>
    </>
  )
}
