import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import qs from 'qs'
import delay from 'lodash/delay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronUp,
  faSearch,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import { useSuggestions } from '@monorepo/search_module'
import { getSearchUrl } from '@monorepo/application_module/helpers'
import { ScreenReaderText } from './ui/ScreenReaderText'
import { SearchControl } from './ui/SearchControl'
import { Menu, MenuItem } from './ui/Menu'
import { SuggestionsList } from './ui/SuggestionsList'
import { Message } from './ui/Menu/Message'

export type SearchFieldProps = {
  show?: boolean
  minLength?: number
  allowVinQuery?: boolean
  isDark?: boolean
}

const vinRe = /^[A-HJ-NPR-Z\d]{9}[A-HJ-NPR-Z\d]{2}[A-Z\d]{6}$/i

const getCatalogUrl = (identString: string) =>
  [
    '/catalogs/vehicles',
    qs.stringify({ identString }, { addQueryPrefix: true }),
  ].join('')

const openKeyPressed = (event: React.KeyboardEvent<HTMLInputElement>) =>
  !event.altKey &&
  !event.ctrlKey &&
  !event.metaKey &&
  !event.shiftKey &&
  ![
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'Backspace',
    'Delete',
    'Enter',
    'Escape',
    'F1',
    'F10',
    'F11',
    'F12',
    'F2',
    'F3',
    'F4',
    'F5',
    'F6',
    'F7',
    'F8',
    'F9',
    'Tab',
  ].includes(event.code)

export const SearchField: React.FC<SearchFieldProps> = ({
  show: initialShow = false,
  isDark,
  minLength = 2,
  allowVinQuery = true,
  ...props
}) => {
  const location = useLocation()
  const history = useHistory()
  const initialQuery = useMemo(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true }) as {
      number?: string
    }
    return query.number ?? ''
  }, [location.search])

  const [isMenuOpen, setMenuOpen] = useState<boolean>(initialShow)
  const openMenu = () => setMenuOpen(true)
  const closeMenu = () => delay(setMenuOpen, 250, false)

  const [query, setQuery] = useState(initialQuery)

  const isVinQuery = allowVinQuery && vinRe.test(query)

  const { data: suggestions, isFetching } = useSuggestions(
    {
      query,
      strict: true,
      useFTS: false,
    },
    { enabled: isMenuOpen && query.length >= minLength, keepPreviousData: true }
  )

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    closeMenu()
    // const target = event?.target as HTMLFormElement

    if (activeItemIdx === 0 && isVinQuery) {
      // history.push('')

      // const getCatalogUrl = (identString: string) =>
      //   `/catalogs/vehicles?identString=${identString}`
      // 3N1BCAC11UL449541
      return history.push(getCatalogUrl(query))
      // return
    }

    if (activeItemIdx >= 0) {
      const good = suggestions?.[isVinQuery ? activeItemIdx - 1 : activeItemIdx]

      if (good) {
        return history.push(getSearchUrl(good))
      }
    }

    if ((query?.length || 0) >= minLength) {
      return history.push(
        [
          '/search',
          qs.stringify({ number: query }, { addQueryPrefix: true }),
        ].join('')
      )
    }

    // setActiveItemIdx(-1)
  }

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value)
    },
    []
  )

  const handleClear = () => setQuery('')

  const [activeItemIdx, setActiveItemIdx] = useState<number>(-1)

  const keypressHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const totalItems = (suggestions?.length ?? 0) + (isVinQuery ? 1 : 0)

      if (!isMenuOpen && openKeyPressed(event)) {
        openMenu()
      }

      if (event.code === 'ArrowDown') {
        setActiveItemIdx((prev) => {
          const next = Math.min(prev + 1, totalItems - 1)
          if (next >= 0 && !isMenuOpen) {
            openMenu()
          }
          return next
        })
        return
      }
      if (event.code === 'ArrowUp') {
        setActiveItemIdx((current) => {
          const next = Math.max(current - 1, -1)
          if (current < 0 && isMenuOpen) {
            closeMenu()
          }
          return next
        })
        return
      }
    },
    [isMenuOpen, isVinQuery, suggestions]
  )

  // useEffect(() => {
  //   document.addEventListener('visibilitychange', closeMenu)

  //   return () => {
  //     document.removeEventListener('visibilitychange', closeMenu)
  //   }
  // }, [])

  return (
    <div {...props}>
      <form role="search" onSubmit={handleSubmit} action="/search">
        <ScreenReaderText>Номер запчасти или VIN автомобиля</ScreenReaderText>
        <SearchControl $isDark={isDark}>
          <input
            type="search"
            name="number"
            placeholder="Номер запчасти или VIN автомобиля..."
            autoComplete="off"
            value={query}
            onChange={handleChange}
            // onFocus={openMenu}
            onBlur={closeMenu}
            onPaste={openMenu}
            onKeyDown={keypressHandler}
          />

          {(query.length > 0 || isMenuOpen) && (
            <button
              type="button"
              onClick={isMenuOpen ? closeMenu : handleClear}
              title={isMenuOpen ? 'Закрыть' : 'Очистить'}
            >
              <FontAwesomeIcon
                icon={isMenuOpen ? faChevronUp : faTimes}
                size="lg"
              />
            </button>
          )}

          <button
            type="submit"
            title="Найти"
            disabled={isFetching || query.length < minLength}
          >
            <FontAwesomeIcon
              icon={isFetching ? faSpinner : faSearch}
              spin={isFetching}
              size="lg"
            />
            <strong>Найти</strong>
          </button>
        </SearchControl>

        <Menu $open={isMenuOpen}>
          {!isFetching && (
            <Message
              isVinQuery={isVinQuery}
              minLength={minLength}
              query={query}
              totalSuggestions={suggestions?.length || 0}
            />
          )}
          {isVinQuery && (
            <MenuItem href="" active={isVinQuery && activeItemIdx === 0}>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp; Найти по VIN <strong>{query.toUpperCase()}</strong>
            </MenuItem>
          )}
          {typeof suggestions !== 'undefined' && suggestions.length > 0 && (
            <SuggestionsList
              suggestions={suggestions}
              activeItemIdx={isVinQuery ? activeItemIdx - 1 : activeItemIdx}
              iconSize="65px"
            />
          )}
        </Menu>
      </form>
    </div>
  )
}
