import React from 'react'
import styled from 'styled-components'

import { IGood } from '@monorepo/application_module'

import { TableHeader } from './components/table_header'
import { TableRow } from './components/table_row'
import { SearchInfo } from './components/search_info'

type ResultsTablePropsType = React.HTMLAttributes<HTMLElement> & {
  items: IGood[]
  title?: React.ReactNode
}

const Wrapper = styled.section`
  margin-top: 24px;
`

const Table = styled.div`
  border: ${(props) => props.theme.baseTableBorder};
  border-radius: ${(props) => props.theme.baseRadius};
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */

  & > *:not(:last-child) {
    border-bottom: ${(props) => props.theme.baseTableBorder};
    /* &:last-child {
      border-bottom: none;
    } */
  }
`

const Title = styled.h4`
  margin-bottom: 0;
  color: ${(props) => props.theme.colorLightBlack};
`

export const ResultsTable: React.FC<ResultsTablePropsType> = ({
  items,
  title,
  ...props
}) => (
  <Wrapper {...props}>
    {title && <Title>{title}</Title>}
    <em>
      <SearchInfo items={items} />
    </em>
    <hr />
    <Table>
      <TableHeader />
      {items.map((item) => (
        <TableRow key={item.id} item={item} />
      ))}
    </Table>
  </Wrapper>
)
