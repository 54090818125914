import { useCurrentUser } from '@monorepo/user_module'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { AccountNavigation } from './AccountNavigation'

type PropsType = {
  //
}

export const CabinetLayout: React.FC<PropsType> = ({ children }) => {
  const { data: user, isFetching } = useCurrentUser()

  return (
    <Row>
      <Col lg="3" className="d-flex">
        {user && <AccountNavigation user={user} />}
      </Col>
      <Col lg="9" className="mt-4 mt-lg-0">
        {isFetching ? <>Загрузка...</> : children}
      </Col>
    </Row>
  )
}
