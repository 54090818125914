import { AxiosError } from 'axios'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import {
  fetchVehiclesList,
  IVehiclesListResponse,
} from '../api/fetch_vehicles_list.api'
import { CatalogCode } from '../interfaces'
import { defaultOptions } from './_default_query_options'

const QUERY_KEY = 'laximo_vehicles_list'

interface Options<TData>
  extends UseQueryOptions<IVehiclesListResponse, AxiosError, TData> {
  ssd?: string
  catalogCode?: CatalogCode
  identString?: string
}

export function useVehiclesList<TData = IVehiclesListResponse>({
  ssd = '',
  catalogCode = '',
  identString = '',
  ...options
}: Options<TData>) {
  const params = { ssd, catalogCode, identString }

  return useQuery(
    [QUERY_KEY, params] as QueryKey,
    fetchVehiclesList.bind(null, params),
    {
      ...defaultOptions,
      enabled:
        (Boolean(ssd.length) && Boolean(catalogCode.length)) ||
        Boolean(identString.length),
      ...options,
    }
  )
}
