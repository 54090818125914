import React, { isValidElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import { ICartItem } from '@monorepo/cart_module'

export type BaseErrorProps = {
  fieldName: string
  message: React.ReactNode
  item: ICartItem
}

export const BaseError: React.FC<BaseErrorProps> = ({ message, children }) => (
  <li className="text-danger my-1 d-flex align-items-center justify-content-between">
    <span className="d-flex align-items-center mr-2">
      <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
      <small>
        {typeof message === 'string' || isValidElement(message)
          ? message
          : 'Непредвиденная ошибка'}
      </small>
    </span>
    {children && <span>{children}</span>}
  </li>
)
