import { IRoute } from './interface'

import CatalogsListPage from 'App/View/Laximo/catalogs_list.page'
import CatalogPage from 'App/View/Laximo/catalog.page'
import VehiclesListPage from 'App/View/Laximo/vehicles_list.page'
import VehiclePage from 'App/View/Laximo/vehicle'
import UnitPage from 'App/View/Laximo/unit.page'

export default (): IRoute[] => [
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/catalogs`,
    component: CatalogsListPage,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/catalogs/vehicles`,
    component: VehiclesListPage,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/catalogs/vehicle`,
    component: VehiclePage,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/catalogs/unit`,
    component: UnitPage,
  },
  {
    exact: true,
    path: `${process.env.PUBLIC_URL}/catalogs/:code`,
    component: CatalogPage,
  },
  // /vehicles/wizard

  // /vehicles/find
  // 1: FindVehicle:Locale=ru_RU|IdentString=WDB4632721X279380

  // /vehicles/findByOEM
  // 1: FindApplicableVehicles:OEM=0913128000|Catalog=HYW201905|ssd=|Locale=ru_RU
  // 2: GetCatalogInfo:Locale=ru_RU|Catalog=HYW201905|ssd=

  // /quick_groups
  // /vehicle

  // /unit
  // {
  //   exact: true,
  //   path: `${process.env.PUBLIC_URL}/catalogs/:code/vehicles`,
  //   component: VehiclesListPage,
  // },
  // {
  //   exact: true,
  //   path: `${process.env.PUBLIC_URL}/catalogs/:code/quick_groups`,
  //   component: VehiclePage,
  // },
  // {
  //   exact: true,
  //   path: `${process.env.PUBLIC_URL}/catalogs/:code/unit`,
  //   component: UnitPage,
  // },
  // {
  //   exact: true,
  //   path: `${process.env.PUBLIC_URL}/order/details/:id`,
  //   component: OrderDetails,
  // },
]
