import React from 'react'
import styled from 'styled-components'
import { chain } from 'lodash'
import { LinkContainer } from 'react-router-bootstrap'

import { CompositButton } from 'App/Components'

type ErrorLayoutProps = {
  code: number | string
  title: React.ReactNode
}

const CodeWrapper = styled.h1`
  color: ${(props) => props.theme.danger};
`
const TitleWrapper = styled.p`
  color: ${(props) => props.theme.primary};
`

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({
  code,
  title,
  children,
}) => (
  <div className="error404">
    <div id="et-content" className="content et-clearfix padding-true">
      <div className="container et-clearfix">
        <div className="message404 et-clearfix">
          <CodeWrapper className="error404-default-title">
            {chain(String(code))
              .split('')
              .reverse()
              .tail()
              .reverse()
              .join('')
              .value()}
            <span className="transform-error">
              {chain(String(code)).split('').last().value()}
            </span>
          </CodeWrapper>
          <TitleWrapper className="error404-default-subtitle">
            {title}
          </TitleWrapper>
          <div className="error404-default-description lead">{children}</div>
          <LinkContainer to="/">
            <CompositButton
              className="error404-button et-button"
              title="Перейти на главную"
              onClick={() => {
                if (code >= 500) {
                  window.location.href = '/'
                }
              }}
            >
              Перейти на главную
            </CompositButton>
          </LinkContainer>
        </div>
      </div>
    </div>
  </div>
)
