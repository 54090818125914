import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { OperationsFilterProvider } from '@monorepo/contract_module'
import { Page } from 'App/Layout'
import { CabinetLayout } from 'App/PageComponents/Cabinet/CabinetLayout'
import { FilterForm, OperationsTable } from 'App/PageComponents/Cabinet/Balance'

type BalanceProps = {
  //
}

const Balance: React.FC<BalanceProps> = () => (
  <Page title="Мой баланс" authenticated>
    <Container>
      <CabinetLayout>
        <OperationsFilterProvider>
          {({ values }) => (
            <Row>
              <Col xs="12">
                <FilterForm />
              </Col>
              <Col xs="12">
                <OperationsTable filter={values} />
              </Col>
            </Row>
          )}
        </OperationsFilterProvider>
      </CabinetLayout>
    </Container>
  </Page>
)

export default Balance
