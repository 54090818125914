import { css } from 'styled-components'

import { ThemeVariant } from './enums'

type BaseTheme = {
  colorMintGreen: string
  colorLightGreyBg: string
  colorBarelyGrey: string
  colorLightGrey: string
  colorGreyBg: string
  colorGrey: string
  colorDarkGrey: string
  colorBlack: string
  colorLightBlack: string
  colorWhite: string
  colorOrange: string
  colorRed: string
  colorGreen: string
  colorLightGreen: string
  colorDarkGreen: string
  colorGreyTextOnWhite: string
  colorLinkBlue: string
  colorYellow: string
}

type ThemeConfig = {
  logoColor: string
  headerBackgroundPrimaryColor: string
  headerBackgroundSecondaryColor: string
  headerPrimaryColor: string
  headerSecondaryColor: string
  headerSubmenuBackgroundColor: string
  headerSubmenuBackgroundHoverColor: string
  headerButtonBackgroundHoverColor: string
  footerColor: string
  footerBackgroundColor: string
  footerBackground?: any
}

const lightTheme = (base: BaseTheme): ThemeConfig => ({
  logoColor: base.colorOrange,
  headerPrimaryColor: base.colorDarkGrey,
  headerSecondaryColor: base.colorWhite,
  // headerBackgroundPrimaryColor: base.colorWhite,
  headerBackgroundPrimaryColor: base.colorWhite,
  headerBackgroundSecondaryColor: base.colorOrange,
  headerSubmenuBackgroundColor: base.colorWhite,
  headerSubmenuBackgroundHoverColor: base.colorLightGrey,
  headerButtonBackgroundHoverColor: base.colorWhite,
  footerColor: base.colorBlack,
  footerBackgroundColor: base.colorWhite,
  footerBackground: css`linear-gradient(
    180deg,
    ${(props) => props.theme.colorWhite} 0%,
    ${(props) => props.theme.colorGrey} 100%
  )`,
})

const darkTheme = (base: BaseTheme): ThemeConfig => ({
  logoColor: base.colorWhite,
  headerPrimaryColor: base.colorWhite,
  headerSecondaryColor: base.colorWhite,
  headerBackgroundPrimaryColor: base.colorBlack,
  headerBackgroundSecondaryColor: base.colorBlack,
  headerSubmenuBackgroundColor: base.colorBlack,
  headerSubmenuBackgroundHoverColor: base.colorDarkGrey,
  headerButtonBackgroundHoverColor: base.colorDarkGrey,
  footerColor: base.colorWhite,
  footerBackgroundColor: base.colorBlack,
  footerBackground: css`linear-gradient(
    180deg,
    ${(props) => props.theme.colorLightBlack} 0%,
    ${(props) => props.theme.colorBlack} 100%
  )`,
})

export const configure: {
  [key in ThemeVariant]: (base: BaseTheme) => ThemeConfig
} = {
  [ThemeVariant.DARK]: darkTheme,
  [ThemeVariant.LIGHT]: lightTheme,
}
// : (key in ThemeVariant)[]
export const darkThemeVariants = [ThemeVariant.DARK]
