import { apiClient } from '@monorepo/core'

import { IBlogPost } from '../interfaces'

export async function fetchPostBySlug(slug: string) {
  try {
    const { data } = await apiClient.get<IBlogPost>(`/v2/blog/posts/${slug}`)

    return data
  } catch (error) {}
}
