import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import qs from 'qs'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import { debounce } from 'lodash/fp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { filterQuickGroups, useQuickGroups } from '@monorepo/laximo_module'
import { DetailsList } from './components/details_list'
import { GroupsList } from './components/groups_list'
import { Metadata } from '../../../../vehicle.page'

interface QuickGroupProps {
  readonly catalogCode: string
  readonly ssd: string
  onLoad?<T extends Metadata>(data: T): void
}

export const QuickGroup: React.FC<QuickGroupProps> = ({
  catalogCode,
  ssd,
  onLoad,
}) => {
  const location = useLocation()

  const query = qs.parse(location.search, { ignoreQueryPrefix: true }) as {
    vehicleId?: string
    groupId?: string
  }

  const { vehicleId = '' } = query

  const { data: { catalog, quickGroup, quickDetails } = {}, isFetched } =
    useQuickGroups({
      catalogCode,
      ssd,
      vehicleId,
      groupId: query.groupId,
      onSuccess: (data) => onLoad?.(data),
    })

  // const support = getCatalogFeatures(catalog)
  // const useQuickgroups = support('quickgroups')

  const root = quickGroup?.childGroups

  const [searchString, setSearchString] = useState('')
  const [inputValue, setInputValue] = useState('')

  const updateSearchString = debounce(500, setSearchString)

  const updateFilterHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setInputValue(value)
      updateSearchString(value)
    },
    [updateSearchString]
  )
  const clearFilterHandler = useCallback(() => {
    setInputValue('')
    setSearchString('')
  }, [])

  const quickGroups = filterQuickGroups(root ?? [], searchString)

  return (
    <Row className="mt-4">
      <Col md="4">
        {isFetched && (
          <>
            <Form>
              <InputGroup className="mb-4-">
                <Form.Control
                  placeholder="Поиск узла..."
                  aria-label="Поиск узла"
                  value={inputValue}
                  onChange={updateFilterHandler}
                />
                {searchString.length > 0 && (
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={clearFilterHandler}
                      type="reset"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </Form>
            {quickGroups.length > 0
              ? quickGroups.map((item) =>
                  item.childGroups ? (
                    <GroupsList
                      key={item.quickgroupid}
                      items={item.childGroups}
                    />
                  ) : null
                )
              : searchString.length > 0 && (
                  <Alert variant="danger">
                    Узел <strong>«{searchString}»</strong> не найден
                  </Alert>
                )}
          </>
        )}
      </Col>
      <Col md="8">
        {catalog &&
          quickDetails?.map((item) => (
            <DetailsList key={item.categoryid} item={item} catalog={catalog} />
          ))}
      </Col>
    </Row>
  )
}
