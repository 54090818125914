import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { useOrderItemValidator } from '@monorepo/order_module'

type ErrorsIndicatorProps = {
  //
}

export const ErrorsIndicator: React.FC<ErrorsIndicatorProps> = () => {
  const { isValid, errors } = useOrderItemValidator()
  const errorsAmount = Object.keys(errors).length

  return isValid ? null : (
    <OverlayTrigger
      placement="auto"
      overlay={
        <Tooltip id="error-tooltip">
          <FormattedMessage
            id="cart.cart_item_error"
            defaultMessage={`{amount, plural,
                =0 {Ошибок нет}
                one {Найдена # ошибка}
                few {Найдено # ошибки}
                many {Найдено # ошибок}
                other {Найдено # ошибок}
              }
            `}
            values={{ amount: errorsAmount }}
          />
        </Tooltip>
      }
    >
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        className="text-danger mr-1"
      />
    </OverlayTrigger>
  )
}
