import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik'
import defer from 'lodash/defer'
import defaults from 'lodash/defaults'

import { FormError } from '@monorepo/application_module/components/form_error'
import { ICartItem } from '@monorepo/cart_module'
import { IOrder } from '../../interfaces'
import { CreateOrderDTO } from '../../dto'
import { useCreateOrder } from '../../hook'
import { createOrderSchema } from '../../schema'
import { useInitialValues } from './initial_values.hook'
// import { useSyncValues } from './sync_values.hook'

type CreateOrderProviderProps = {
  items: ICartItem[]
  onSuccess?(order: IOrder): void | Promise<void>
  onError?(error: FormError): void
  children:
    | ((props: FormikContextType<CreateOrderDTO>) => React.ReactNode)
    | React.ReactNode
}

export const CreateOrderProvider: React.FC<CreateOrderProviderProps> = ({
  items,
  children,
  onSuccess,
  onError,
}) => {
  const initialValues = useInitialValues(items)
  const { mutateAsync } = useCreateOrder()

  const history = useHistory()

  const onSubmit = async (
    values: CreateOrderDTO,
    helpers: FormikHelpers<CreateOrderDTO>
  ) => {
    const dto = createOrderSchema.cast(values, {
      stripUnknown: true,
    })

    try {
      const order = await mutateAsync(dto as CreateOrderDTO)

      helpers.setSubmitting(false)
      await onSuccess?.(order)

      defer(history.push, `/order/pay/${order.id}`, {
        order,
        successUrl: `/order/details/${order.id}`,
      })
      // history.push(`/order/${order.id}/pay`, {
      //   order,
      //   successUrl: `/order/${order.id}/status`,
      // })
    } catch (error) {
      helpers.setSubmitting(false)
      onError?.(FormError.create(error))
    }
  }

  const formik: FormikContextType<CreateOrderDTO> = useFormik<CreateOrderDTO>({
    initialValues,
    onSubmit,
    enableReinitialize: false, // IMPORTANT: set false!
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: createOrderSchema,
  })

  useEffect(() => {
    const values = defaults({ items }, formik.values, initialValues)

    formik.setValues(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, items])

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function'
        ? (
            children as (
              props: FormikContextType<CreateOrderDTO>
            ) => React.ReactNode
          )(formik)
        : children}
    </FormikProvider>
  )
}

export function usePlaceOrderForm() {
  return useFormikContext<CreateOrderDTO>()
}
