import styled from 'styled-components'

import Base from './links_list'

export * from './links_list'

export const LinksList = styled(Base)`
  width: 100%;

  & ul {
    list-style: none;
    margin: 0;

    & li {
      display: flex;
      color: ${(props) => props.theme.colorYellow};

      & a {
        color: ${(props) => props.theme.footerColor};
      }
    }
  }
`
