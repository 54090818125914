import { apiClient } from '@monorepo/core'

import { IPaymentMethod } from '../interfaces'

export async function fetchPaymentMethods() {
  try {
    const { data } = await apiClient.get<IPaymentMethod[]>('/payment_methods')
    return data
  } catch (error) {
    return []
  }
}
