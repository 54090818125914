import React from 'react'
import { Col, FormGroup, FormControl, Form } from 'react-bootstrap'
import { FormattedNumber } from 'react-intl'
import { Form as FormikForm, Field, FieldProps, useField } from 'formik'

import { IOffer } from '@monorepo/application_module'
// import { NumberInput } from 'App/components/FormFields/NumberInput'

type AddToCartFormProps = {
  id?: string
  offer: IOffer
  cartAmount: number
}

export const AddToCartForm: React.FC<AddToCartFormProps> = ({
  id,
  offer,
  cartAmount,
}) => {
  const { amountString, multiplicityAmount = 1 } = offer

  const [{ value: currentAmount }] = useField('amount')

  return (
    <Form id={id} as={FormikForm}>
      <Form.Row>
        <Col xs={6} sm={4}>
          <Field name="amount">
            {(props: FieldProps) => (
              <FormGroup>
                <Form.Label>Количество</Form.Label>
                <FormControl
                  {...props.field}
                  // as={NumberInput}
                  min={offer?.minAmount ?? 0}
                  max={offer?.amount}
                  step={offer?.multiplicityAmount ?? 1}
                />
                {/* <Form.Text
                  // muted
                  className={
                    offer?.amount && currentAmount > offer?.amount
                      ? 'text-danger'
                      : 'text-muted'
                  }
                >
                  В наличии {amountString} шт.
                  {cartAmount > 0 && <>, в корзине {cartAmount} шт.</>}
                  {multiplicityAmount !== 1 && (
                    <>
                      <br />
                      <span className="text-danger">
                        Кратность заказа {multiplicityAmount} шт.
                      </span>
                    </>
                  )}
                </Form.Text> */}
              </FormGroup>
            )}
          </Field>
        </Col>
        <Col xs={6} sm={8} className="text-right">
          <FormGroup>
            <Form.Label className="text-right">Стоимость</Form.Label>
            <div className="d-flex flex-column padding-none">
              <span style={{ fontSize: '1.75em' }}>
                <FormattedNumber
                  value={offer.price * currentAmount}
                  format="RUB"
                />
              </span>
            </div>
            {currentAmount !== offer.minAmount && (
              <Form.Text>
                Цена: <FormattedNumber value={offer.price} format="RUB" />
              </Form.Text>
            )}
          </FormGroup>
        </Col>

        <Col xs={12}>
          <Form.Text
            // muted
            className={
              offer?.amount && currentAmount > offer?.amount
                ? 'text-danger'
                : 'text-muted'
            }
          >
            В наличии {amountString} шт.
            {cartAmount > 0 && <>, в корзине {cartAmount} шт.</>}
            {multiplicityAmount !== 1 && (
              <>
                <br />
                <span className="text-danger">
                  Кратность заказа {multiplicityAmount} шт.
                </span>
              </>
            )}
          </Form.Text>
        </Col>

        <Col xs={12}>
          <FormGroup>
            <Form.Label>Коментарий к позиции | Reference</Form.Label>
            <Field name="reference">
              {({ field }: FieldProps) => (
                <Form.Control
                  {...field}
                  as="textarea"
                  rows={5}
                  style={{ minHeight: 'auto' }}
                />
              )}
            </Field>
          </FormGroup>
        </Col>
      </Form.Row>
    </Form>
  )
}
