import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import { Wrapper } from './components/wrapper'
import { FindByVinForm } from './components/find_by_vin.form'

type FindFormProps = {
  //
}

export const FindForm: React.FC<FindFormProps> = () => (
  <Wrapper>
    <Tabs defaultActiveKey="vin-frame" variant="pills">
      <Tab eventKey="vin-frame" title="Поиск по VIN/Frame">
        <FindByVinForm />
      </Tab>
    </Tabs>
  </Wrapper>
)
