import { get, min, orderBy } from 'lodash/fp'

import { IGood, IOffer } from '@monorepo/application_module'

export interface SortGoodsByProps {
  by: keyof IOffer
  direction?: 'asc' | 'desc'
}

export const sortGoodsBy = ({
  by,
  direction = 'asc',
}: SortGoodsByProps): ((data: IGood[]) => IGood[]) =>
  orderBy<IGood>(
    [
      (good) => (good.isCross ? (good.isOriginalCross ? 2 : 1) : 3),
      (good) => (good.offers?.find((offer) => offer.isStock) ? 1 : 0),
      (good) => good.brand.priority ?? Number.NEGATIVE_INFINITY,
      (good) => min(good.offers?.map(get(by))),
      'brand.name',
      'number',
    ],
    ['desc', 'desc', 'desc', direction, 'asc', 'asc']
  )
