import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import qs from 'qs'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { some } from 'lodash/fp'

import {
  getVehiclesListPageUrl,
  ILaximoCatalog,
  useCatalogInfo,
  WizardProvider,
} from '@monorepo/laximo_module'
import { Page } from 'App/Layout'
import { FindForm } from './components/find_form'
import { HLine } from './components/h_line'
import { CompositButton } from 'App/Components'
import { createBreadcrumbs } from 'App/PageComponents/Laximo/helpers/createBreadcrumbs'
import { CatalogErrorPage } from 'App/PageComponents/Laximo/catalog_error.page'

interface CatalogPageProps {
  //
}

const supportFeature = (
  catalog: ILaximoCatalog | null | undefined,
  feature: string
) =>
  typeof catalog?.features?.find((item) => item.name === feature) !==
  'undefined'

const allowSelect = some({ allowListVehicles: 1 })

const CatalogPage: React.FC<CatalogPageProps> = () => {
  const { code = '' } = useParams<{ code?: string }>()
  const location = useLocation()
  const query = qs.parse(location.search, { ignoreQueryPrefix: true }) as {
    ssd?: string
    wizard?: string
    catalogCode?: string
  }

  const {
    data: { catalog, wizard } = {},
    isFetched,
    isError,
  } = useCatalogInfo({
    catalogCode: query.catalogCode || code,
    ssd: query.ssd,
    wizard: ['true', 't', '1'].includes(String(query.wizard).toLowerCase()),
  })

  if (isFetched && isError) {
    return <CatalogErrorPage />
  }

  const supportWizardSearch = supportFeature(catalog, 'wizardsearch2')
  const supportVinSearch = supportFeature(catalog, 'vinsearch')

  return (
    <Page title={catalog?.brand} breadcrumbs={createBreadcrumbs({})}>
      <Container>
        <Row className="mb-5">
          <Col md="6">{supportVinSearch && <FindForm />}</Col>
        </Row>
        {/* {supportVinSearch && supportWizardSearch && <HLine />} */}
        <Row>
          <Col md="6">
            <h4>Поиск автомобиля по параметрам:</h4>
            <HLine />
            {supportWizardSearch && wizard?.steps && (
              <WizardProvider
                wizard={wizard}
                ssd={query.ssd}
                catalogCode={code}
              >
                {allowSelect(wizard.steps) && (
                  <CompositButton
                    to={getVehiclesListPageUrl({
                      catalogCode: code,
                      ssd: query.ssd ?? '',
                    })}
                  >
                    Показать автомобили
                  </CompositButton>
                )}
              </WizardProvider>
            )}
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

export default CatalogPage
