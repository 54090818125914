import styled from 'styled-components'

export const ItemsGroup = styled.section`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
