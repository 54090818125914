import React from 'react'
import styled from 'styled-components'

import { GroupsListItem, IBaseItem } from './components/list_item'

const List = styled.ul.attrs({ className: 'fa-ul' })`
  margin-bottom: 0px;
  margin-left: 1.5em;
`

export function GroupsList<T extends IBaseItem>({
  items,
}: {
  items: T[]
}): JSX.Element {
  return (
    <List>
      {items.map((item) => (
        <GroupsListItem key={item.quickgroupid} item={item} />
      ))}
    </List>
  )
}
