import styled from 'styled-components'

import Base from './CartMenu'
export * from './CartMenu'

export const CartMenu = styled(Base)`
  && button {
    background-color: transparent;
    transition: background 250ms ease;
    color: ${(props) => props.theme.headerPrimaryColor};

    &:hover {
      background-color: ${(props) =>
        props.theme.headerButtonBackgroundHoverColor};

      .badge {
        background-color: ${(props) => props.theme.colorLightBlack};
      }
    }
  }

  ul {
    list-style-type: none;
    max-height: 75vh;
    overflow: auto;
    margin: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0.25rem 0.5rem;
      margin: 0.25rem 0.5rem;

      &:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.colorGrey};
      }

      &:hover {
        background-color: ${(props) => props.theme.colorLightGrey};
      }

      & > *:nth-child(1) {
        height: 50px;
        width: 50px;
      }

      & > article {
        min-width: 250px;
        max-width: 300px;
        overflow: hidden;
        margin: 0 0.5rem;
        flex: 1;
        display: flex;
        flex-direction: column;

        em {
          font-size: 0.75rem;
          white-space: nowrap;
          display: inline-block;
          text-overflow: ellipsis;
        }
        p {
          color: ${(props) => props.theme.colorOrange};
          font-weight: ${(props) => props.theme.fontBold};
          align-self: end;
          margin: 0.25rem;
        }
        /* height: 50px; */
        /* width: 50px; */
      }

      & > button {
        /* display: inline-block; */
        appearance: none;
        width: 2rem;
        height: 2rem;
        line-height: 1.5rem;
        font-size: 1.5rem;
        padding: 0.25rem 0.25rem 0.5rem;
        border: 0;
        color: ${(props) => props.theme.colorOrange}!important;

        justify-content: center;
        align-items: center;
        font-weight: ${(props) => props.theme.fontBold};
        border-radius: ${(props) => props.theme.baseRadius};

        &:hover {
          background-color: ${(props) => props.theme.colorGrey}!important;
        }
        &:active {
          background-color: ${(props) => props.theme.colorDarkGrey}!important;
        }
      }
    }
  }
`
