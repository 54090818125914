import React from 'react'

import { ReactComponent as LogoImage } from 'App/Resources/images/logo/logo.svg'

export type LogoProps = React.HTMLAttributes<HTMLElement> & {
  title?: string
  $height?: string | number
}

const Logo: React.FC<LogoProps> = ({ title = 'Автозапчасти', ...props }) => (
  <span {...props}>
    <LogoImage />
    {!!title.length && <span>{title}</span>}
  </span>
)

export default Logo
