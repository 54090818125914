import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'
import { ForgotPasswordDTO } from '../dto'

export async function forgotPassword(dto: ForgotPasswordDTO) {
  try {
    const { data } = await apiClient.get<{ message: string }>(
      '/user/resetpassword',
      { params: dto }
    )

    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при восстановлении')
  }
}
