import styled from 'styled-components'

import { CatalogItem as Base } from './catalog_item'

export const CatalogItem = styled(Base).attrs({
  className:
    'link et-icon-box et-item icon-position-top icon-alignment-center hover-none',
})`
  transition: 0.25s ease-in-out;
  border-radius: ${(props) => props.theme.baseRadius};
  padding: 1.5rem;

  & a {
    * {
      color: ${(props) => props.theme.colorWhite};
    }

    .el-icon {
      color: ${(props) => props.theme.colorYellow};
      overflow: visible;
    }

    &:hover {
      text-decoration: none;
    }
  }

  & * {
    transition: 0.25s ease-in-out;
  }

  &:hover {
    background-color: ${(props) => props.theme.colorYellow};

    .et-icon {
      transform: scale(1.15);
      .el-icon {
        color: ${(props) => props.theme.colorOrange};
      }
    }
  }
`
