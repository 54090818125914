import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import {
  Formik,
  Form as FormikForm,
  FormikHelpers,
  Field,
  FormikValues,
} from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { getVehiclesListPageUrl } from '@monorepo/laximo_module'
import { useHistory } from 'react-router'

export interface FindByVinFormProps {
  exampleVin?: string
}

interface Values extends FormikValues {
  identString: string
}

export const FindByVinForm: React.FC<FindByVinFormProps> = ({
  exampleVin = 'KMHVD34N8VU263043s',
}) => {
  const history = useHistory()
  const initialValues: Values = {
    identString: '',
  }

  const submitHandler = (values: Values, _helpers: FormikHelpers<Values>) => {
    const url = getVehiclesListPageUrl(values)

    history.push(url)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form as={FormikForm}>
        <InputGroup className="mb-3">
          <Field
            as={Form.Control}
            name="identString"
            placeholder="VIN или номер кузова автомобиля"
            aria-label="VIN или номер кузова автомобиля"
          />
          <InputGroup.Append>
            <Button type="submit" variant="outline-secondary">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <Form.Text muted>
          Введите VIN или номер кузова автомобиля, например, {exampleVin}
        </Form.Text>
      </Form>
    </Formik>
  )
}
