import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { OrdersFilterProvider } from '@monorepo/order_module'
import { Page } from 'App/Layout'
import { CabinetLayout } from 'App/PageComponents/Cabinet/CabinetLayout'
import { FilterForm, OrdersTable } from 'App/PageComponents/Order/orders_list'

type OrdersProps = {
  //
}

export const Orders: React.FC<OrdersProps> = () => (
  <Page title="Мои заказы" authenticated>
    <Container>
      <CabinetLayout>
        <OrdersFilterProvider>
          {({ values }) => (
            <Row>
              <Col xs="12">
                <FilterForm />
              </Col>
              <Col xs="12">
                <OrdersTable filter={values} />
              </Col>
            </Row>
          )}
        </OrdersFilterProvider>
      </CabinetLayout>
    </Container>
  </Page>
)

export default Orders
