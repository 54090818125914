import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { IPaymentMethod } from '../interfaces'
import { fetchPaymentMethods } from '../api'

const QUERY_KEY = 'paymentMethods'

export function usePaymentMethods<TData = IPaymentMethod[]>(
  options?: UseQueryOptions<IPaymentMethod[], AxiosError, TData>
) {
  return useQuery(QUERY_KEY as any, fetchPaymentMethods, {
    refetchOnWindowFocus: false,
    ...options,
  })
}
