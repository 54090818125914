import React, { useCallback, useEffect, useMemo, useState } from 'react'
import qs from 'qs'
import {
  Field,
  FieldProps,
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
} from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'

import { FormError } from '@monorepo/application_module/components/form_error'
// import type { ICar } from '../interfaces'
import { WizardDTO } from '../dto/wizard.dto'
import { IWizard, IWizardStep, IWizardStepOption } from '../interfaces'
import { isEqual } from 'lodash'
import { useCatalogInfo } from '../hooks'
import { string } from 'yup/lib/locale'

// import { useUpdateCar } from '../hooks'
// import { updateCarSchema } from '../schema'

type WizardProviderProps = {
  // item: ICar
  // onSuccess?(car: ICar): void
  // onError?(errors: FormError): void
  wizard?: IWizard
  catalogCode: string
  ssd?: string
  children:
    | ((props: FormikContextType<WizardDTO>) => React.ReactNode)
    | React.ReactNode
}

export const WizardProvider: React.FC<WizardProviderProps> = ({
  children,
  catalogCode,
  ssd,
  // wizard,
  // item,
  // onSuccess,
  // onError,
}) => {
  const [state, setState] = useState<Record<string, string>>({})
  const {
    data: wizard,
    isFetching,
    // isFetched,
  } = useCatalogInfo({
    catalogCode,
    ssd,
    select: (data) => data.wizard,
    onSuccess: setState.bind(null, {}),
  })

  // const [initialized, setInitialized] = useState(false)
  // useEffect(() => {
  //   setInitialized(!isFetching)
  // }, [isFetching])

  const initialValues: WizardDTO = {
    // id: item?.id,
    // mark: item?.mark ?? '',
    // model: item?.model ?? '',
    // modification: item?.modification ?? '',
    // description: item?.description ?? '',
    // productionYear: item?.productionYear ?? new Date().getFullYear(),
    // engineModel: item?.engineModel ?? '',
    // enginePower: item?.enginePower ?? '',
    // engineSize: item?.engineSize ?? '',
    // vin: item?.vin ?? '',
  }
  // const { mutateAsync } = useUpdateCar()

  const init = Object.fromEntries(
    wizard?.steps?.map((step) => [step.name, step.value]) ?? []
  )
  // console.log('INIT:', init)

  const submitHandler = useCallback(
    async (values: WizardDTO, _helpers: FormikHelpers<WizardDTO>) => {
      try {
        // const dto = await mutateAsync(values)
        // if (!!dto) {
        //   onSuccess?.(dto)
        // }
      } catch (error) {
        // onError?.(FormError.isFormError(error) ? error : new FormError())
      }
    },
    []
  )

  const formik: FormikContextType<WizardDTO> = useFormik<WizardDTO>({
    // initialValues: Object.fromEntries(
    //   wizard?.steps.map((step, idx) => [`field-${idx}`, '']) ?? []
    // ),
    initialValues: init,
    onSubmit: submitHandler,
    enableReinitialize: true,
    // validationSchema: updateCarSchema,
  })
  // console.log(formik.initialValues)
  // const renderSelect = (step: IWizardStep) => (
  //   <select>
  //     {step.options.map((option) => (
  //       <option key={option.key} value={option.key}>
  //         {option.value}
  //       </option>
  //     ))}
  //   </select>
  // )

  // const renderOption = (option: IWizardStepOption) => (
  //   <option value={option.key}>{option.value}</option>
  // )

  const location = useLocation()
  const history = useHistory()
  const updateSsd = useCallback(
    (ssd: string) => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true })

      const search = qs.stringify(Object.assign(query, { ssd }), {
        addQueryPrefix: true,
      })

      history.push({ ...location, search })
    },
    [history, location]
  )

  // const currentState = useMemo(
  //   () =>
  //     Object.fromEntries(
  //       wizard?.steps.map((step) => [step.name, step.value]) ?? []
  //     ),
  //   [wizard]
  // )

  // const [state, setState] = useState<Record<string, string>>(init)
  // const [initialized, setInitialized] = useState(false)
  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const diff = { [event.target.name]: event.target.value }
      // console.log(event.target)

      // setState((prev) => ({ ...prev, [event.target.name]: event.target.value }))
      setState((prev) => ({ ...prev, ...diff }))
      // setState((prev) => {
      //   const next = { ...prev, ...diff }

      //   console.log('NEXT:', next)
      //   return next
      // })
      // setTimeout(() => updateSsd(event.target.value), 1000)
      updateSsd(event.target.value)
    },
    [updateSsd]
  )

  // useEffect(() => {
  //   if (!isEqual(state, currentState)) {
  //     setState(currentState)
  //   }
  // }, [currentState, state])

  return (
    <FormikProvider value={formik}>
      {/* <div>{initialized ? 'OK' : 'WIAT'}</div> */}
      {/* <div>{isFetched ? 'Fetched' : 'NOT Fetched'}</div> */}
      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}

      {wizard?.steps &&
        wizard?.steps.map((item, idx) => (
          <Form.Group key={idx} as={Row} /*controlId="formHorizontalEmail"*/>
            <Form.Label column sm={4}>
              {item.name}:
            </Form.Label>
            <Col sm={7}>
              {/* {item.name} -:- {state[item.name]}; */}
              <Form.Control
                /*type="email" placeholder="Email"*/ placeholder={item.name}
                as="select"
                // className="mr-sm-2"
                custom
                // defaultValue={item.value}
                value={state[item.name] ?? ''}
                onChange={changeHandler}
                name={item.name}
                // disabled={isFetching}
                // onChange={(event) => {
                //   updateSsd(event.target.value)
                // }}
              >
                {/* <option value={item.value}>
                  {!item.value.length ? 'Не выбрано' : item.value}
                </option> */}
                {!item.value && <option value="">Не выбрано</option>}
                {item.options ? (
                  item.options.map((option, idx) => (
                    <option key={idx} value={option.key}>
                      {option.value}
                    </option>
                  ))
                ) : (
                  <option value={item.value}>{item.value}</option>
                )}
              </Form.Control>
            </Col>
            <Col sm={1}>
              {!!item.determined && !item.automatic && (
                <Button
                  variant="outline-secondary"
                  onClick={updateSsd.bind(null, item.ssd)}
                >
                  &times;
                </Button>
              )}
            </Col>
          </Form.Group>
        ))}

      {false &&
        wizard?.steps &&
        wizard?.steps.map((item, idx) => (
          <Form.Group key={idx} as={Row} /*controlId="formHorizontalEmail"*/>
            <Form.Label column sm={2}>
              {item.name}:
            </Form.Label>
            <Col sm={9}>
              <Field name={item.name}>
                {({ field }: FieldProps) => (
                  <Form.Control
                    /*type="email" placeholder="Email"*/ placeholder={item.name}
                    as="select"
                    // className="mr-sm-2"
                    custom
                    // defaultValue={item.value}
                    // onChange={(event) => {
                    //   updateSsd(event.target.value)
                    // }}
                    {...field}
                  >
                    {/* <option value={item.value}>
                  {!item.value.length ? 'Не выбрано' : item.value}
                </option> */}
                    {!item.value && <option value="">Не выбрано</option>}
                    {item.options ? (
                      item.options.map((option, idx) => (
                        <option key={idx} value={option.key}>
                          {option.value}
                        </option>
                      ))
                    ) : (
                      <option value={item.value}>{item.value}</option>
                    )}
                  </Form.Control>
                )}
              </Field>
            </Col>
            <Col sm={1}>
              {!!item.determined && !item.automatic && (
                <Button
                  variant="outline-secondary"
                  onClick={updateSsd.bind(null, item.ssd)}
                >
                  &times;
                </Button>
              )}
            </Col>
          </Form.Group>
        ))}
      {/* {wizard?.steps.map((item, idx) => (
        <div key={idx}>
          <label>{item.name}:</label>
          <select
            name={`field-${idx}`}
            defaultValue={item.value}
            onChange={(event) => {
              updateSsd(event.target.value)
            }}
          >
            <option value={item.value}>
              {!item.value.length ? 'Не выбрано' : item.value}
            </option>
            {item.options &&
              item.options.map((option, idx) => (
                <option key={idx} value={option.key}>
                  {option.value}
                </option>
              ))}
          </select>
        </div>
      ))} */}
      {/* {wizard?.steps.map((item, idx) => (
        <Field
          key={idx}
          name={`field-${idx}`}
          as="select"
          onChange={console.log}
        >
          <option value="" disabled>
            Не выбрано
          </option>
          {item.options.map((option, idx) => (
            <option key={idx} value={option.key}>
              {option.value}
            </option>
          ))}
        </Field>
      ))} */}
      {typeof children === 'function' ? children(formik) : children}
    </FormikProvider>
  )
}
