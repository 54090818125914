import React from 'react'
import { useParams } from 'react-router'

import { useStaticPage } from '@monorepo/static_page_module'
import { htmlParser } from '@monorepo/application_module/helpers'
import { Page } from 'App/Layout'

type StaticPagePropsType = {
  //
}

export const StaticPage: React.FC<StaticPagePropsType> = () => {
  const { slug } = useParams<{ slug: string }>()

  const { data: page } = useStaticPage(slug)

  return (
    <Page bottomSpace>
      <div className="container et-clearfix">
        <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none vc-column-712819">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              {page && (
                <>
                  <Page.Title subTitle={page.description}>
                    {page.title}
                  </Page.Title>
                  {htmlParser(page.content)}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}
