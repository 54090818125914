import { apiClient } from '@monorepo/core'
import {
  CatalogCode,
  ILaximoCatalog,
  ILaximoVehicle,
  Nullable,
} from '../interfaces'

export interface IVehiclesListResponse {
  readonly catalog: Nullable<ILaximoCatalog>
  readonly vehicles: Nullable<ILaximoVehicle[]>
}

interface ByIdentStringParams {
  readonly identString: string
}

interface ByWizardParams {
  readonly ssd: string
  readonly catalogCode: CatalogCode
}

export async function fetchVehiclesList(
  params: ByIdentStringParams | ByWizardParams
) {
  try {
    const { data } = await apiClient.get<IVehiclesListResponse>(
      '/laximo2/vehicles',
      { params }
    )

    return data
  } catch (error) {
    throw error
  }
}
