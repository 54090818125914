import React from 'react'
import { Alert } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import {
  flow,
  filter,
  size,
  values,
  overSome,
  isString,
  isObject,
  defaultTo,
} from 'lodash/fp'

import { ICreateOrderErrors } from '@monorepo/order_module/interfaces'

type ErrorsSectionProps = {
  errors: ICreateOrderErrors
}

const getItemErrorsCount = flow(filter(isObject), size, defaultTo(0))

const extractErrors = flow(
  values,
  filter(overSome([isString, React.isValidElement]))
)

const ErrorItem: React.FC = ({ children }) => (
  <dd className="mb-0">{children}</dd>
)

export const ErrorsSection: React.FC<ErrorsSectionProps> = ({ errors }) => {
  const itemsErrorsAmount = getItemErrorsCount(errors.items)

  return (
    <Alert className="d-flex align-items-center" variant="danger">
      <FontAwesomeIcon icon={faExclamationTriangle} size="5x" />
      <dl className="ml-3 m-0">
        <dt>Внимание!</dt>
        {itemsErrorsAmount > 0 && (
          <ErrorItem>
            <FormattedMessage
              id="cart.item_errors_amount"
              defaultMessage={`В корзине 
                {amount, plural,
                  =0 {нет товаров}
                  one {# товар}
                  few {# товара}
                  many {# товаров}
                  other {# товаров}
                }
                с ошибками
              `}
              values={{ amount: itemsErrorsAmount }}
            />
          </ErrorItem>
        )}
        {extractErrors(errors).map((error, idx) => (
          <ErrorItem key={idx}>{error}</ErrorItem>
        ))}
      </dl>
    </Alert>
  )
}
