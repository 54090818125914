import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'

import { UpdateCarDTO } from '../dto'
import { ICar } from '../interfaces'

export async function updateCar(dto: UpdateCarDTO) {
  try {
    const { ...values } = dto

    const { data } = await apiClient.post<ICar | null>(`/cars`, values)
    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при изменении')
  }
}
