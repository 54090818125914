import React from 'react'
import styled from 'styled-components'
import BaseContainer from 'react-bootstrap/Container'

import { Link } from 'App/Components'
import { SearchField } from './SearchField'
import { CartMenu } from './CartMenu'

import { Logo } from 'App/Layout/components/logo'

type MiddleSectionProps = {
  $isDark?: boolean
}

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.headerBackgroundPrimaryColor};
  padding-top: 10px;
  /* box-shadow: inset ${(props) => props.theme.baseBoxShadow}; */
`

const Container = styled(BaseContainer)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

export const MiddleSection: React.FC<MiddleSectionProps> = ({ $isDark }) => (
  <Wrapper>
    <Container>
      <div className="hbe header-logo hbe-left">
        <Link to="/">
          <Logo $height="70px" />
        </Link>
      </div>
      <SearchField $isDark={$isDark} />
      <CartMenu />
    </Container>
  </Wrapper>
)
