import styled from 'styled-components'
import Color from 'color'
import BaseBadge from 'react-bootstrap/Badge'

export type BaseProps = {
  $color?: string
}
export const Base = styled(BaseBadge)<BaseProps>`
  color: ${(props) => props.theme.light};
  background-color: ${(props) => props.$color};
  padding: 4px;
  line-height: ${(props) => props.theme.baseLineHeight};
  white-space: nowrap;
  /* border-color: ${(props) => Color(props.$color).darken(0.25).string()}; */
  /* border-style: solid; */
  /* border-width: 1px 0px; */
`
